import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import ShooterInfo from '../components/ShooterInfo';
import api from '../Api';

const VERIFICATIONS = {
    open: ['details', 'openmincaliber', 'mag170', 'maxtorso'],
    standard: ['details', 'mincaliber', 'weightsonbarrel', 'box', 'maxtorso', 'bones', 'nobarrelporting', 'nooptics', 'nocompensators'],
    classic: ['details', 'mincaliber', 'weightsonbarrel', 'box', 'maxtorso', 'bones', 'nooptics', 'nocompensators', 'classichandgun', 'classicmag', 'classicmod'],
    production: ['details', 'mincaliber', 'weightsonbarrel', 'prodtrigger', 'maxbarrellength', 'maxtorso', 'bones', 'nooptics', 'nocompensators', 'inprodlist'],
    'production-optics': ['details', 'mincaliber', 'weightsonbarrel', 'prodtrigger', 'maxbarrellength', 'maxtorso', 'bones', 'mandatoryoptics', 'nocompensators', 'inprodlist'],
    'production-optics-light': ['details', 'mincaliber', 'weightsonbarrel', 'prodtrigger', 'maxbarrellength', 'bones', 'mandatoryoptics', 'nocompensators', 'inprodlist', 'maxlightweight'],
    revolver: ['details', 'mincaliber', 'weightsonbarrel', 'notselfloadingrevolver', 'minoronlyrevolver7round'],
    'pcc-optics': ['details'],
    'pcc-iron': ['details'],
};

function RegistrationVerify(props) {
    const {
        t, classes, shooter, match, auth, onChange, readonly,
        setTitle, setExtraButtons, setSaveButtonLabel, setSaveButtonCallback, setBackButtonVisible,
    } = props;

    const [ackedVerifications, setAckedVerifications] = useState([]);
    const [showCheckError, setShowCheckError] = useState(false);
    const [showSaveError, setShowSaveError] = useState(false);
    const [stateShooter, setStateShooter] = useState(shooter);
    const [saving, setSaving] = useState(false);

    const verifications = readonly ? [] : VERIFICATIONS[stateShooter.division];

    useEffect(() => {
        if (!readonly) {
            setTitle(t('title', { name: match.title }));
            setExtraButtons(null);
            setSaveButtonLabel(null);
            setBackButtonVisible(true);
            setSaveButtonCallback(null);
        }

        verifications.forEach((verification) => {
            if (!t(verification, { context: stateShooter['power-factor'] })) {
                setAckedVerifications([...ackedVerifications, verification]);
            }
        });
    }, []);

    async function onClick() {
        if (ackedVerifications.length < verifications.length) {
            setShowCheckError(true);
            return;
        }

        await setSaving(true);

        try {
            const newShooter = await api.patchShooter({
                matchId: match.id,
                shooterId: stateShooter.id,
                auth,
                reason: 'verify',
                shooter: {
                    verified: true,
                },
            });
            onChange(newShooter);
            await setStateShooter(newShooter);
        } catch (e) {
            await setShowSaveError(true);
        }
        await setSaving(false);
    }

    function onCheckAll() {
        setAckedVerifications(verifications);
    }

    return (
        <div className={classes.wrapper}>
            <ShooterInfo t={t} shooter={stateShooter} match={match} />
            {verifications.map((verification) => (
                (t(verification, { context: stateShooter['power-factor'] })) && (
                    <div key={verification}>
                        <FormControlLabel
                            control={(
                                <Checkbox
                                    disabled={stateShooter.verified}
                                    checked={ackedVerifications.indexOf(verification) > -1 || stateShooter.verified}
                                    onChange={async () => {
                                        if (ackedVerifications.indexOf(verification) > -1) {
                                            await setAckedVerifications(ackedVerifications.filter((s) => s !== verification));
                                        } else {
                                            await setAckedVerifications([...ackedVerifications, verification]);
                                        }
                                        await setShowCheckError(false);
                                        await setShowSaveError(false);
                                    }}
                                />
                            )}
                            label={t(verification, { context: stateShooter['power-factor'] })}
                        />
                    </div>
                )
            ))}
            {!stateShooter.verified && !readonly && (
                <div>
                    <div style={{ display: 'flex' }}>
                        <Button variant='text' color='primary' onClick={onCheckAll}>
                            {t('checkall')}
                        </Button>
                        &nbsp;
                        <Button disabled={saving} fullWidth variant='contained' color='primary' onClick={onClick}>
                            {!saving && t('submit')}
                            {saving && <CircularProgress size={24} />}
                        </Button>
                    </div>
                    {showCheckError && (
                        <Typography className={classes.error}>
                            {t('checkmarks')}
                        </Typography>
                    )}
                    {showSaveError && (
                        <Typography className={classes.error}>
                            {t('generic:saveerror')}
                        </Typography>
                    )}
                </div>
            )}
            {stateShooter.verified && (
                <Typography variant='subtitle1' style={{ textAlign: 'center', paddingTop: '12px' }}>
                    <i className='fas fa-check' style={{ color: green[500] }} />
                    &nbsp;
                    {t('verified')}
                </Typography>
            )}
        </div>
    );
}

RegistrationVerify.propTypes = {
    setTitle: PropTypes.func.isRequired,
    setExtraButtons: PropTypes.func.isRequired,
    setSaveButtonLabel: PropTypes.func.isRequired,
    setSaveButtonCallback: PropTypes.func.isRequired,
    setBackButtonVisible: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    classes: PropTypes.shape({}).isRequired,
    shooter: PropTypes.shape({
        id: PropTypes.string.isRequired,
        publicId: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        division: PropTypes.string.isRequired,
        category: PropTypes.string.isRequired,
        'power-factor': PropTypes.string.isRequired,
    }).isRequired,
    match: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        payment: PropTypes.string.isRequired,
        squadSelection: PropTypes.string.isRequired,
        squads: PropTypes.array,
        registrationExtraFields: PropTypes.array,
    }).isRequired,
    auth: PropTypes.string.isRequired,
    readonly: PropTypes.bool,
    onChange: PropTypes.func,
};

RegistrationVerify.defaultProps = {
    readonly: false,
    onChange: () => {},
};

const styles = () => ({
    wrapper: {
        maxWidth: '720px',
        width: '90%',
        margin: 'auto',
    },
    error: {
        color: red[500],
        padding: '12px',
        textAlign: 'center',
        width: '100%',
    },
});

export default withStyles(styles, { withTheme: true })(withTranslation('registrationverify')(RegistrationVerify));
