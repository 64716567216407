import _ from 'lodash';

export function getAllDivisions({ matchType }) {
    if (matchType === 'ipsc-shotgun') {
        return ['sg-open', 'sg-modified', 'sg-standard', 'sg-standard-manual'];
    }
    if (matchType === 'il-army') {
        return ['il-army-pistol', 'il-army-rifle', 'il-army-negev', 'il-army-army-rifle'];
    }
    return ['standard', 'production', 'open', 'production-optics', 'production-optics-light', 'classic', 'revolver', 'pcc-optics', 'pcc-iron'];
}

export function getAllCategories({ matchType }) {
    if (matchType === 'il-army') {
        return ['men', 'women', 'reserves'];
    }
    return ['regular', 'lady', 'super-junior', 'junior', 'senior', 'super-senior', 'lady-senior', 'grand-senior'];
}

export function getLatestScoreForShooterPerStage({ scores, numStages, stages }) {
    const $scores = _(scores)
        .filter((score) => !score.deleted)
        .filter((score) => !stages || !stages[score.stageIdx - 1].inactive || !!score.dq)
        .groupBy('stageIdx')
        .mapValues((stageScore) => _(stageScore)
            .groupBy('shooterId')
            .mapValues((shooterScores) => _.sortBy(shooterScores, (s) => -1 * s.timestamp)[0])
            .values()
            .value())
        .value();


    const ret = _.range(numStages).map((stageNum) => $scores[stageNum + 1] || []);

    return ret;
}

export function numShotsPerPaper({ stage }) {
    return (stage.rounds - (stage.targets.poppers || 0) - (stage.targets.minipoppers || 0) - (stage.targets.plates || 0) - (stage.targets['pcc-frangible'] || 0) - (stage.targets['pcc-ten-points-poppers'] || 0) - (stage.targets['pcc-ten-points-frangible'] || 0)) / ((stage.targets.paper || 0) + (stage.targets.minipaper || 0));
}

export function getMultipliers({ scoreMode }) {
    if (scoreMode === 'il-army') {
        return {
            major: {
                alpha: 5,
                charlie: 1,
                delta: 0,
                miss: -5,
                noshoot: -5,
                penalty: -5,
            },
            minor: {
                alpha: 5,
                charlie: 1,
                delta: 0,
                miss: -5,
                noshoot: -5,
                penalty: -5,
            },
            invalid: {
                alpha: 0,
                charlie: 0,
                delta: 0,
                miss: 0,
                noshoot: 0,
                penalty: 0,
            }
        };
    }
    return {
        major: {
            alpha: 5,
            charlie: 4,
            delta: 2,
            miss: -10,
            noshoot: -10,
            penalty: -10,
        },
        minor: {
            alpha: 5,
            charlie: 3,
            delta: 1,
            miss: -10,
            noshoot: -10,
            penalty: -10,
        },
        invalid: {
            alpha: 0,
            charlie: 0,
            delta: 0,
            miss: 0,
            noshoot: 0,
            penalty: 0,
        }
    };
}

export function summarizeScore({
    score, stage, mode = 'comstock', powerFactor,
}) {
    const ret = {
        time: score.time || 0,
        alphas: (score.scorePerTarget || []).join('').split('').filter((s) => s === 'A').length
            + (score.poppers || 0) + (score.tenPointsPoppers || 0),
        charlies: (score.scorePerTarget || []).join('').split('').filter((s) => s === 'C').length,
        deltas: (score.scorePerTarget || []).join('').split('').filter((s) => s === 'D').length,
        misses: !score.time ? 0 : (score.scorePerTarget || []).join('').split('').filter((s) => s === 'M').length
            + (mode === 'steel-no-miss'
                ? 0
                : (stage.targets.poppers || 0) + (stage.targets.minipoppers || 0) + (stage.targets.plates || 0) + (stage.targets['pcc-frangible'] || 0) + (stage.targets['pcc-ten-points-poppers'] || 0) + (stage.targets['pcc-ten-points-frangible'] || 0) - (score.poppers || 0) - (score.tenPointsPoppers || 0)
            ),
        bonusMisses: (score.scorePerTarget || []).join('').split('').filter((s) => s === 'm').length
            + (mode === 'steel-no-miss'
                ? (stage.targets.poppers || 0) + (stage.targets.minipoppers || 0) + (stage.targets.plates || 0) + (stage.targets['pcc-frangible'] || 0) + (stage.targets['pcc-ten-points-poppers'] || 0) + (stage.targets['pcc-ten-points-frangible'] || 0) - (score.poppers || 0) - (score.tenPointsPoppers || 0)
                : 0
            ),
        noShoots: score['no-shoots'] || 0,
        penalties: _.sum(_.values(score.penaltiesByType)) || 0,
        bonusField: score.bonusField || 0,
    };

    const multiplier = getMultipliers({ scoreMode: mode })[powerFactor || 'minor'];

    const tenPointsExtra = (score.tenPointsPoppers || 0) * multiplier.alpha;

    ret.total = Math.max(0, (ret.alphas * multiplier.alpha)
            + tenPointsExtra
            + (ret.charlies * multiplier.charlie)
            + (ret.deltas * multiplier.delta)
            + (ret.misses * multiplier.miss)
            + (ret.noShoots * multiplier.noshoot)
            + (ret.penalties * multiplier.penalty))
            + (mode === 'il-army' ? ret.bonusField : 0);

    ret.specialPenaltyPercentageStr = score.specialPenalty ? `${score.specialPenalty}%` : '';
    ret.specialPenaltyValue = -1 * Math.round((-1 * ret.total * (score.specialPenalty || 0)) / 100); // -1 for round down
    ret.total -= ret.specialPenaltyValue;

    ret.factor = ret.time === 0 ? 0 : ret.total / ret.time;
    ret.timeStr = ret.time === 0 ? '0' : pad(ret.time, 2);
    ret.factorStr = ret.time === 0 ? '0' : pad(parseInt(Math.round(ret.factor * 10000, 10), 10) / 10000, 4);

    return ret;
}

export function pad(num, len) {
    const parts = (`${num}`).split('.');
    parts[1] = (parts[1] || '').substr(0, len || 2);
    const frac = _.padEnd(parts[1] || '', len, '0');
    return `${parts[0]}.${frac}`;
}

export function scoreToStr({
    t, score, stage, mode,
}) {
    const sum = summarizeScore({ score, stage, mode });
    return `${t('score:score-summary', sum)}`;
}

export function getScoresWithPoints({
    scores: $scores, stages, shooters, combined = false, mode,
}) {
    const dqs = [];

    const scoresPerStageWithShooterInfo = getLatestScoreForShooterPerStage({ scores: $scores, numStages: stages.length, stages })
        .map((stageScores, stageIdx) => _.compact(stageScores.map((s) => {
            // TODO: Erase this after migration
            const shooter = shooters.find(($shooter) => $shooter.id.toString() === s.shooterId.toString())
                || shooters.find(($shooter) => $shooter.id === `${s.matchId}_${s.shooterId}`);
            if (!shooter) return null;
            return {
                ...summarizeScore({
                    score: s, stage: stages[stageIdx], powerFactor: shooter['power-factor'], mode,
                }),
                dq: s.dq,
                timestamp: s.timestamp,
                shooterId: shooter.id,
                shooterPublicId: shooter.publicId,
                shooterName: shooter.name,
                shooterTeam: shooter.team,
                shooterIcs: shooter.ics,
                isRo: _.find(['ro', 'cro', 'so', 'md', 'rm', 'qm'], (role) => ((shooter.labels || []).indexOf(role) > -1)),
                division: shooter.division || 'standard',
                category: shooter.category || 'regular',
                'power-factor': shooter['power-factor'] || 'major',
            };
        })));

    scoresPerStageWithShooterInfo.forEach((stageScores, stage) => stageScores.forEach((score) => {
        if (score.dq) {
            if (!dqs.find((dq) => dq.shooterId === score.shooterId)) {
                dqs.push({ ...score, stage: stage + 1 });
            }
        }
    }));

    scoresPerStageWithShooterInfo.forEach((stageScores, stage) => {
        scoresPerStageWithShooterInfo[stage] = stageScores.filter((score) => !dqs.find((dq) => dq.shooterId === score.shooterId));
    });

    const topFactorPerStage = {};

    scoresPerStageWithShooterInfo.forEach((stageScores, stageIdx) => stageScores.forEach((score) => {
        topFactorPerStage[stageIdx] = topFactorPerStage[stageIdx] || {};
        const division = combined ? 'combined' : score.division;
        topFactorPerStage[stageIdx][division] = topFactorPerStage[stageIdx][division]
            || _(stageScores)
                .filter((s) => ((combined) || (s.division === score.division)))
                .sortBy((s) => -1 * s.factor)
                .value()[0];

        const topFactor = topFactorPerStage[stageIdx][division];
        const topFactorFactor = Math.round(topFactor.factor * 10000) / 10000;

        const stage = stages[stageIdx];
        const tenPointsExtra = ((stage.targets['pcc-ten-points-poppers'] || 0) + (stage.targets['pcc-ten-points-frangible'] || 0)) * 5;

        /* eslint-disable no-param-reassign */
        score.factor = Math.round(score.factor * 10000) / 10000;
        score.percentage = Math.round((score.factor * 10000) / topFactorFactor);
        const totalPoints = stage.rounds * 5 + tenPointsExtra + (stage.bonusField ? 20 : 0);
        score.points = score.time === 0 || topFactor.total === 0 ? 0 : (totalPoints * score.factor) / topFactorFactor;
        /* eslint-enable no-param-reassign */
    }));

    const scoresByStage = scoresPerStageWithShooterInfo.map((stageScores) => _(stageScores)
        .map((score) => (score.dq ? null : score))
        .compact()
        .sortBy((s) => -1 * s.factor)
        .value());

    const scoresOverall = _(scoresByStage)
        .flatten()
        .groupBy((s) => s.shooterId)
        .map((s) => ({
            ..._.pick(s[0], ['shooterId', 'shooterName', 'shooterTeam', 'shooterIcs', 'isRo', 'shooterPublicId', 'division', 'category']),
            score: _.sumBy(s, ($s) => $s.points),
        }))
        .value();

    scoresOverall.sort((a, b) => b.score - a.score);

    scoresOverall.forEach((score) => {
        const topDivision = combined ? scoresOverall[0] : scoresOverall.find((s) => s.division === score.division);
        /* eslint-disable no-param-reassign */
        score.percentage = Math.round((score.score * 10000) / topDivision.score);
        /* eslint-enable no-param-reassign */
    });

    scoresPerStageWithShooterInfo.forEach((stageScores) => stageScores.forEach((score) => {
        /* eslint-disable no-param-reassign */
        score.points = Math.round(score.points * 10000) / 10000;
        /* eslint-enable no-param-reassign */
    }));

    return { scoresByStage, scoresOverall, dqs };
}

export function getFlagSrc({ countryCode, size }) {
    if (countryCode === 'ND') {
        return `https://flagcdn.com/${size}x${(size * 3) / 4}/gb-nir.png`;
    }
    return `https://flagcdn.com/${size}x${(size * 3) / 4}/${countryCode.toLowerCase()}.png`;
}
