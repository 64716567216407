/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import uuidv4 from 'uuid/v4';
import ReactRouterPropTypes from 'react-router-prop-types';
import Button from '@material-ui/core/Button';
import ScoreSignaturedForm from './Score.form.signature';
import { getLatestScoreForShooterPerStage } from './Utils';
import ScoreScoring from './Score.scoring';
import ScorePenalties from './Score.penalties';
import ScoreDq from './Score.dq';
import ScoreTransfer from './Score.transfer';
import Snackbar from '../components/Snackbar';
import api from '../Api';

const EMPTY_SCORE = {
    time: 0,
    target: 0,
    poppers: 0,
    tenPointsPoppers: 0,
    'no-shoots': 0,
    penaltiesByType: {},
    specialPenalty: 0,
    bonusField: 0,
    signature: null,
    scorePerTarget: [],
    shooterId: null,
    dq: null,
};

export default class ScoreReviewingWrapper extends Component {
    constructor(props) {
        super(props);

        const backup = JSON.parse(window.localStorage.getItem('__score_reviewing_wrapper') || '{}');
        if ((backup.shooterId === props.shooterId) && (backup.stageIdx === props.stageIdx)) {
            this.state = backup.state;
        } else {
            this.state = {
                sending: false,
                saveLocal: null,
                newScore: _.cloneDeep(EMPTY_SCORE),
            };
        }
    }

    backup = () => {
        try {
            const { shooterId, stageIdx } = this.props;

            const data = {
                shooterId,
                stageIdx,
                state: this.state,
            };

            window.localStorage.setItem('__score_reviewing_wrapper', JSON.stringify(data));
        } catch (e) {
            // Left blank intentionally
        }
    }

    onUpdateNewScore = (type, value) => {
        console.log('@@@@@@@@', type, value);
        const { newScore } = this.state;
        const updated = {
            ...newScore,
            [type === 'noShoots' ? 'no-shoots' : type]: value,
        };
        this.setState({ newScore: updated }, this.backup);
    }

    onUpdatePenalty = (type, val) => {
        const { newScore } = this.state;
        const updated = {
            ...newScore,
            penaltiesByType: { ...newScore.penaltiesByType, [type]: val },
        };
        this.setState({ newScore: updated }, this.backup);
    }

    onUpdateSpecialPenalty = (val) => {
        const { newScore } = this.state;
        const updated = {
            ...newScore,
            specialPenalty: val || 0,
        };
        this.setState({ newScore: updated }, this.backup);
    }

    onPenaltyCancel = (oldValue, oldSpecial) => {
        const { routeProps } = this.props;
        const { newScore } = this.state;
        const updated = {
            ...newScore,
            penaltiesByType: oldValue,
            specialPenalty: oldSpecial || 0,
        };
        this.setState({ newScore: updated }, () => {
            this.backup();
            routeProps.history.goBack();
        });
    }

    onDqRule = (rule) => {
        const { routeProps } = this.props;
        const { newScore } = this.state;
        const updated = {
            ...newScore,
            dq: rule,
        };
        this.setState({ newScore: updated }, () => {
            this.backup();
            routeProps.history.push(`${routeProps.match.url}/approve${routeProps.history.location.search}`);
        });
    }

    onDoneNewScore = () => {
        const { routeProps } = this.props;
        routeProps.history.push(`${routeProps.match.url}/approve${routeProps.history.location.search}`);
    }

    onClearLocal = () => {
        window.localStorage.setItem('__score_reviewing_wrapper', '{}');
        this.setState({ newScore: _.cloneDeep(EMPTY_SCORE) }, this.backup);
    }

    onLoadLocal = () => {
        const backup = JSON.parse(window.localStorage.getItem('__score_reviewing_wrapper') || '{}');
        window.localStorage.setItem('__score_reviewing_wrapper', '{}');
        this.setState(backup.state, this.backup);
    }

    onTempSave = ({ roSignature }) => {
        this.onSave({ finalize: false, roSignature });
    }

    onSaveLocal = () => {
        const { saveLocal } = this.state;
        this.onSave({ ...saveLocal, local: true });
    }

    onSave = async ({
        signature, roSignature, dqTime, finalize = true, local = false, score = null,
    }) => {
        const {
            demo, t, match, auth, stageIdx, squadId,
            shooterId, onError, routeProps, targets, onAddScore, onSuccess,
        } = this.props;
        const {
            newScore,
        } = this.state;
        const {
            history,
        } = routeProps;
        const {
            dq, poppers, tenPointsPoppers, scorePerTarget, penaltiesByType,
            specialPenalty, 'no-shoots': noShoots, time, bonusField,
        } = (score || newScore);

        await new Promise((res) => this.setState({ sending: true }, res));

        const savingScore = !dq ? {
            stageIdx,
            shooterId,
            scorePerTarget,
            poppers,
            tenPointsPoppers,
            'no-shoots': noShoots,
            penaltiesByType,
            specialPenalty,
            time: time ? parseFloat(time) : 0,
            bonusField,
        } : {
            stageIdx,
            shooterId,
            dq,
            occuredOn: new Date(dqTime).getTime(),
        };

        if (!dq) {
            if (signature && finalize) {
                savingScore.signature = signature;
            } else if (finalize) {
                this.setState({ sending: false }, () => onError(t('missing-signature')));
                return;
            }
        }

        if (roSignature) {
            savingScore.roSignature = roSignature;
        } else {
            this.setState({ sending: false }, () => onError(t('ro-missing-signature')));
            return;
        }

        try {
            let newScoreLocked = null;
            if (!demo) {
                if (local) {
                    newScoreLocked = {
                        ...savingScore,
                        finalize,
                        id: `local-${uuidv4()}`,
                    };
                    const localCache = JSON.parse(window.localStorage.getItem('__offline_cache') || '[]');
                    localCache.push({
                        id: uuidv4(),
                        matchId: match.id,
                        type: 'score',
                        timestamp: new Date().getTime(),
                        data: newScoreLocked,
                    });
                    window.localStorage.setItem('__offline_cache', JSON.stringify(localCache));
                } else {
                    const key = await api.postScore({
                        matchId: match.id,
                        auth,
                        finalize,
                        score: savingScore,
                    });
                    const serverScore = await api.getScore({ matchId: match.id, scoreId: key, auth });
                    if ((serverScore.shooterId !== shooterId) || (serverScore.stageIdx !== stageIdx)) { throw new Error('unmatching results'); }
                    newScoreLocked = finalize
                        ? await api.patchScore({
                            matchId: match.id,
                            scoreId: key,
                            auth,
                            patch: { editable: false },
                        })
                        : serverScore;
                }
            } else {
                newScoreLocked = { ...savingScore, id: Math.random().toString(), editable: false };
            }

            window.localStorage.setItem('__score_reviewing_wrapper', '{}');
            this.setState({
                sending: false,
                newScore: _.cloneDeep(EMPTY_SCORE),
            }, () => {
                onAddScore(newScoreLocked);
                onSuccess(t('sendsuccess'));
                window.localStorage.setItem('__score_reviewing_wrapper.rosignature', roSignature);

                if (!demo) {
                    if (squadId !== 'none') {
                        history.push(`/${match.id}/manage/scores${history.location.search}`);
                        history.push(`/${match.id}/manage/scores/stage/${stageIdx}${history.location.search}`);
                        history.push(`/${match.id}/manage/scores/stage/${stageIdx}/squad/${squadId}${history.location.search}`);
                        history.push(`/${match.id}/manage/scores/stage/${stageIdx}/squad/${squadId}/score-mode${history.location.search}`);
                    } else {
                        history.push(`/${match.id}/manage/scores${history.location.search}`);
                        history.push(`/${match.id}/manage/scores/stage/${stageIdx}${history.location.search}`);
                    }
                } else if (squadId !== 'none') {
                    history.push(`/scoring-demo/${targets}${history.location.search}`);
                    history.push(`/scoring-demo/${targets}/stage/${stageIdx}${history.location.search}`);
                    history.push(`/scoring-demo/${targets}/stage/${stageIdx}/squad/${squadId}${history.location.search}`);
                    history.push(`/scoring-demo/${targets}/stage/${stageIdx}/squad/${squadId}/score-mode${history.location.search}`);
                } else {
                    history.push(`/scoring-demo/${targets}${history.location.search}`);
                    history.push(`/scoring-demo/${targets}/stage/${stageIdx}${history.location.search}`);
                }
            });
        } catch (e) {
            console.error(e);
            if (_.get(e, 'response.data.status') === 409) {
                this.setState({ sending: false }, () => onError(t('score-uneditable-error')));
            } else if ((_.get(e, 'response.status') >= 400) && (_.get(e, 'response.status') < 500)) {
                this.setState({ sending: false }, () => onError(t('senderror')));
            } else {
                this.setState({
                    sending: false,
                    saveLocal: {
                        signature,
                        roSignature,
                        dqTime,
                        finalize,
                        score: savingScore,
                    },
                });
            }
        }
    }

    onClearRoSignature = () => {
        window.localStorage.removeItem('__score_reviewing_wrapper.rosignature');
        this.forceUpdate();
    }

    onTransfer = () => {
        const { routeProps } = this.props;
        routeProps.history.push(`${routeProps.match.url}/transfer${routeProps.history.location.search}`);
    }

    onEdit = (score) => {
        const { routeProps } = this.props;
        window.localStorage.setItem('__score_reviewing_wrapper', '{}');
        this.setState({
            newScore: {
                ..._.cloneDeep(EMPTY_SCORE),
                ..._.omit(score, ['roSignature', 'dq', 'ro', 'signature']),
            },
        }, () => {
            this.backup();
            routeProps.history.push(`${routeProps.match.url.replace(/reviewing/, 'scoring')}${routeProps.history.location.search}`);
        });
    }

    render() {
        const {
            t, scores, match, stageIdx, shooterId, onError, onSuccess, onUpdateTransfer, demo,
        } = this.props;
        const {
            newScore, sending, saveLocal,
        } = this.state;

        const latestScoresPerStage = getLatestScoreForShooterPerStage({ scores, numStages: demo ? 1 : match.stages.length });
        const score = {
            ...EMPTY_SCORE,
            ...(latestScoresPerStage[stageIdx - 1].find((s) => s.shooterId === shooterId) || {}),
        };

        return (
            <div>
                <Route
                    path={[
                        '/:matchId/manage/scores/stage/:stageIdx/squad/:squadId/score-mode/reviewing/:shooterId',
                        '/scoring-demo/:targets/stage/:stageIdx2/squad/:squadId2/score-mode/reviewing/:shooterId2',
                    ]}
                    exact
                    render={(routeProps) => (
                        <ScoreReviewingInner
                            {...this.props}
                            reviewing
                            routeProps={routeProps}
                            score={score}
                            scoreFormDisplayMode={match.scoreFormDisplayMode}
                            onSave={(params) => this.onSave({ ...params, score })}
                            onEdit={() => this.onEdit(score)}
                            onTransfer={this.onTransfer}
                        />
                    )}
                />
                <Route
                    path={[
                        '/:matchId/manage/scores/stage/:stageIdx/squad/:squadId/score-mode/scoring/:shooterId',
                        '/scoring-demo/:targets/stage/:stageIdx2/squad/:squadId2/score-mode/scoring/:shooterId2',
                    ]}
                    exact
                    render={(routeProps) => {
                        const backup = JSON.parse(window.localStorage.getItem('__score_reviewing_wrapper') || '{}');
                        const validLocalStorage = (backup.shooterId !== shooterId)
                            && (backup.stageIdx === stageIdx)
                            && (!_.isEqual(backup.state, { newScore: EMPTY_SCORE }));

                        return (
                            <ScoreScoringInner
                                {...this.props}
                                routeProps={routeProps}
                                score={newScore}
                                validLocalStorage={validLocalStorage}
                                onUpdateScore={this.onUpdateNewScore}
                                onDone={this.onDoneNewScore}
                                onClearLocal={this.onClearLocal}
                                onLoadLocal={this.onLoadLocal}
                            />
                        );
                    }}
                />
                <Route
                    path={[
                        '/:matchId/manage/scores/stage/:stageIdx/squad/:squadId/score-mode/scoring/:shooterId/penalties',
                        '/scoring-demo/:targets/stage/:stageIdx2/squad/:squadId2/score-mode/scoring/:shooterId2/penalties',
                    ]}
                    exact
                    render={(routeProps) => (
                        <ScorePenalties
                            {...this.props}
                            history={routeProps.history}
                            penalties={newScore.penaltiesByType}
                            specialPenalty={newScore.specialPenalty}
                            onUpdatePenalty={this.onUpdatePenalty}
                            onUpdateSpecialPenalty={this.onUpdateSpecialPenalty}
                            onSave={() => routeProps.history.goBack()}
                            onCancel={this.onPenaltyCancel}
                        />
                    )}
                />

                <Route
                    path={[
                        '/:matchId/manage/scores/stage/:stageIdx/squad/:squadId/score-mode/scoring/:shooterId/dq',
                        '/scoring-demo/:targets/stage/:stageIdx2/squad/:squadId2/score-mode/scoring/:shooterId2/dq',
                    ]}
                    exact
                    render={(routeProps) => (
                        <ScoreDq
                            {...this.props}
                            history={routeProps.history}
                            onFilter={(filter) => routeProps.history.push(`${routeProps.match.url}/${filter}${routeProps.history.location.search}`)}
                            filter={null}
                        />
                    )}
                />

                <Route
                    path={[
                        '/:matchId/manage/scores/stage/:stageIdx/squad/:squadId/score-mode/scoring/:shooterId/dq/:filter',
                        '/scoring-demo/:targets/stage/:stageIdx2/squad/:squadId2/score-mode/scoring/:shooterId2/dq/:filter2',
                    ]}
                    exact
                    render={(routeProps) => (
                        <ScoreDq
                            {...this.props}
                            history={routeProps.history}
                            filter={routeProps.match.params.filter || routeProps.match.params.filter2}
                            onRule={this.onDqRule}
                        />
                    )}
                />

                <Route
                    path={[
                        '/:matchId/manage/scores/stage/:stageIdx/squad/:squadId/score-mode/scoring/:shooterId/approve',
                        '/scoring-demo/:targets/stage/:stageIdx2/squad/:squadId2/score-mode/scoring/:shooterId2/approve',
                    ]}
                    exact
                    render={(routeProps) => {
                        const roSignature = window.localStorage.getItem('__score_reviewing_wrapper.rosignature') || null;
                        return (
                            <ScoreReviewingInner
                                {...this.props}
                                routeProps={routeProps}
                                score={newScore}
                                roSignature={roSignature}
                                sending={sending}
                                scoreFormDisplayMode={match.scoreFormDisplayMode}
                                onSave={this.onSave}
                                onTempSave={this.onTempSave}
                                onClearRoSignature={this.onClearRoSignature}
                            />
                        );
                    }}
                />

                <Route
                    path='/:matchId/manage/scores/stage/:stageIdx/squad/:squadId/score-mode/reviewing/:shooterId/transfer'
                    exact
                    render={(routeProps) => (
                        <ScoreTransfer
                            {...this.props}
                            history={routeProps.history}
                            scores={latestScoresPerStage}
                            score={score}
                            squads={match.squads}
                            onSaveError={onError}
                            onUpdateScore={async (newScores) => {
                                routeProps.history.go(-2);
                                await onUpdateTransfer(newScores);
                                onSuccess(t('sendsuccess'));
                            }}
                        />
                    )}
                />

                <Snackbar
                    open={!!saveLocal}
                    variant='error'
                    message={(
                        <div style={{}}>
                            <div>
                                {t('savelocal')}
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Button
                                    variant='flat'
                                    style={{ color: 'white' }}
                                    onClick={() => this.onSaveLocal()}
                                >
                                    {t('savelocalsavelocal')}
                                </Button>
                                <Button
                                    variant='flat'
                                    style={{ color: 'white' }}
                                    onClick={() => this.setState({ saveLocal: null })}
                                >
                                    {t('generic:cancel')}
                                </Button>
                            </div>
                        </div>
                    )}
                />
            </div>
        );
    }
}

ScoreReviewingWrapper.propTypes = {
    t: PropTypes.func.isRequired,
    routeProps: PropTypes.shape({
        history: ReactRouterPropTypes.history.isRequired,
        match: ReactRouterPropTypes.match.isRequired,
    }).isRequired,
    scores: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    match: PropTypes.shape({
        id: PropTypes.string.isRequired,
        scoreFormDisplayMode: PropTypes.string,
        stages: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
        squads: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
    stageIdx: PropTypes.number.isRequired,
    squadId: PropTypes.string,
    shooterId: PropTypes.string.isRequired,
    demo: PropTypes.bool,
    auth: PropTypes.string,
    targets: PropTypes.number,
    onError: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onAddScore: PropTypes.func.isRequired,
    onUpdateTransfer: PropTypes.func,
};

ScoreReviewingWrapper.defaultProps = {
    demo: false,
    auth: null,
    squadId: null,
    targets: 0,
    onUpdateTransfer: () => {},
};

class ScoreReviewingInner extends Component {
    componentDidMount() {
        const {
            match, stageIdx, shooterId, score, reviewing,
        } = this.props;

        const {
            poppers, tenPointsPoppers, scorePerTarget, penaltiesByType,
            specialPenalty, 'no-shoots': noShoots, time, bonusField,
        } = score;

        if (!reviewing) {
            (async () => {
                const approveStorage = JSON.parse(window.localStorage.getItem('approve-backup') || '[]').filter(
                    (backup) => backup.timestamp > new Date().getTime() - 48 * 60 * 60 * 1000,
                );

                approveStorage.push({
                    timestamp: new Date().getTime(),
                    matchId: match.id,
                    stageIdx,
                    shooterId,
                    time,
                    scorePerTarget,
                    penaltiesByType,
                    specialPenalty,
                    poppers,
                    tenPointsPoppers,
                    bonusField,
                    'no-shoots': noShoots,
                });

                window.localStorage.setItem('approve-backup', JSON.stringify(approveStorage));
            })();
        }
    }

    render() {
        const {
            match, me, score, routeProps, sending, roSignature,
            onUpdateScore, onDeleteScore, onError, onEdit, onTransfer,
            reviewing, onSave, onTempSave, onClearRoSignature, scoreFormDisplayMode,
        } = this.props;
        const { history } = routeProps;
        const {
            dq, ro, occuredOn, poppers, tenPointsPoppers, scorePerTarget, penaltiesByType,
            specialPenalty, 'no-shoots': noShoots, time, signature, editable, bonusField,
            timestamp,
        } = score;

        return (
            <ScoreSignaturedForm
                {...this.props}
                history={history}
                reviewing={reviewing}
                match={match}
                matchId={match.id}
                ro={ro || me.name}
                sending={sending}
                editable={editable}
                dq={dq}
                formTime={occuredOn || timestamp}
                poppers={poppers}
                tenPointsPoppers={tenPointsPoppers}
                scorePerTarget={scorePerTarget}
                penaltiesByType={penaltiesByType}
                specialPenalty={specialPenalty}
                noShoots={noShoots}
                time={time}
                bonusField={bonusField}
                signature={signature ? signature.toString() : null}
                roSignature={roSignature || score.roSignature ? (roSignature || score.roSignature).toString() : null}
                scoreId={score.id}
                scoreFormDisplayMode={scoreFormDisplayMode}
                onSave={onSave}
                onTempSave={onTempSave}
                onClearSignature={() => {}}
                onClearRoSignature={onClearRoSignature}
                onSaveError={onError}
                onUpdateScore={onUpdateScore}
                onDeleteScore={onDeleteScore}
                onEdit={onEdit}
                onTransfer={onTransfer}
            />
        );
    }
}

ScoreReviewingInner.propTypes = {
    routeProps: PropTypes.shape({
        history: ReactRouterPropTypes.history.isRequired,
        match: ReactRouterPropTypes.match.isRequired,
    }).isRequired,
    me: PropTypes.shape({
        name: PropTypes.string,
    }).isRequired,
    sending: PropTypes.bool,
    match: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
    score: PropTypes.shape({
        id: PropTypes.string,
        time: PropTypes.number,
        poppers: PropTypes.number,
        tenPointsPoppers: PropTypes.number,
        scorePerTarget: PropTypes.arrayOf(PropTypes.string),
        penaltiesByType: PropTypes.shape({}),
        specialPenalty: PropTypes.number,
        'no-shoots': PropTypes.number,
        dq: PropTypes.string,
        ro: PropTypes.string,
        occuredOn: PropTypes.number,
        editable: PropTypes.bool,
        timestamp: PropTypes.number,
        signature: PropTypes.string,
        roSignature: PropTypes.string,
        bonusField: PropTypes.number,
    }).isRequired,
    stageIdx: PropTypes.number.isRequired,
    reviewing: PropTypes.bool,
    scoreFormDisplayMode: PropTypes.string,
    roSignature: PropTypes.string,
    shooterId: PropTypes.string.isRequired,
    onClearRoSignature: PropTypes.func,
    onUpdateScore: PropTypes.func.isRequired,
    onDeleteScore: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onTempSave: PropTypes.func,
    onError: PropTypes.func.isRequired,
    onEdit: PropTypes.func,
    onTransfer: PropTypes.func,
};

ScoreReviewingInner.defaultProps = {
    sending: false,
    reviewing: false,
    scoreFormDisplayMode: null,
    roSignature: null,
    onTempSave: () => {},
    onClearRoSignature: () => {},
    onEdit: () => {},
    onTransfer: () => {},
};

function ScoreScoringInner(props) {
    const {
        score, routeProps, onUpdateScore, match, onDone, validLocalStorage,
        onLoadLocal, onClearLocal,
    } = props;

    const {
        poppers, tenPointsPoppers, scorePerTarget, penaltiesByType, target,
        specialPenalty, 'no-shoots': noShoots, time, bonusField,
    } = score;

    return (
        <ScoreScoring
            {...props}
            history={routeProps.history}
            time={time}
            scorePerTarget={scorePerTarget}
            poppers={poppers}
            tenPointsPoppers={tenPointsPoppers}
            noShoots={noShoots}
            target={target}
            scoreMode={match.scoreMode}
            penaltiesByType={penaltiesByType}
            specialPenalty={specialPenalty}
            validLocalStorage={validLocalStorage}
            bonusField={bonusField}
            onDone={onDone}
            onDq={() => routeProps.history.push(`${routeProps.match.url}/dq${routeProps.history.location.search}`)}
            onPenalties={() => routeProps.history.push(`${routeProps.match.url}/penalties${routeProps.history.location.search}`)}
            onLoadLocal={onLoadLocal}
            onClearLocal={onClearLocal}
            onUpdateScore={onUpdateScore}
        />
    );
}
ScoreScoringInner.propTypes = {
    routeProps: PropTypes.shape({
        history: ReactRouterPropTypes.history.isRequired,
        match: ReactRouterPropTypes.match.isRequired,
    }).isRequired,
    match: PropTypes.shape({
        scoreMode: PropTypes.string,
    }).isRequired,
    score: PropTypes.shape({
        id: PropTypes.string,
        time: PropTypes.number,
        target: PropTypes.number,
        poppers: PropTypes.number,
        tenPointsPoppers: PropTypes.number,
        scorePerTarget: PropTypes.arrayOf(PropTypes.string),
        penaltiesByType: PropTypes.shape({}),
        specialPenalty: PropTypes.number,
        bonusField: PropTypes.number,
        'no-shoots': PropTypes.number,
        dq: PropTypes.string,
        ro: PropTypes.string,
        occuredOn: PropTypes.number,
        editable: PropTypes.bool,
        timestamp: PropTypes.number,
        signature: PropTypes.string,
        roSignature: PropTypes.string,
    }).isRequired,
    validLocalStorage: PropTypes.bool.isRequired,
    /*
    me: PropTypes.shape({}).isRequired,
    sending: PropTypes.bool.isRequired,
    match: PropTypes.shape({}).isRequired,
    stageIdx: PropTypes.number.isRequired,
    shooterId: PropTypes.string.isRequired,
    onUpdateScore: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    */
    onLoadLocal: PropTypes.func.isRequired,
    onClearLocal: PropTypes.func.isRequired,
    onDone: PropTypes.func.isRequired,
    onUpdateScore: PropTypes.func.isRequired,
};
/* eslint-enable max-classes-per-file */
