/* eslint-disable react/no-unescaped-entities */

import React, { Component } from 'react';
import _ from 'lodash';
import validator from 'validator';
import uuidv4 from 'uuid/v4';
import moment from 'moment';
import ReactFlagsSelect from 'react-flags-select';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import EditIcon from '@material-ui/icons/Edit';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';
import EquipmentBelt from '../components/EquipmentBelt';
import Snackbar, { MySnackbarContentWrapper } from '../components/Snackbar';
import RegistrationVerify from './RegistrationVerify';
import { getAllDivisions, getAllCategories } from './Utils';
import api from '../Api';

class Registration extends Component {
    constructor(props) {
        const {
            setTitle, t, match, edit, autofill, manage,
        } = props;
        super(props);
        if (setTitle) setTitle(t('title', { name: match.title }));
        const e = edit || autofill;

        if ((match.registrationLimitGSheet !== null) && (autofill) && (!edit)) {
            e.club = '';
        }

        this.state = {
            id: e && e.id ? e.id : uuidv4(),
            publicId: e ? (() => (e.publicId.indexOf('|') > -1 ? e.publicId.split('|')[1] : e.publicId))() : '',
            countryCode: e ? (() => (e.publicId.indexOf('|') > -1 ? e.publicId.split('|')[0] : (match.countryCode || match.region)))() : (match.countryCode || match.region || 'US'),
            name: e ? e.name || '' : '',
            club: e ? e.club || '' : '',
            email: e ? e.email : '',
            paid: e ? e.paid : false,
            extraFields: e ? e.extraFields || {} : {},
            cancelledParticipation: e ? e.cancelledParticipation : false,
            division: e ? e.division : 'standard',
            category: e ? e.category : 'regular',
            'power-factor': match.type === 'ipsc-shotgun' ? 'major' : (e ? e['power-factor'] : 'minor'),
            squad: e ? e.squad : null,
            team: e ? e.team : null,
            ics: e ? e.ics : null,
            log: e ? e.log : null,
            chronos: e ? e.chronos : null,
            equipmentChecks: e ? e.equipmentChecks : null,
            labels: e ? e.labels || [] : [],
            errors: { extraFields: {} },
            saveError: null,
            changeReason: 'shooter-request',
            equipmentEdit: false,
            notifyemail: true,
            nameError: null,
            limitations: [],
            acceptTos: manage || !!edit,
            acceptTosIl: manage || !!edit || match.countryCode !== 'IL',
        };
        this.newShooterFields = { extraFields: {} };

        if ((match.registrationLimitGSheet !== null) && (autofill || edit)) {
            this.onBlurPublicId();
        }
    }

    async componentDidMount() {
        const {
            t, mode, match, shooters, setTitle, setExtraButtons,
            setSaveButtonLabel, setSaveButtonCallback, setBackButtonVisible,
        } = this.props;

        if (setTitle) setTitle(t('title', { name: match.title }));
        if (setExtraButtons) this.setExtraButtons();
        if (setSaveButtonLabel) setSaveButtonLabel(null);
        if (setBackButtonVisible) setBackButtonVisible(true);
        if (setSaveButtonCallback) setSaveButtonCallback(null);

        if (mode === 'line-officer-attendance') {
            this.setState({ match, shooters });
        } else {
            const promises = await Promise.all([
                api.getMatch({ matchId: match.id }),
                api.getShooters({ matchId: match.id }),
            ]);

            const [$match, $shooters] = promises;

            this.setState({ match: $match, shooters: $shooters });
        }
    }

    async getPaymentInfo() {
        const { match, edit, auth } = this.props;
        this.setState({ payment: { id: '...', state: '...' } });
        const payment = await api.getShooterPayment({
            matchId: match.id,
            shooterId: edit.id,
            auth,
            status: true,
        });
        this.setState({ payment });
    }

    setExtraButtons() {
        const {
            t, auth, setExtraButtons, manage, edit, me, history, match,
        } = this.props;

        const {
            name, forceEditRequest, forceEdit, publicId, countryCode, labels,
        } = this.state;

        const extraButtons = [];

        if (manage && edit && edit.verified && !forceEditRequest && !forceEdit && (me.permissions.indexOf('registration-change-verified') > -1)) {
            extraButtons.push([
                <Button
                    variant='contained'
                    color='primary'
                    onClick={async () => {
                        await new Promise((res) => this.setState({ forceEditRequest: true }, res));
                        this.setExtraButtons();
                    }}
                >
                    <EditIcon />
                </Button>,
            ]);
        }

        if (manage && edit && (me.permissions.indexOf('staff') > -1)) {
            const isRo = _.find(['ro', 'cro', 'so', 'md', 'rm', 'qm'], (role) => ((labels || []).indexOf(role) > -1));
            extraButtons.push([
                <Button
                    style={{ margin: '0 12px' }}
                    variant={isRo ? 'flat' : 'contained'}
                    color={isRo ? null : 'primary'}
                    onClick={async () => {
                        if (!isRo) {
                            await new Promise((res) => this.setState({ match: null }, res));
                            setExtraButtons(null);
                            const staff = await api.getStaff({ matchId: match.id, auth });
                            staff.push({
                                publicId: `${countryCode}|${publicId}`,
                                name,
                                permissions: [],
                                role: 'ro',
                                user: `com.endofscoring|${match.id}|${parseInt(10000 + Math.random() * 10000, 10)}`,
                            });
                            await api.setStaff({ matchId: match.id, auth, staff });
                        }
                        history.push(`/${match.alias}/manage/staff${history.location.search}#${encodeURIComponent(`${countryCode}|${publicId}`)}`);
                    }}
                >
                    {isRo ? isRo.toUpperCase() : t('makero')}
                </Button>,
            ]);
        }

        if (manage && !edit) {
            extraButtons.push([

                <Tooltip title={t('importshooter')}>
                    <IconButton
                        style={{ margin: '0 12px' }}
                        variant='flat'
                        color='primary'
                        onClick={async () => {
                            history.push(`/${match.alias}/manage/shooters/new/import${history.location.search}`);
                        }}
                    >
                        <i className='fas fa-file-import' />
                    </IconButton>
                </Tooltip>,
            ]);
        }


        setExtraButtons(extraButtons.length > 0 ? extraButtons : null);
    }

    onComment = () => {
        const { match, edit, history } = this.props;
        history.push(`/${match.alias}/manage/comments/${edit.id}`);
    }

    handleOnCancel = async () => {
        const {
            match, auth, edit, registrationCode, onSuccess, ro,
        } = this.props;
        const {
            shooters, changeReason, notifyemail,
        } = this.state;

        await new Promise((res) => this.setState({ canceling: true }, res));

        const newShooter = await api.patchShooter({
            matchId: match.id,
            shooterId: edit.id,
            auth,
            reason: changeReason,
            noemail: !notifyemail,
            shooter: {
                cancellationRequest: {
                    timestamp: (new Date()).getTime(),
                },
            },
            registrationCode,
            ro,
        });

        const newShooters = shooters.map((s) => (s.id === edit.id ? newShooter : s));
        this.setState({
            canceling: false,
            saveSuccess: true,
            shooters: newShooters,
        }, () => onSuccess({ ...edit, cancellationRequest: newShooter.cancellationRequest }));
    }

    handleOnCancelCancel = async () => {
        const {
            match, auth, edit, registrationCode, onSuccess, ro,
        } = this.props;
        const {
            shooters, changeReason, notifyemail,
        } = this.state;

        await new Promise((res) => this.setState({ canceling: true }, res));

        const newShooter = await api.patchShooter({
            matchId: match.id,
            shooterId: edit.id,
            auth,
            reason: changeReason,
            noemail: !notifyemail,
            shooter: {
                cancellationRequest: null,
            },
            registrationCode,
            ro,
        });

        const newShooters = shooters.map((s) => (s.id === edit.id ? newShooter : s));
        this.setState({
            canceling: false,
            saveSuccess: true,
            shooters: newShooters,
        }, () => onSuccess({ ...edit, cancellationRequest: null }));
    }

    handleOnCancelApprove = async () => {
        const {
            match, auth, edit, registrationCode, onSuccess, ro,
        } = this.props;
        const {
            shooters, changeReason, notifyemail,
        } = this.state;

        await new Promise((res) => this.setState({ canceling: true }, res));

        const newShooter = await api.patchShooter({
            matchId: match.id,
            shooterId: edit.id,
            auth,
            reason: changeReason,
            noemail: !notifyemail,
            shooter: {
                cancellationRequest: null,
                squad: null,
                cancelledParticipation: true,
            },
            registrationCode,
            ro,
        });

        const newShooters = shooters.map((s) => (s.id === edit.id ? newShooter : s));
        this.setState({
            canceling: false,
            saveSuccess: true,
            shooters: newShooters,
        }, () => onSuccess({ ...edit, cancellationRequest: null }));
    }

    handleOnClick = async () => {
        const {
            match, auth, ro, edit, manage, mode, registrationCode, onSuccess = () => {},
        } = this.props;
        const {
            id, publicId, countryCode, name, club, email, extraFields, paid, 'power-factor': powerFactor,
            cancelledParticipation, division, category, squad, team, ics, shooters, changeReason, notifyemail,
        } = this.state;

        const newState = { errors: {} };

        if (mode !== 'line-officer') {
            if (!publicId) newState.errors.publicId = true;
            if ((!name) && (match.registrationLimitGSheet === null)) newState.errors.name = true;
            if ((!club) && (match.registrationLimitGSheet === null)) newState.errors.club = true;
            if ((email) && (!validator.isEmail(email || ''))) newState.errors.email = true;
            if ((!squad) && (!manage) && (match.squadSelection === 'during-registration')) newState.errors.squad = true;
            match.registrationExtraFields.forEach((field) => {
                if ((!extraFields[field]) && (field.indexOf('optional') === -1) && (field !== 'equipment')) {
                    newState.errors.extraFields = newState.errors.extraFields || {};
                    newState.errors.extraFields[field] = true;
                }
            });
        }

        if (division === 'production' || division === 'production-optics' || division === 'pcc-optics' || division === 'pcc-iron') {
            if (powerFactor === 'major') {
                newState.errors['power-factor'] = true;
            }
        }

        if (match.type === 'ipsc-shotgun') {
            if (powerFactor !== 'major') {
                newState.errors['power-factor'] = true;
            }
        }

        if (Object.keys(newState.errors).length > 0) {
            newState.errors.extraFields = newState.errors.extraFields || {};
            this.setState(newState);
            return;
        }

        await new Promise((res) => this.setState({ sending: true }, res));

        const shooter = !!edit && (mode === 'line-officer') ? {
            division, category, 'power-factor': powerFactor, squad: squad || null, team: team || null, ics: ics || null, extraFields,
        } : {
            id,
            publicId: `${countryCode || match.countryCode || match.region}|${publicId}`,
            name,
            club: match.registrationLimitGSheet === null ? club : null,
            email,
            extraFields,
            division,
            category,
            'power-factor': powerFactor,
            paid,
            cancelledParticipation,
            squad: squad || null,
            team: team || null,
            ics: ics || null,
        };

        try {
            if (edit) {
                const patchShooter = _.omitBy(shooter, (v, k) => _.isEqual(edit[k] || null, v || null));
                if (match.registrationLimitGSheet !== null) {
                    delete patchShooter.club;
                }

                const newShooter = await api.patchShooter({
                    matchId: match.id,
                    shooterId: edit.id,
                    auth,
                    reason: changeReason,
                    noemail: !notifyemail,
                    shooter: patchShooter,
                    registrationCode,
                    ro,
                });
                const newShooters = shooters.map((s) => (s.id === shooter.id ? newShooter : s));
                this.setState({ sending: false, saveSuccess: true, shooters: newShooters }, () => onSuccess(newShooter));
            } else {
                await api.postShooter({
                    matchId: match.id,
                    auth,
                    shooter,
                    registrationCode,
                    ro,
                });
                this.setState({
                    sending: false, saveSuccess: true, id: uuidv4(), countryCode: null, publicId: '', name: '', club: '', email: '', extraFields: {}, paid: false, cancelledParticipation: false, division: 'standard', category: 'regular', 'power-factor': match.type === 'ipsc-shotgun' ? 'major' : 'minor', shooters: [...shooters, shooter],
                }, () => onSuccess({ ...shooter, club }));
            }
        } catch (e) {
            if (e.response.status === 409) {
                this.setState({ sending: false, saveError: 'exists' });
            } else if (e.response.data.title === 'Unknown Shooter') {
                this.setState({ sending: false, saveError: 'unknown-shooter' });
            } else if (e.response.data.detail === 'squad-full') {
                this.setState({ sending: false, saveError: 'squad-full' });
            } else if (e.response.data.detail === 'name-mismatch') {
                this.setState({ sending: false, saveError: 'name-mismatch' });
            } else {
                this.setState({ sending: false, saveError: 'generic' });
            }
        }
    }

    onEquipmentField = ({ field, value }) => {
        const { extraFields } = this.state;
        const equipmentObj = JSON.parse(extraFields.equipment || '{}');
        if (field === 'holster') {
            equipmentObj.holster = value;
            equipmentObj.magnets = equipmentObj.magnets || [];
            equipmentObj.magnets = equipmentObj.magnets.filter((a) => a.toString() !== value.toString());
            equipmentObj.pouches = equipmentObj.pouches || [];
            equipmentObj.pouches = equipmentObj.pouches.filter((a) => a.toString() !== value.toString());
        } else if (field === 'magnet') {
            equipmentObj.magnets = equipmentObj.magnets || [];
            if (equipmentObj.magnets.includes(parseInt(value, 10))) {
                equipmentObj.magnets = equipmentObj.magnets.filter((a) => a.toString() !== value.toString());
            } else {
                equipmentObj.magnets = [...equipmentObj.magnets, parseInt(value, 10)];
            }
        } else if (field === 'pouch') {
            equipmentObj.pouches = equipmentObj.pouches || [];
            if (equipmentObj.pouches.includes(parseInt(value, 10))) {
                equipmentObj.pouches = equipmentObj.pouches.filter((a) => a.toString() !== value.toString());
            } else {
                equipmentObj.pouches = [...equipmentObj.pouches, parseInt(value, 10)];
            }
        }

        const newExtraFields = {
            ...extraFields,
            equipment: JSON.stringify(equipmentObj),
        };

        this.setState({ extraFields: newExtraFields });
    }

    onSelectCountryCode = async (v) => {
        const {
            publicId,
            match,
        } = this.state;

        await new Promise((res) => this.setState({ countryCode: v, nameError: null }, res));

        if (match.registrationLimitGSheet === null) return;

        await new Promise((res) => this.setState({ club: '' }, res));

        if (publicId) {
            this.onBlurPublicId();
        }
    }

    onKeyDownPublicId = (e) => {
        const {
            match,
        } = this.state;

        if (e.keyCode === 13) this.newShooterFields.name.focus();
        if (match.registrationLimitGSheet !== null) {
            this.setState({ club: '', nameError: null });
        }
    }

    onBlurPublicId = async () => {
        const {
            auth, match,
        } = this.props;
        const {
            countryCode, publicId,
        } = this.state;

        if ((publicId) && (match.registrationLimitGSheet !== null)) {
            try {
                const data = await api.checkShooterPublicId({ matchId: match.id, auth, publicId: `${countryCode}|${publicId}` });
                this.setState({ club: data.club, limitations: (data.limitations || '').split(',') });
            } catch (e) {
                this.setState({ nameError: 'limitedshooternotfound' });
            }
        }
    }

    renderComments() {
        const {
            t, edit, manage,
        } = this.props;

        if (!manage) return null;
        if (!edit) return null;

        return (
            <div
                style={{
                    maxWidth: '720px', width: '90%', margin: 'auto', marginTop: '12px',
                }}
            >
                <div style={{ display: 'flex' }}>
                    <Typography variant='h6'>
                        {t('comments')}
                    </Typography>
                    <Button variant='text' color='primary' onClick={this.onComment}>
                        {t('tocomments')}
                    </Button>
                </div>
                {(edit.comments || []).map((c) => (
                    <div key={c.timestamp}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            "
                            {c.comment}
                            "
                        </div>
                        <Typography variant='caption'>
                            (
                            {c.name}
                            ,
                            &nbsp;
                            {moment(c.timestamp).format('LL LT')}
                            )
                        </Typography>
                    </div>
                ))}
                {(edit.comments || []).length === 0 && (
                    <Typography variant='body2'>
                        {t('nocomments')}
                    </Typography>
                )}
            </div>
        );
    }

    onSubmit(e) {
        const { extraFields, errors, match } = this.state;
        const field = (match.registrationExtraFields || []).includes('image_optional') ? 'image_optional' : 'image';
        e.preventDefault();
        const file = document.getElementById(`fileupload`).files[0]; 
        if (file) {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (evt) => {
                this.setState({
                    extraFields: { ...extraFields, [field]: evt.target.result },
                    errors: { ...errors, extraFields: { ...errors.extraFields, [field]: false } },
                });
            };
            reader.onerror = (evt) => {
                console.error('ERROR!');
            };
        }
    }

    render() {
        const {
            t, i18n, classes, edit, manage, mode, me, ro,
        } = this.props;
        const {
            id, countryCode, publicId, name, club, email, extraFields, paid,
            cancelledParticipation, errors, division, category,
            squad, team, ics, sending, canceling, shooters, match, payment, 'power-factor': powerFactor,
            changeReason, log, chronos, equipmentChecks, saveSuccess, saveError, equipmentEdit, forceEdit, forceEditRequest,
            notifyemail, nameError, limitations = [], acceptTos, acceptTosIl,
        } = this.state;

        if (!match) {
            return (
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <CircularProgress size={75} />
                </div>
            );
        }

        if ((edit) && (edit.verified) && (!forceEdit)) {
            return (
                <div>
                    {forceEditRequest && (
                        <MySnackbarContentWrapper
                            style={{
                                maxWidth: '720px', width: '90%', margin: 'auto', marginBottom: '12px',
                            }}
                            onClose={() => this.setState({ forceEditRequest: false }, () => this.setExtraButtons())}
                            variant='warning'
                            message={(
                                <div>
                                    {t('forceedit')}
                                    <span style={{ padding: '0px 12px' }}>
                                        <Button variant='contained' color='white' onClick={() => this.setState({ forceEditRequest: false, forceEdit: true }, () => this.setExtraButtons())}>
                                            {t('generic:yes')}
                                        </Button>
                                    </span>
                                    <Button variant='contained' color='white' onClick={() => this.setState({ forceEditRequest: false }, () => this.setExtraButtons())}>
                                        {t('generic:no')}
                                    </Button>
                                </div>
                            )}
                        />
                    )}
                    <RegistrationVerify {..._.omit(this.props, ['t', 'classes'])} shooter={edit} readonly />
                    {this.renderComments()}
                </div>
            );
        }

        const showInvalidCategory = (() => {
            if (!edit) return false;
            if (edit.cancelledParticipation) return false;
            if (edit.category === 'regular') return false;
            const num = shooters.filter((s) => !s.cancelledParticipation && s.division === edit.division && s.category === edit.category).length;
            return ((num === 5) && ((category !== edit.category) || (cancelledParticipation)));
        })();

        const equipmentObj = JSON.parse(extraFields.equipment || '{}');

        return (
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                {!manage && !edit && match.payment === 'online' && match.squadSelection === 'after-payment' && (
                    <div className={classes.instructions}>
                        <Typography variant='body2'>
                            <div className={classes.instructionstitle}>{t('instructions')}</div>
                            {t('instructions-online-after-payment').split('\n').map((s) => <p style={{ margin: '0px', marginBottom: '6px' }}>{s}</p>)}
                        </Typography>
                    </div>
                )}
                {!manage && !edit && match.payment === 'offline' && match.squadSelection === 'after-payment' && (
                    <div className={classes.instructions}>
                        <Typography variant='body2'>
                            <div className={classes.instructionstitle}>{t('instructions')}</div>
                            {t('instructions-offline-after-payment').split('\n').map((s) => <p style={{ margin: '0px', marginBottom: '6px' }}>{s}</p>)}
                        </Typography>
                    </div>
                )}
                {!manage && !edit && match.payment === 'online' && match.squadSelection === 'during-registration' && (
                    <div className={classes.instructions}>
                        <Typography variant='body2'>
                            <div className={classes.instructionstitle}>{t('instructions')}</div>
                            {t('instructions-online-during-registration').split('\n').map((s) => <p style={{ margin: '0px', marginBottom: '6px' }}>{s}</p>)}
                        </Typography>
                    </div>
                )}
                {!manage && !edit && match.payment === 'offline' && match.squadSelection === 'during-registration' && (
                    <div className={classes.instructions}>
                        <Typography variant='body2'>
                            <div className={classes.instructionstitle}>{t('instructions')}</div>
                            {t('instructions-offline-during-registration').split('\n').map((s) => <p style={{ margin: '0px', marginBottom: '6px' }}>{s}</p>)}
                        </Typography>
                    </div>
                )}
                {!manage && edit && edit.cancellationRequest && (
                    <Card
                        color='secondary'
                        style={{
                            maxWidth: '720px',
                            width: manage ? '90%' : '100%',
                            marginBottom: '12px',
                        }}
                    >
                        <CardContent>
                            <Typography variant='caption' style={{ color: red[500] }}>
                                {t('cancelling')}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button
                                variant='contained'
                                color='secondary'
                                onClick={this.handleOnCancelCancel}
                                disabled={canceling}
                            >
                                {canceling ? <CircularProgress size={24} /> : t('cancelcancel')}
                            </Button>
                        </CardActions>
                    </Card>
                )}
                {manage && edit && edit.cancellationRequest && (
                    <Card
                        color='secondary'
                        style={{
                            maxWidth: '720px',
                            width: manage ? '90%' : '100%',
                            marginBottom: '12px',
                        }}
                    >
                        <CardContent>
                            <Typography variant='body1' style={{ color: red[500] }}>
                                {t('cancellationrequest')}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button
                                variant='contained'
                                color='secondary'
                                onClick={this.handleOnCancelApprove}
                                disabled={canceling}
                            >
                                {canceling ? <CircularProgress size={24} /> : t('cancellationrequestapprove')}
                            </Button>
                            {!canceling && (
                                <Button
                                    variant='text'
                                    color='secondary'
                                    onClick={this.handleOnCancelCancel}
                                    disabled={canceling}
                                >
                                    {t('cancellationrequestdecline')}
                                </Button>
                            )}
                        </CardActions>
                    </Card>
                )}
                <div style={{ maxWidth: '720px', width: manage ? '90%' : '100%', opacity: edit && edit.cancellationRequest ? 0.5 : 1 }}>
                    <div style={{ display: 'flex' }}>
                        {((mode !== 'line-officer' && match.registrationExtraFields) || []).filter((field) => field === 'image' || field === 'image_optional').map((field) => (
                            <div style={{ border: '1px solid #909090', position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: '150px', height: '200px', marginRight: '12px', cursor: 'pointer' }} onClick={() => document.getElementById(`fileupload`).click()}>
                                {(extraFields['image'] || extraFields['image_optional']) && <img style={{ position: 'absolute', top: '0px', left: '0px', width: '150px', height: '200px' }} src={ extraFields['image'] || extraFields['image_optional'] } />}
                                <i style={{ fontSize: '48px' }} className='fas fa-user-tie' />
                                <Typography variant='caption' style={{ textAlign: 'center' }} >
                                    {t('generic:image_description')}
                                </Typography>
                                <form
                                    style={{margin: '0px'}}
                                    id={`fileuploadform`}
                                    encType='multipart/form-data'
                                >
                                    <input
                                        id={`fileupload`}
                                        style={{ display: 'none'}}
                                        type='file'
                                        onChange={e => this.onSubmit(e)}
                                        name='file'/>
                                </form>
                            </div>
                        ))}
                        <div>
                            <div className={classes.namerow}>
                                <div className={classes.flagwrapper} style={!manage && match.registrationPatchLockedFields.indexOf('publicId') > -1 ? { pointerEvents: 'none' } : {}}>
                                    <ReactFlagsSelect
                                        defaultCountry={countryCode || match.countryCode || match.region}
                                        onSelect={(v) => this.onSelectCountryCode(v)}
                                    />
                                </div>
                                <TextField
                                    autoFocus={!edit && mode !== 'line-officer'}
                                    label={t('generic:shooter_id')}
                                    value={publicId}
                                    onChange={(e) => this.setState({
                                        publicId: e.target.value,
                                        errors: { ...errors, publicId: false, exists: false },
                                    })}
                                    onKeyDown={this.onKeyDownPublicId}
                                    onBlur={this.onBlurPublicId}
                                    error={errors.publicId}
                                    disabled={mode === 'line-officer' || (!manage && match.registrationPatchLockedFields.indexOf('publicId') > -1)}
                                />

                                <TextField
                                    autoFocus={!!edit && mode !== 'line-officer'}
                                    label={t('generic:shooter_name')}
                                    value={name}
                                    onChange={(e) => this.setState({
                                        name: e.target.value,
                                        errors: { ...errors, name: false },
                                    })}
                                    onKeyDown={(e) => { if (e.keyCode === 13) this.newShooterFields.club.focus(); }}
                                    error={errors.name}
                                    inputRef={(e) => { this.newShooterFields.name = e; }}
                                    disabled={mode === 'line-officer'}
                                />

                                <TextField
                                    label={t('generic:shooter_club')}
                                    value={club}
                                    onChange={(e) => this.setState({
                                        club: e.target.value,
                                        errors: { ...errors, club: false },
                                    })}
                                    onKeyDown={(e) => { if (e.keyCode === 13) this.newShooterFields.email.focus(); }}
                                    error={errors.club}
                                    inputRef={(e) => { this.newShooterFields.club = e; }}
                                    disabled={mode === 'line-officer' || (match.registrationLimitGSheet !== null)}
                                />
                            </div>
                            {nameError && (
                                <div className={classes.namerow}>
                                    <Typography variant='caption' style={{ color: red[500] }}>
                                        {t(nameError)}
                                    </Typography>
                                </div>
                            )}
                            {mode !== 'line-officer' && (
                                <div className={classes.namerow}>
                                    <TextField
                                        label={t('generic:shooter_email')}
                                        value={email}
                                        onChange={(e) => this.setState({
                                            email: e.target.value,
                                            errors: { ...errors, email: false },
                                        })}
                                        error={errors.email}
                                        inputRef={(e) => { this.newShooterFields.email = e; }}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    {((mode !== 'line-officer' && match.registrationExtraFields) || []).filter((field) => field !== 'equipment' && field !== 'covid' && field !== 'covid_optional' && field !== 'image' && field !== 'image_optional').map((field) => (
                        <div key={field} className={classes.namerow}>
                            <TextField
                                label={`${t(`generic:${field.split('_optional')[0]}`)} ${field.indexOf('_optional') > -1 ? `(${t('generic:optional')})` : ''}`}
                                value={extraFields[field]}
                                onChange={(e) => this.setState({
                                    extraFields: { ...extraFields, [field]: e.target.value },
                                    errors: { ...errors, extraFields: { ...errors.extraFields, [field]: false } },
                                })}
                                error={errors.extraFields[field]}
                                inputRef={(e) => { this.newShooterFields.extraFields[field] = e; }}
                            />
                        </div>
                    ))}
                    {((mode !== 'line-officer' && match.registrationExtraFields) || []).filter((field) => field === 'covid' || field === 'covid_optional').map((field) => (
                        <div style={{ marginTop: '24px' }}>
                            <FormControl component='fieldset'>
                                <Typography variant='h6'>{t('generic:covid')}</Typography>
                                <RadioGroup
                                    aria-label='Covid'
                                    name='covid'
                                    value={extraFields[field]}
                                    onChange={(e) => this.setState({
                                        extraFields: { ...extraFields, [field]: e.target.value },
                                        errors: { ...errors, extraFields: { ...errors.extraFields, [field]: false } },
                                    })}
                                    row
                                >
                                    {
                                    ['vacinated', 'immuned', 'tested'].map((d) => (
                                        <FormControlLabel key={d} value={d} control={<Radio disabled={limitations.includes(`no_${d}`)} />} label={t(`generic:covid_${d}`)} />
                                    ))
                                }
                                    />
                                </RadioGroup>
                            </FormControl>
                        </div>
                    ))}
                    <div style={{ marginTop: '24px' }}>
                        <FormControl component='fieldset'>
                            <Typography variant='h6'>{t('generic:shooter_division')}</Typography>
                            <RadioGroup
                                aria-label='Gender'
                                name='division'
                                value={division}
                                onChange={(e) => this.setState({ division: e.target.value })}
                                row
                            >
                                {
                                    getAllDivisions({ matchType: match.type }).map((d) => (
                                        <FormControlLabel key={d} value={d} control={<Radio disabled={(limitations.includes(`no_${d}`) || (match.registrationExcludeDivisions || []).includes(d))} />} label={t(`generic:division-${d}`)} />
                                    ))
                                }
                                />
                            </RadioGroup>
                        </FormControl>
                    </div>
                    {showInvalidCategory && (edit.division !== division) && (
                        <div>
                            <Typography variant='caption' style={{ color: red[500] }}>
                                {t('invalidcategorydivision', {
                                    division: t(`generic:division-${edit.division}`),
                                    category: t(`category-${edit.category}`),
                                })}
                            </Typography>
                        </div>
                    )}
                    <div style={{ marginTop: '24px' }}>
                        <FormControl component='fieldset'>
                            <Typography variant='h6'>{t('generic:shooter_category')}</Typography>
                            <RadioGroup
                                aria-label='Gender'
                                name='category'
                                value={category}
                                onChange={(e) => this.setState({ category: e.target.value })}
                                row
                            >
                                {getAllCategories({ matchType: match.type }).map((d) => (
                                    <FormControlLabel key={d} value={d} control={<Radio />} label={t(`category-${d}`)} />
                                ))}
                            </RadioGroup>
                        </FormControl>
                    </div>
                    {showInvalidCategory && (edit.category !== category) && (
                        <div>
                            <Typography variant='caption' style={{ color: red[500] }}>
                                {t('invalidcategorycategory', {
                                    division: t(`generic:division-${edit.division}`),
                                    category: t(`category-${edit.category}`),
                                })}
                            </Typography>
                        </div>
                    )}
                    <div style={{ marginTop: '24px', display: match.type === 'ipsc-shotgun' ? 'none' : '' }}>
                        <FormControl component='fieldset'>
                            <Typography variant='h6'>{t('generic:shooter_power_factor')}</Typography>
                            {errors['power-factor'] && (
                                <FormHelperText error>
                                    {t('power-factor-error')}
                                </FormHelperText>
                            )}
                            <RadioGroup
                                aria-label='Gender'
                                name='power-factor'
                                value={powerFactor}
                                onChange={(e) => this.setState({ 'power-factor': e.target.value })}
                                row
                            >
                                <FormControlLabel value='minor' control={<Radio disabled={chronos && chronos.length > 0} />} label={t('minor')} />
                                <FormControlLabel value='major' control={<Radio disabled={chronos && chronos.length > 0} />} label={t('major')} />
                                />
                            </RadioGroup>
                        </FormControl>
                    </div>
                    {manage && mode !== 'line-officer' && (
                        <div style={{ marginTop: '24px' }}>
                            <Typography variant='h6'>{t('payment')}</Typography>
                            <FormControlLabel
                                control={(
                                    <Checkbox
                                        disabled={me.permissions.indexOf('payments') === -1}
                                        checked={paid}
                                        onChange={() => { this.setState({ paid: !paid }); }}
                                    />
                                )}
                                label={(
                                    <div>
                                        {t('paid')}
                                        {match.payment === 'online' && edit && payment && (
                                        <span style={{ margin: '0px 24px', fontSize: '9px' }}>
                                            (
                                            {t('payment-status', { ...payment })}
                                            )
                                        </span>
                                        )}
                                        {match.payment === 'online' && edit && !payment && (
                                        <span
                                            role='button'
                                            tabIndex={0}
                                            style={{
                                                margin: '0px 24px', fontSize: '9px', textDecoration: 'underline', cursor: 'pointer',
                                            }}
                                            onKeyDown={() => {}}
                                            onClick={(e) => {
                                                this.getPaymentInfo();
                                                e.stopPropagation();
                                                e.preventDefault();
                                                return true;
                                            }}
                                        >
                                            {t('load-payment-info')}
                                        </span>
                                        )}
                                    </div>
                                )}
                            />
                        </div>
                    )}
                    {manage && mode !== 'line-officer' && (
                        <div style={{ marginTop: '24px' }}>
                            <Typography variant='h6'>{t('participation')}</Typography>
                            <FormControlLabel
                                control={(
                                    <Checkbox
                                        checked={cancelledParticipation}
                                        onChange={() => { this.setState({ cancelledParticipation: !cancelledParticipation }); }}
                                    />
                                )}
                                label={t('cancelledparticipation')}
                            />
                            {showInvalidCategory && cancelledParticipation && (
                                <div>
                                    <Typography variant='caption' style={{ color: red[500] }}>
                                        {t('invalidcategoryparticiaption', {
                                            division: t(`generic:division-${edit.division}`),
                                            category: t(`category-${edit.category}`),
                                        })}
                                    </Typography>
                                </div>
                            )}
                        </div>
                    )}
                    {(manage || match.squadSelection !== 'closed' || ro) && (
                        <div style={{ marginTop: '24px' }}>
                            <Typography variant='h6'>{t('generic:squad_number')}</Typography>
                            {(manage || match.squadSelection !== 'after-payment' || paid || ro) && (
                            <Select
                                value={squad || ''}
                                onChange={(e) => this.setState({ squad: e.target.value })}
                                disabled={cancelledParticipation}
                                error={errors.squad}
                            >
                                <MenuItem value={null} />
                                {(match.squads || []).filter(($squad) => (ro ? $squad.ro : !$squad.ro)).map(($squad) => {
                                    const numShooters = shooters.filter((s) => s.squad === $squad.id && s.id !== id).length;
                                    const left = Math.max(0, $squad.maxShooters - numShooters);
                                    const leftStr = (() => {
                                        if (!$squad.maxShooters) return null;
                                        return t(left === 0 ? 'noplacesleft' : 'placesleft', { count: left });
                                    })();

                                    return (
                                        <MenuItem value={$squad.id} key={$squad.id} disabled={($squad.maxShooters) && (left === 0)}>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <div>
                                                    <Typography variant='body1'>
                                                        {$squad.title}
                                                        {' '}
                                                        (
                                                        {$squad.description}
                                                        )
                                                    </Typography>
                                                </div>
                                                <div>
                                                    <Typography variant='caption'>{leftStr}</Typography>
                                                </div>
                                            </div>
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                            )}
                            {errors.squad && (
                                <FormHelperText style={{ color: red[500] }}>
                                    {t('squadselection-error')}
                                </FormHelperText>
                            )}
                            {!manage && match.squadSelection === 'after-payment' && !paid && !ro && (
                            <FormHelperText style={{ color: 'black' }}>
                                {t('squadselection-after-payment')}
                            </FormHelperText>
                            )}
                        </div>
                    )}
                    {(manage || (match.teams || []).length > 0) && (
                        <div style={{ marginTop: '24px' }}>
                            <Typography variant='h6'>{t('generic:shooter_team')}</Typography>
                            <Select
                                value={team || ''}
                                onChange={(e) => this.setState({ team: e.target.value })}
                            >
                                <MenuItem value={null} />
                                {(match.teams || []).map(($team) => (
                                    <MenuItem value={$team.id} key={$team.id}>
                                        {$team.title}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                    )}

                    {(manage || match.icsEnabled) && (
                        <div style={{ marginTop: '24px' }}>
                            <Typography variant='h6'>{t('generic:shooter_ics')}</Typography>
                            <Select
                                value={ics || null}
                                onChange={(e) => this.setState({ ics: e.target.value })}
                            >
                                <MenuItem value={null} />
                                {['grand-master', 'master', 'a', 'b', 'c', 'd'].map(($ics) => (
                                    <MenuItem value={$ics} key={$ics}>
                                        {t(`generic:ics-${$ics}`)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                    )}

                    {(match.registrationExtraFields || []).includes('equipment') && (
                        <EquipmentBelt
                            t={t}
                            equipmentObj={equipmentObj}
                            edit={equipmentEdit}
                            onSetEdit={(value) => this.setState({ equipmentEdit: value })}
                            onChange={this.onEquipmentField}
                        />
                    )}

                    {manage && edit && (
                    <div style={{ marginTop: '24px' }}>
                        <FormControl component='fieldset'>
                            <Typography variant='h6'>{t('reason-for-change')}</Typography>
                            <RadioGroup
                                aria-label='Reason for change'
                                name='reason-for-change'
                                value={changeReason}
                                onChange={(e) => this.setState({ changeReason: e.target.value })}
                            >
                                {(mode !== 'line-officer') && <FormControlLabel value='registration' control={<Radio />} label={t('change-registration')} />}
                                <FormControlLabel value='shooter-request' control={<Radio />} label={t('change-shooter-request')} />
                                {(powerFactor !== edit['power-factor']) && <FormControlLabel value='chrono' control={<Radio />} label={t('change-chrono')} />}
                                <FormControlLabel value='judge-decision' control={<Radio />} label={t('change-judge-decision')} />
                                />
                            </RadioGroup>
                        </FormControl>
                    </div>
                    )}
                </div>
                {manage && equipmentChecks && equipmentChecks.length > 0 && (
                    <div style={{
                        maxWidth: '720px', width: '90%', marginBottom: '6px', marginTop: '6px',
                    }}
                    >
                        <Typography variant='h6' style={{ paddingBottom: '6px' }}>{t('equipmentchecks')}</Typography>
                        <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>
                            {match.stages.map((s, i) => {
                                const key = `equipment-checks-${i}`;
                                const check = equipmentChecks[i] || {};
                                return (
                                    <div key={key} style={{ width: 'calc(50% - 24px)', padding: '12px' }}>
                                        {i + 1}
                                        .
                                        &nbsp;
                                        {check.check === 'ok' && <i style={{ color: green[500] }} className='fas fa-check' />}
                                        {check.check === 'absent' && <i style={{ color: grey[500] }} className='fas fa-minus' />}
                                        {check.check === 'error-open' && <i style={{ color: red[500] }} className='fas fa-times' />}
                                        {check.check === 'error-noscore' && <i style={{ color: red[500] }} className='fas fa-times' />}
                                        &nbsp;
                                        {check.ro && (
                                            <span>
                                                (
                                                {check.ro}
                                                )
                                            </span>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
                {manage && chronos && chronos.length > 0 && (
                    <div style={{
                        maxWidth: '720px', width: '90%', marginBottom: '6px', marginTop: '6px',
                    }}
                    >
                        <Typography variant='h6' style={{ paddingBottom: '6px' }}>{t('chronos')}</Typography>
                        {chronos.map((chrono) => (
                            <div
                                key={chrono.timestamp}
                                style={{
                                    border: '1px solid rgba(0,0,0,0.2',
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '12px',
                                }}
                            >
                                <Typography variant='body2' style={{ flex: 1 }}>
                                    {chrono.prevPowerFactor === chrono.nextPowerFactor ? t('chronoconfirmed', chrono) : null}
                                    {chrono.prevPowerFactor !== chrono.nextPowerFactor ? t('chronochanged', chrono) : null}
                                </Typography>
                                <Typography variant='caption'>{moment(chrono.timestamp).locale(match.locale).format('LL LT')}</Typography>
                            </div>
                        ))}
                    </div>
                )}
                {manage && log && log.length > 0 && (
                    <div style={{
                        maxWidth: '720px', width: '90%', marginBottom: '6px', marginTop: '6px',
                    }}
                    >
                        <Typography variant='h6' style={{ paddingBottom: '6px' }}>{t('log')}</Typography>
                        {log.map((l, i) => (
                            <div key={`log${JSON.stringify(l)}`} style={{ border: '1px solid rgba(0,0,0,0.2)', padding: '12px' }}>
                                <div>
                                    {l.changes.filter((change) => change.key !== 'extraFields').map((change) => (
                                        <div key={change.key} style={{ display: 'flex' }}>
                                            <div>
                                                <Typography variant='body2'>
                                                    {logToStr(t, match, change.key)}
                                                    : &nbsp;
                                                </Typography>
                                            </div>
                                            <div><Typography variant='body2'>{logToStr(t, match, change.key, change.pre || null)}</Typography></div>
                                            <div style={{ padding: '0px 12px' }}>
                                                {i18n.dir() === 'rtl'
                                                    ? <i className='fas fa-long-arrow-alt-left' />
                                                    : <i className='fas fa-long-arrow-alt-right' />}
                                            </div>
                                            <div><Typography variant='body2'>{logToStr(t, match, change.key, change.post || null)}</Typography></div>
                                        </div>
                                    ))}
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ flex: '1' }}><Typography variant='caption'>{t(`change-${l.reason}`)}</Typography></div>
                                    <div><Typography variant='caption'>{moment(l.modified).format('LL LT')}</Typography></div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                {!manage && (
                    <div style={{
                        maxWidth: '720px', width: '100%', marginTop: '24px', marginBottom: '6px',
                    }}
                    >
                        <FormControlLabel
                            control={(
                                <Checkbox
                                    checked={acceptTos}
                                    onChange={() => { this.setState({ acceptTos: !acceptTos }); }}
                                />
                            )}
                            label={(
                                <div>
                                    {t('termsofuse').split('###').map((s, i) => {
                                        if (i === 1) {
                                            return <a target='_blank' rel='noopener noreferrer' href='https://docs.google.com/document/d/1Saeek_HSNjO4_yW6FvtkQHOAFplhmxEs1Ctd-UMYOto/edit?usp=sharing' style={{ color: 'inherit' }}>{s}</a>;
                                        }
                                        if (i === 3) {
                                            return <a target='_blank' rel='noopener noreferrer' href='https://docs.google.com/document/d/1l7tK7PK0I3BYNuFC104ipvMhWIMETxahUi690oL_Wr0/edit?usp=sharing' style={{ color: 'inherit' }}>{s}</a>;
                                        }
                                        return <span>{s}</span>;
                                    })}
                                </div>
                            )}
                        />
                    </div>
                )}

                {!manage && (match.countryCode === 'IL') && (
                    <div style={{
                        maxWidth: '720px', width: '100%', marginTop: '6px', marginBottom: '6px',
                    }}
                    >
                        <FormControlLabel
                            control={(
                                <Checkbox
                                    checked={acceptTosIl}
                                    onChange={() => { this.setState({ acceptTosIl: !acceptTosIl }); }}
                                />
                            )}
                            label={(
                                <div>
                                    {t('termsofuseIL').split('###').map((s, i) => {
                                        if (i === 1) {
                                            return <a target='_blank' rel='noopener noreferrer' href='https://docs.google.com/document/d/1vcmmVPyjv-f2ByJ_t2VVg-2K_KRGbYpdAHxzzObt46c/edit?usp=sharing' style={{ color: 'inherit' }}>{s}</a>;
                                        }
                                        return <span>{s}</span>;
                                    })}
                                </div>
                            )}
                        />
                    </div>
                )}
                <Button
                    variant='contained'
                    color='primary'
                    style={{
                        maxWidth: '720px', width: manage ? '90%' : '100%', marginBottom: edit ? '12px' : '48px', marginTop: '24px',
                    }}
                    onClick={this.handleOnClick}
                    disabled={sending || !acceptTos || !acceptTosIl || canceling || (edit && edit.cancellationRequest) || (ro && !squad)}
                >
                    {sending ? <CircularProgress size={24} /> : t('generic:save')}
                </Button>

                {!manage && edit && !edit.cancellationRequest && (
                    <Button
                        variant='text'
                        color='secondary'
                        style={{
                            maxWidth: '720px', width: manage ? '90%' : '100%', marginBottom: '12px', marginTop: '24px', fontSize: '0.8em',
                        }}
                        onClick={this.handleOnCancel}
                        disabled={canceling}
                    >
                        {canceling ? <CircularProgress size={24} /> : t('cancel')}
                    </Button>
                )}

                {manage && edit && (
                    <FormControlLabel
                        style={{ maxWidth: '720px', width: '90%', marginBottom: '48px' }}
                        control={(
                            <Checkbox
                                checked={notifyemail}
                                onChange={() => { this.setState({ notifyemail: !notifyemail }); }}
                            />
                        )}
                        label={(<Typography variant='caption'>{t('notifyemail')}</Typography>)}
                    />
                )}

                {this.renderComments()}

                {saveError && (
                    <Snackbar
                        open={!!saveError}
                        onClose={() => this.setState({ saveError: null })}
                        variant='error'
                        message={
                            {
                                exists: t('userexists'),
                                'squad-full': t('squadfullerror'),
                                'unknown-shooter': t('unknownshootererror'),
                                'name-mismatch': t('namemismatcherror'),
                            }[saveError] || t('generic:saveerror')
                        }
                    />
                )}

                <Snackbar
                    open={saveSuccess}
                    onClose={() => this.setState({ saveSuccess: false })}
                    variant='success'
                    message={t('generic:savesuccess')}
                />
            </div>
        );
    }
}

function logToStr(t, match, param1, param2) {
    if (typeof (param2) === 'undefined') {
        switch (param1) {
        case 'division': return t('generic:shooter_division');
        case 'category': return t('generic:shooter_category');
        case 'power-factor': return t('generic:shooter_power_factor');
        case 'squad': return t('generic:squad_number');
        case 'team': return t('generic:shooter_team');
        case 'ics': return t('generic:shooter_ics');
        case 'publicId': return t('generic:shooter_id');
        case 'name': return t('generic:shooter_name');
        case 'club': return t('generic:shooter_club');
        case 'email': return t('generic:shooter_email');
        case 'paid': return t('paid');
        case 'cancelledParticipation': return t('cancelledparticipation');
        case 'cancellationRequest': return t('cancellationrequestlog');
        default:
            return param1;
        }
    } else {
        switch (param1) {
        case 'division': return t(`generic:division-${param2}`);
        case 'category': return t(`category-${param2}`);
        case 'power-factor': return t(param2);
        case 'squad': return (match.squads.find((s) => s.id === param2) || {}).title || '[]';
        case 'team': return (match.teams.find((s) => s.id === param2) || {}).title || '[]';
        case 'ics': return t(`generic:ics-${param2 || 'unclassified'}`);
        case 'paid': return param2 ? t('generic:yes') : t('generic:no');
        case 'cancelledParticipation': return param2 ? t('notparticipating') : t('participating');
        case 'cancellationRequest': return param2 ? t('cancellationrequested') : t('cancellationrequesterased');
        default:
            return `${param2}`;
        }
    }
}

const styles = () => {
    const base = {
        namerow: {
            display: 'flex',
            marginBottom: '16px',
            '& > div': {
                flex: 1,
            },
        },
        flagwrapper: {
            whiteSpace: 'nowrap',
            display: 'flex',
            flex: 1,
            textAlign: 'left',
            '& > div': {
                flex: 1,
            },
            '& .selected--flag--option  .country-label': {
                display: 'none',
            },
            '& .flag-select': {
                textAlign: 'left',
            },
        },
        instructions: {
            marginBottom: '12px',
        },
        instructionstitle: {
            marginBottom: '3px',
            fontWeight: 'bold',
            textDecoration: 'underline',
        },
    };

    for (let i = 0; i < 24; i++) {
        base[`equipmentslot-${i}`] = {
            transform: `rotate(${-45 + 15 * i}deg)`,
            '& > div': {
                transform: `rotate(${45 + (-15 * i)}deg)`,
            },
        };
    }

    return base;
};

Registration.propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        dir: PropTypes.func.isRequired,
    }).isRequired,
    classes: PropTypes.shape({}).isRequired,
    ro: PropTypes.bool,
    auth: PropTypes.string.isRequired,
    mode: PropTypes.oneOf([
        'line-officer',
        'line-officer-attendance',
    ]),
    manage: PropTypes.bool,
    shooters: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        publicId: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        division: PropTypes.string.isRequired,
        category: PropTypes.string.isRequired,
        'power-factor': PropTypes.string.isRequired,
    })).isRequired,
    match: PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        alias: PropTypes.string.isRequired,
        payment: PropTypes.string.isRequired,
        squadSelection: PropTypes.string.isRequired,
        squads: PropTypes.array,
        registrationExtraFields: PropTypes.array,
        registrationLimitGSheet: PropTypes.string,
        countryCode: PropTypes.string,
        region: PropTypes.string,
    }).isRequired,
    edit: PropTypes.shape({
        id: PropTypes.string,
        publicId: PropTypes.string,
        name: PropTypes.string,
        email: PropTypes.string,
        paid: PropTypes.bool,
        verified: PropTypes.bool,
        extraFields: PropTypes.objectOf(PropTypes.string),
        cancelledParticipation: PropTypes.bool,
        division: PropTypes.string,
        category: PropTypes.string,
        'power-factor': PropTypes.string,
        squad: PropTypes.string,
        cancellationRequest: PropTypes.shape({}),
        comments: PropTypes.arrayOf(PropTypes.shape({
            comment: PropTypes.string,
            name: PropTypes.string,
            timestamp: PropTypes.number,
        })),
        log: PropTypes.arrayOf(PropTypes.shape({
            changes: PropTypes.arrayOf(PropTypes.shape({
                key: PropTypes.string,
                pre: PropTypes.any,
                post: PropTypes.any,
            })),
        })),
    }),
    autofill: PropTypes.shape({
        publicId: PropTypes.string,
        name: PropTypes.string,
        email: PropTypes.string,
        extraFields: PropTypes.objectOf(PropTypes.string),
        division: PropTypes.string,
        category: PropTypes.string,
        'power-factor': PropTypes.string,
    }),
    me: PropTypes.shape({
        permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    onSuccess: PropTypes.func.isRequired,
    setTitle: PropTypes.func,
    setExtraButtons: PropTypes.func,
    setSaveButtonLabel: PropTypes.func,
    setSaveButtonCallback: PropTypes.func,
    setBackButtonVisible: PropTypes.func,
    registrationCode: PropTypes.string,
};

Registration.defaultProps = {
    mode: null,
    edit: null,
    autofill: null,
    manage: false,
    setTitle: null,
    setExtraButtons: null,
    setSaveButtonLabel: null,
    setSaveButtonCallback: null,
    setBackButtonVisible: null,
    registrationCode: null,
    ro: false,
};

export default withStyles(styles, { withTheme: true })(withTranslation('registration')(Registration));

/* eslint-enable react/no-unescaped-entities */
