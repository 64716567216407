import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import api from '../Api';
import {
    getScoresWithPoints, pad, getFlagSrc,
} from '../matches/Utils';
import GenericI18nThemeWrapper from '../components/GenericI18nThemeWrapper';

function LeagueMain(props) {
    const { leagueId, classes, onUpdateThemeLocale } = props;
    const [league, setLeague] = useState(null);

    useEffect(() => {
        (async () => {
            const $league = await api.getLeague({ leagueId });
            await setLeague($league);
        })();
    }, []);

    if (!league) {
        return (
            <div className={classes.wrapper}>
                <CircularProgress size={48} />
            </div>
        );
    }

    return (
        <GenericI18nThemeWrapper match={league} onUpdateThemeLocale={onUpdateThemeLocale}>
            <LeagueMainInternal {...props} league={league} />
        </GenericI18nThemeWrapper>
    );
}

LeagueMain.propTypes = {
    leagueId: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    classes: PropTypes.shape({}).isRequired,
    auth: PropTypes.string,
    onUpdateThemeLocale: PropTypes.func.isRequired,
};

LeagueMain.defaultProps = {
    auth: null,
};

const styles = () => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '12px',
    },
    slider: {
        display: 'block',
        flex: 1,
        width: 'calc(100% - 72px)',
        margin: '0px 36px',
        position: 'relative',
        height: '200px',
        '&> *': {
            minHeight: '0',
            minWidth: '0',
        },
        '& *:before': {
            color: 'black',
        },
    },
    matchcard: {
        margin: '12px',
        height: '200px',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
    },
    matchcardinfowrapper: {
        background: 'rgba(255, 255, 255, 0.6)',
        padding: '6px',
    },
    matchcardinfo: {
        display: 'flex',
        alignItems: 'center',
    },
    matchcardinfologo: {
        width: '50px',
        height: '50px',
        marginRight: '12px',
    },
    matchcardtitle: {
        whiteSpace: 'nowrap',
        width: '80%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontWeight: '800',
    },
});

export default withStyles(styles, { withTheme: true })(withTranslation('leaguemain')(LeagueMain));

function LeagueMainInternal(props) {
    const {
        league, t, classes, auth,
    } = props;
    const [matches, setMatches] = useState(null);
    const [division, setDivision] = useState('all');
    const [category, setCategory] = useState('all');
    const [sortBy, setSortBy] = useState('total');
    const [sortAscDesc, setSortAscDesc] = useState('desc');

    useEffect(() => {
        (async () => {
            const $matches = await Promise.all(league.matches.map(async (matchId) => {
                const [match, scores, shooters] = await Promise.all([
                    api.getMatch({ matchId, auth }),
                    api.getScores({ matchId, auth }),
                    api.getShooters({ matchId, auth }),
                ]);
                const stages = await api.getStages({ stages: match.stages, auth });

                return {
                    match, scores, shooters, stages,
                };
            }));

            await setMatches($matches);
        })();
    }, []);

    function toggleSortBy(key) {
        if (key === sortBy) {
            setSortAscDesc(sortAscDesc === 'asc' ? 'desc' : 'asc');
        } else {
            setSortAscDesc(key === 'name' ? 'asc' : 'desc');
            setSortBy(key);
        }
    }

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        centerMode: true,
    };

    const results = useMemo(() => {
        if (!matches) return null;

        // First, see shooters who competed in both regular category and specific category
        let shooterCategories = {};
        matches.forEach(({
            scores, shooters, stages,
        }) => {
            const { scoresOverall, dqs } = getScoresWithPoints({ scores, shooters, stages });
            scoresOverall.forEach((score) => {
                const publicId = score.shooterPublicId.split('-')[0];
                shooterCategories[publicId] = shooterCategories[publicId] || [];
                shooterCategories[publicId] = _.uniq([...shooterCategories[publicId], score.category]);
            });
            dqs.forEach((dq) => {
                const publicId = dq.shooterPublicId.split('-')[0];
                shooterCategories[publicId] = shooterCategories[publicId] || [];
                shooterCategories[publicId] = _.uniq([...shooterCategories[publicId], dq.category]);
            });
        });
        shooterCategories = _.pickBy(shooterCategories, (a) => a.length > 1);

        const $results = {};
        matches.forEach(({
            match, scores, shooters, stages,
        }) => {
            const { scoresOverall, dqs } = getScoresWithPoints({ scores, shooters, stages });
            scoresOverall.forEach((score) => {
                const publicId = score.shooterPublicId.split('-')[0];
                const categories = getCategories({ category: score.category, shooterCategories: shooterCategories[publicId] || [] });
                categories.forEach(($category) => {
                    const key = `${publicId}-${score.division}-${$category}`;
                    $results[key] = $results[key] || {
                        publicId, name: score.shooterName, division: score.division, category: $category, scores: {},
                    };
                    if (league.alias === 'il-2022-kohav.ezorit') {
                        $results[key].scores[match.id] = score.score * 100 * 100;
                    } else {
                        $results[key].scores[match.id] = score.percentage * 100;
                    }
                });
            });
            dqs.forEach((dq) => {
                const publicId = dq.shooterPublicId.split('-')[0];
                const categories = getCategories({ category: dq.category, shooterCategories: shooterCategories[publicId] || [] });
                categories.forEach(($category) => {
                    const key = `${publicId}-${dq.division}-${$category}`;
                    $results[key] = $results[key] || {
                        publicId: publicId.split('-')[0], name: dq.shooterName, division: dq.division, category: $category, scores: {},
                    };
                    $results[key].scores[match.id] = 'DQ';
                });
            });
        });

        Object.keys($results).forEach((k) => {
            const scores = league.matches.map((k2) => ($results[k].scores[k2] || 0)).map((s) => (s === 'DQ' ? 0 : s)).sort((a, b) => b - a);

            if (league.alias === 'il-2019-europe-slots') {
                if (scores[scores.length - 1] === $results[k].scores['5732503335731200']) {
                    $results[k].total = _.sum(scores.slice(0, 7)); /* best 7, drop 8 (isr-nat) */
                } else if (scores[scores.length - 2] === $results[k].scores['5732503335731200']) {
                    $results[k].total = _.sum(scores.slice(0, 5)) + (($results[k].scores['5732503335731200'] || 0) * 2); /* best 5, drop 6, isr-nat * 2, drop 8 */
                } else {
                    $results[k].total = _.sum(scores.slice(0, 6)) + ($results[k].scores['5732503335731200'] || 0); /* isr-nat * 2 */
                }
            } else if (league.alias === 'il-2020') {
                $results[k].total = _.sum(scores.slice(0, 6));
            } else if (league.alias === 'il-2021') {
                $results[k].total = _.sum(scores.slice(0, 5));
            } else if (league.alias === 'il-2022-3-of-5') {
                $results[k].total = _.sum(scores.slice(0, 3));
            } else if (league.alias === 'il-2022-4-of-6') {
                $results[k].total = _.sum(scores.slice(0, 4));
            } else if (league.alias === 'il-2022-pcc-4-of-5') {
                $results[k].total = _.sum(scores.slice(0, 4));
            } else if (league.alias === 'il-2022-pcc-3-of-5') {
                $results[k].total = _.sum(scores.slice(0, 3));
            } else if (league.alias === 'il-2023') {
                $results[k].total = _.sum(scores.slice(0, 3));
            } else if (league.alias === 'il-2023-drop-1') {
                $results[k].total = _.sum(scores.slice(0, 4));
            } else {
                $results[k].total = _.sum(scores.slice(0, 7));
            }
        });

        return $results;
    }, [matches]);

    if (!matches) {
        return (
            <div className={classes.wrapper}>
                <Typography variant='h4'>{league.title}</Typography>
                <CircularProgress size={48} />
            </div>
        );
    }

    const displayed = _(results)
        .values()
        .filter((r) => {
            if ((division !== 'all') && (r.division !== division)) return false;
            if ((category !== 'all') && (r.category !== category)) return false;
            return true;
        })
        .orderBy((() => {
            if (sortBy.startsWith('score-')) {
                return (r) => {
                    const [, matchId] = sortBy.split('-');
                    const score = r.scores[matchId];
                    if (_.isUndefined(score)) return -2;
                    if (score === 'DQ') return -1;
                    return Number(parseFloat(r.scores[matchId], 10));
                };
            }
            if (sortBy === 'publicId') {
                return [
                    (r) => r.publicId.split('|')[0],
                    (r) => Number(parseInt(r.publicId.split('|')[1], 10)),
                ];
            }
            if (sortBy === 'total') {
                return (r) => Number(parseFloat(r.total, 10));
            }
            return (r) => r[sortBy];
        })(), [sortAscDesc, sortAscDesc])
        .value();

    if (matches.length < 3) {
        settings.slidesToShow = 2;
    }

    return (
        <div>
            <div className={classes.wrapper}>
                <Typography variant='h4'>{league.title}</Typography>
            </div>
            <div className={classes.slider}>
                <Slider {...settings}>
                    {matches.map(({ match }) => (
                        <div key={match.id}>
                            <div
                                tabIndex='0'
                                role='button'
                                className={classes.matchcard}
                                onKeyDown={() => {}}
                                onClick={() => window.open(`https://www.endofscoring.com/${match.alias}`, '_black')}
                                style={{ backgroundImage: `url("${match.cover}")` }}
                            >
                                <div style={{ flex: 1 }} />
                                <div className={classes.matchcardinfowrapper}>
                                    <div className={classes.matchcardinfo}>
                                        <img alt={match.title} className={classes.matchcardinfologo} src={match.logo} />
                                        <div style={{ flex: 1 }}>
                                            <Typography variant='subtitle1' className={classes.matchcardtitle}>
                                                {match.title}
                                            </Typography>
                                            <Typography variant='caption' className={classes.matchcardtitle}>
                                                {match.description}
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
                <div className='noprint' style={{ display: 'flex', justifyContent: 'center' }}>
                    <Select style={{ margin: '0px 12px' }} value={division || 'all'} onChange={(e) => setDivision(e.target.value)}>
                        {['open', 'standard', 'production', 'production-optics', 'production-optics-light', 'classic', 'revolver', 'pcc-optics', 'pcc-iron', null].map((d) => (
                            <MenuItem value={d || 'all'} key={d || 'all'}>{t(d ? `generic:division-${d}` : 'scoretable:all-divisions')}</MenuItem>
                        ))}
                    </Select>
                    <Select style={{ margin: '0px 12px' }} value={category || 'all'} onChange={(e) => setCategory(e.target.value)}>
                        {['lady', 'junior', 'super-junior', 'senior', 'super-senior', 'grand-senior', 'lady-senior', null].map((c) => (
                            <MenuItem value={c || 'all'} key={c || 'all'}>{t(c ? `registration:category-${c}` : 'scoretable:all-categories')}</MenuItem>
                        ))}
                    </Select>
                </div>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel active={sortBy === 'publicId'} direction={sortAscDesc} onClick={() => toggleSortBy('publicId')}>
                                    {t('generic:shooter_id')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel active={sortBy === 'name'} direction={sortAscDesc} onClick={() => toggleSortBy('name')}>
                                    {t('generic:shooter_name')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel active={sortBy === 'division'} direction={sortAscDesc} onClick={() => toggleSortBy('division')}>
                                    {t('generic:shooter_division')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel active={sortBy === 'category'} direction={sortAscDesc} onClick={() => toggleSortBy('category')}>
                                    {t('generic:shooter_category')}
                                </TableSortLabel>
                            </TableCell>
                            {matches.map(({ match }) => (
                                <TableCell key={match.id}>
                                    <TableSortLabel
                                        active={sortBy === `score-${match.id}`}
                                        direction={sortAscDesc}
                                        onClick={() => toggleSortBy(`score-${match.id}`)}
                                    >
                                        {match.title}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                            <TableCell>
                                <TableSortLabel active={sortBy === 'total'} direction={sortAscDesc} onClick={() => toggleSortBy('total')}>
                                    {t('finalscore')}
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {displayed.map((s) => (
                            <TableRow key={`${s.publicId}-${s.division}-${s.category}`}>
                                <TableCell>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {(s.publicId.indexOf('|') > -1) && (
                                            <img
                                                alt={s.publicId.split('|')[0]}
                                                src={getFlagSrc({ countryCode: s.publicId.split('|')[0], size: 16 })}
                                            />
                                        )}
                                        <div style={{ padding: '0px 6px' }}>
                                            {s.publicId.split('|').pop()}
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell>
                                    {s.name}
                                </TableCell>
                                <TableCell>
                                    {t(`generic:division-${s.division}`)}
                                </TableCell>
                                <TableCell>
                                    {t(`generic:category-${s.category}`)}
                                </TableCell>
                                {matches.map(({ match }) => (
                                    <TableCell key={match.id}>
                                        {s.scores[match.id] === 'DQ' ? 'DQ' : _.isUndefined(s.scores[match.id]) ? '-' : pad(s.scores[match.id] / 10000, 2)}
                                    </TableCell>
                                ))}
                                <TableCell>
                                    {pad(s.total / 10000, 2)}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </div>
    );
}

LeagueMainInternal.propTypes = {
    league: PropTypes.shape({
        id: PropTypes.string.isRequired,
        alias: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        matches: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    t: PropTypes.func.isRequired,
    classes: PropTypes.shape({}).isRequired,
    auth: PropTypes.string,
};

LeagueMainInternal.defaultProps = {
    auth: null,
};

function getCategories({ category, shooterCategories }) {
    if (category === 'regular') return ['regular'];
    if ((shooterCategories.length > 1) && (category !== 'regular')) return ['regular', category];
    return [category];
}
