import React, { Component } from 'react';
import _ from 'lodash';
import SignatureCanvas from 'react-signature-canvas';
import PropTypes from 'prop-types';
import moment from 'moment';
import arrayToSentence from 'array-to-sentence';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import PeopleIcon from '@material-ui/icons/People';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import TransferIcon from '@material-ui/icons/TransferWithinAStation';
import TextField from '@material-ui/core/TextField';
import Zoom from '@material-ui/core/Zoom';
import red from '@material-ui/core/colors/red';
import Snackbar from '../components/Snackbar';
import ScoreForm from './Score.form';
import api from '../Api';

export default class ScoreSignaturedForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dqTime: new Date().toISOString().replace(/\..*$/, ''),
            editableRequest: false,
        };
    }

    componentDidMount() {
        this.updateHeader();
    }

    shouldComponentUpdate(nextProps, nextState) {
        const a = _.pick(this.props, ['editable', 'sending', 'roSignature']);
        const b = _.pick(nextProps, ['editable', 'sending', 'roSignature']);
        const c = _.pick(this.state, ['editableRequest', 'showDeleteApprove', 'deleting', 'dqTime']);
        const d = _.pick(nextState, ['editableRequest', 'showDeleteApprove', 'deleting', 'dqTime']);
        return !(_.isEqual(a, b) && _.isEqual(c, d));
    }

    componentDidUpdate() {
        this.updateHeader();
    }

    updateHeader = () => {
        const {
            t, i18n, reviewing, stageIdx, shooterId, shooters, editable, me, setBackButtonVisible,
            setTitle, setExtraButtons, setSaveButtonCallback, onShowNextShooters,
        } = this.props;

        const {
            editableRequest,
        } = this.state;

        if (!reviewing) {
            setTitle(`${t('managewrapper:scores')} - ${t('stage')} ${stageIdx} / ${shooters.find((s) => s.id === shooterId).name}`);
            setExtraButtons([
                <Button key='shooters' color='primary' size='large' onClick={onShowNextShooters}>
                    <PeopleIcon />
                </Button>,
            ]);
            setSaveButtonCallback(null);
            setBackButtonVisible(true);
        } else {
            setTitle(`${t('managewrapper:scores')} - ${t('stage')} ${stageIdx} / ${shooters.find((s) => s.id === shooterId).name}`);

            const buttons = [];

            const lockDisabled = !!editableRequest
                || ((me.permissions || []).indexOf('approve-rescoring') === -1);

            buttons.push(
                <Button
                    key='editable'
                    disabled={lockDisabled}
                    variant='contained'
                    color='secondary'
                    style={{ [i18n.dir() === 'rtl' ? 'marginLeft' : 'marginRight']: '12px' }}
                    onClick={() => this.onEditable()}
                >
                    {editableRequest ? <CircularProgress size='18px' />
                        : editable ? <i className='fas fa-lock-open' /> : <i className='fas fa-lock' />}
                </Button>,
            );

            setExtraButtons(buttons);
            setSaveButtonCallback(null);
            setBackButtonVisible(true);
        }
    }

    onEditable = async () => {
        const {
            t, matchId, scoreId, auth, editable, onUpdateScore, onSaveError,
        } = this.props;
        await new Promise((res) => this.setState({ editableRequest: true }, res));
        try {
            const newScore = await api.patchScore({
                matchId,
                scoreId,
                auth,
                patch: {
                    editable: !editable,
                },
            });
            await new Promise((res) => this.setState({ editableRequest: false }, res));
            onUpdateScore(newScore);
        } catch (e) {
            await new Promise((res) => this.setState({ editableRequest: false }, res));
            onSaveError(t('senderror'));
        }
    }

    onDelete = () => {
        this.setState({ showDeleteApprove: true });
    }

    onDeleteApproved = async () => {
        const {
            t, matchId, scoreId, auth, onDeleteScore, onSaveError,
        } = this.props;
        this.setState({ deleting: true });
        try {
            await api.deleteScore({ matchId, scoreId, auth });
            await new Promise((res) => this.setState({ deleting: false, showDeleteApprove: false }, res));
            onDeleteScore({ id: scoreId });
        } catch (e) {
            await new Promise((res) => this.setState({ deleting: false, showDeleteApprove: false }, res));
            onSaveError(t('senderror'));
        }
    }

    render() {
        const {
            reviewing, stageIdx, shooterId, matchId,
            t, i18n, match, ro, stages,
            shooters, sending, dq, formTime, poppers, tenPointsPoppers,
            scorePerTarget, penaltiesByType, specialPenalty,
            noShoots, time, bonusField, signature, roSignature,
            onSave, onTempSave, onClearSignature, onClearRoSignature,
            onEdit, me, onTransfer, editable, scoreFormDisplayMode,
        } = this.props;

        const {
            dqTime, showDeleteApprove, deleting,
        } = this.state;

        const shooter = shooters.find((s) => s.id === shooterId);
        const stage = stages[stageIdx - 1];

        let roSignatureCanvas = null;
        let signatureCanvas = null;

        const rm = ((me.permissions || []).indexOf('approve-rescoring') > -1);

        const targetMisses = (_.values(scorePerTarget) || []).join('').split('').filter((s) => s === 'M').length;
        const bonusMisses = (_.values(scorePerTarget) || []).join('').split('').filter((s) => s === 'm').length;
        const metalMisses = match.scoreMode === 'steel-no-miss' ? 0 : (stage.targets.poppers || 0) + (stage.targets.minipoppers || 0) + (stage.targets.plates || 0) + (stage.targets['pcc-frangible'] || 0) + (stage.targets['pcc-ten-points-poppers'] || 0) + (stage.targets['pcc-ten-points-frangible'] || 0) - (poppers || 0) - (tenPointsPoppers || 0);
        const warning = arrayToSentence(_.compact([
            targetMisses ? t('signingwarningtargetmisses', { count: targetMisses }) : null,
            metalMisses ? t('signingwarningmetalmisses', { count: metalMisses }) : null,
            bonusMisses ? t('signingwarningbonustargetmisses', { count: bonusMisses }) : null,
        ]), {
            lastSeparator: t('generic:last_separator'),
        });

        return (
            <div style={{ margin: 'auto', marginBottom: '24px' }}>
                <ScoreForm
                    title={match.title}
                    ro={ro}
                    stage={stages[stageIdx - 1]}
                    stageIdx={stageIdx}
                    shooter={shooter}
                    poppers={poppers}
                    tenPointsPoppers={tenPointsPoppers}
                    scorePerTarget={scorePerTarget}
                    penaltiesByType={penaltiesByType}
                    specialPenalty={specialPenalty}
                    noShoots={noShoots}
                    dq={dq}
                    formTime={moment(formTime || undefined).format('L LT')}
                    bonusField={bonusField}
                    time={time}
                    scoreMode={match.scoreMode}
                    scoreFormDisplayMode={scoreFormDisplayMode}
                    signatureNode={(
                        <div>
                            <div>
                                {SignatureArea({
                                    matchId,
                                    i18n,
                                    editable: !reviewing,
                                    signature: roSignature,
                                    title: t('ro-signature'),
                                    signee: ro,
                                    onRegisterCanvas: (e) => { roSignatureCanvas = e; },
                                    onClear: () => {
                                        if (roSignatureCanvas) {
                                            roSignatureCanvas.clear();
                                        }
                                        onClearRoSignature();
                                    },
                                })}
                            </div>
                            {!dq && (
                                <div>
                                    {!reviewing && !!time && (targetMisses > 0 || bonusMisses > 0 || metalMisses > 0) && (
                                        <Typography
                                            variant='body2'
                                            style={{
                                                marginTop: '24px',
                                                backgroundColor: red[500],
                                                color: 'white',
                                                padding: '12px',
                                                textAlign: 'center',
                                                boxShadow: '1px 1px 6px rgba(0,0,0,0.2)',
                                            }}
                                        >
                                            {t('signingwarning', { warning })}
                                        </Typography>
                                    )}
                                    <div style={{ position: 'relative', marginTop: '24px' }}>
                                        {SignatureArea({
                                            matchId,
                                            i18n,
                                            editable: !reviewing || !signature,
                                            signature,
                                            title: t('signature'),
                                            signee: shooter.name,
                                            onRegisterCanvas: (e) => { signatureCanvas = e; },
                                            onClear: () => {
                                                if (signatureCanvas) {
                                                    signatureCanvas.clear();
                                                }
                                                onClearSignature();
                                            },
                                        })}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                />
                {!reviewing && dq && (
                    <TextField
                        id='datetime-local'
                        type='datetime-local'
                        label={t('score:dqtime')}
                        value={dqTime}
                        onChange={(e) => {
                            this.setState({ dqTime: e.target.value });
                        }}
                    />
                )}
                {(!reviewing || (!signature && !dq)) && !sending && onSave && (
                    <Button
                        variant='contained'
                        color='primary'
                        fullWidth
                        onClick={() => onSave({
                            signature: dq ? null : signature || (signatureCanvas.isEmpty() ? null : signatureCanvas.toDataURL()),
                            roSignature: roSignature || (roSignatureCanvas.isEmpty() ? null : roSignatureCanvas.toDataURL()),
                            dqTime: dq ? dqTime : null,
                        })}
                    >
                        {t('save-and-send-email')}
                    </Button>
                )}
                {!reviewing && !dq && !sending && onTempSave && (
                    <Typography
                        variant='caption'
                        style={{
                            color: red[500],
                            marginTop: '36px',
                            marginBottom: '24px',
                            cursor: 'pointer',
                            textAlign: 'center',
                        }}
                        onClick={() => onTempSave({
                            roSignature: roSignature || (roSignatureCanvas.isEmpty() ? null : roSignatureCanvas.toDataURL()),
                        })}
                    >
                        {t('save-temporary')}
                    </Typography>
                )}
                {sending && (
                    <div style={{
                        display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: '12px',
                    }}
                    >
                        <CircularProgress size={48} />
                    </div>
                )}
                <div style={{ position: 'fixed', bottom: '12px', [i18n.dir() === 'ltr' ? 'right' : 'left']: '12px' }}>
                    <Zoom
                        in={editable && rm && reviewing}
                        timeout={{ enter: 200, exit: 200 }}
                        unmountOnExit
                        style={{ transitionDelay: '400ms' }}
                        onClick={this.onDelete}
                    >
                        <Fab color='secondary'>
                            <DeleteIcon />
                        </Fab>
                    </Zoom>
                    <Zoom
                        in={editable && rm && reviewing}
                        timeout={{ enter: 200, exit: 200 }}
                        unmountOnExit
                        style={{ transitionDelay: '300ms', margin: '0px 12px' }}
                        onClick={onTransfer}
                    >
                        <Fab color='primary'>
                            <TransferIcon />
                        </Fab>
                    </Zoom>
                    <Zoom
                        in={editable && reviewing}
                        timeout={{ enter: 200, exit: 200 }}
                        unmountOnExit
                        style={{ transitionDelay: '200ms' }}
                        onClick={onEdit}
                    >
                        <Fab color='primary'>
                            <EditIcon />
                        </Fab>
                    </Zoom>
                </div>

                <Snackbar
                    open={!!showDeleteApprove}
                    variant='warning'
                    message={(
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                            <div style={{ flex: 1 }}>
                                {t('are-you-sure-delete-score')}
                            </div>
                            <Button style={{ color: 'white', margin: '12px' }} onClick={this.onDeleteApproved}>
                                {deleting ? <CircularProgress size={12} color='white' /> : t('generic:yes')}
                            </Button>
                            <Button style={{ color: 'white' }} onClick={() => this.setState({ showDeleteApprove: null })}>
                                {t('generic:no')}
                            </Button>
                        </div>
)}
                />
            </div>
        );
    }
}

function SignatureArea(props) {
    const {
        matchId, i18n, editable, signature, title, signee, onRegisterCanvas, onClear,
    } = props;

    return (
        <div
            style={{
                position: 'relative', margin: 'auto', textAlign: 'center', width: '317px',
            }}
        >

            {/* Editable, and no saved signature - show backdrop */}
            {editable && !signature && (
                <div style={{
                    position: 'absolute', [i18n.dir() === 'rtl' ? 'right' : 'left']: '0px', top: '0px', height: '150px', width: '317px', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', fontSize: '24px', color: 'rgba(0,0,0,0.3)', fontWeight: '300', zIndex: '0',
                }}
                >
                    {title}
                    <br />
                    {signee}
                </div>
            )}

            {/* Editable, and no saved signature - show canvas */}
            {editable && !signature && (
                <SignatureCanvas
                    ref={(e) => onRegisterCanvas(e)}
                    penColor='#2196f3'
                    canvasProps={{
                        width: 317,
                        height: 150,
                        style: {
                            position: 'relative', zIndex: '2', background: 'transparent', boxShadow: 'rgba(0, 0, 0, 0.8) 0px 0px 3px',
                        },
                    }}
                />
            )}

            {/* Editable - show delete button */}
            {editable && (
                <Button
                    color='secondary'
                    onClick={() => onClear()}
                    style={{
                        position: 'absolute', top: '0px', right: '0px', minWidth: '40px', padding: '0px', width: '40px', height: '40px', zIndex: 2,
                    }}
                >
                    <CloseIcon />
                </Button>
            )}

            {/* Noneditable - show image */}
            {signature && (
                <div style={{ textAlign: 'center' }}>
                    <Typography variant='subtitle2'>{title}</Typography>
                    {(`${signature}`).indexOf('base64') > -1
                        ? <img alt='signature' src={signature} style={{ width: '317px', height: '150px' }} />
                        : <img alt='signature' src={`https://www.endofscoring.com/api/matches/${matchId}/scores/signatures/${signature}`} style={{ width: '317px', height: '150px' }} />}
                </div>
            )}
        </div>
    );
}

SignatureArea.propTypes = {
    i18n: PropTypes.shape({
        dir: PropTypes.func.isRequired,
    }).isRequired,
    matchId: PropTypes.string.isRequired,
    editable: PropTypes.bool.isRequired,
    signature: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    signee: PropTypes.string.isRequired,
    onRegisterCanvas: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
};

ScoreSignaturedForm.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        dir: PropTypes.func.isRequired,
    }).isRequired,
    stageIdx: PropTypes.number.isRequired,
    shooterId: PropTypes.string.isRequired,
    auth: PropTypes.string.isRequired,
    matchId: PropTypes.string.isRequired,
    scoreId: PropTypes.string,
    time: PropTypes.number.isRequired,
    reviewing: PropTypes.bool,
    editable: PropTypes.bool,
    me: PropTypes.shape({
        name: PropTypes.string.isRequired,
        permissions: PropTypes.array.isRequired,
    }).isRequired,
    stages: PropTypes.arrayOf(PropTypes.shape({
        targets: PropTypes.shape({
            poppers: PropTypes.number,
            minipoppers: PropTypes.number,
            plates: PropTypes.number,
            'pcc-frangible': PropTypes.number,
            'pcc-ten-points-poppers': PropTypes.number,
            'pcc-ten-points-frangible': PropTypes.number,
        }).isRequired,
        rounds: PropTypes.number.isRequired,
    })).isRequired,
    shooters: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
    match: PropTypes.shape({
        title: PropTypes.string,
        scoreMode: PropTypes.string,
    }).isRequired,
    ro: PropTypes.string.isRequired,
    dq: PropTypes.string,
    signature: PropTypes.string,
    roSignature: PropTypes.string,
    sending: PropTypes.bool,
    formTime: PropTypes.number,
    bonusField: PropTypes.number,
    scoreFormDisplayMode: PropTypes.string,
    scorePerTarget: PropTypes.arrayOf(PropTypes.string),
    penaltiesByType: PropTypes.shape({}).isRequired,
    specialPenalty: PropTypes.number.isRequired,
    poppers: PropTypes.number.isRequired,
    tenPointsPoppers: PropTypes.number.isRequired,
    noShoots: PropTypes.number.isRequired,
    onShowNextShooters: PropTypes.func,
    onEdit: PropTypes.func.isRequired,
    onTransfer: PropTypes.func.isRequired,
    onUpdateScore: PropTypes.func.isRequired,
    onSaveError: PropTypes.func.isRequired,
    onDeleteScore: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onTempSave: PropTypes.func,
    onClearSignature: PropTypes.func,
    onClearRoSignature: PropTypes.func,
    setTitle: PropTypes.func.isRequired,
    setExtraButtons: PropTypes.func.isRequired,
    setSaveButtonCallback: PropTypes.func.isRequired,
    setBackButtonVisible: PropTypes.func.isRequired,
};

ScoreSignaturedForm.defaultProps = {
    dq: null,
    scoreId: null,
    sending: false,
    reviewing: false,
    editable: false,
    signature: null,
    roSignature: null,
    formTime: null,
    scorePerTarget: null,
    scoreFormDisplayMode: null,
    bonusField: 0,
    onShowNextShooters: () => {},
    onTempSave: () => {},
    onClearSignature: () => {},
    onClearRoSignature: () => {},
};
