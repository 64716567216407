/* eslint-disable jsx-a11y/anchor-has-content */
import './Stage.css';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import arrayToSentence from 'array-to-sentence';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import BrokenImageIcon from '@material-ui/icons/BrokenImage';
import AddIcon from '@material-ui/icons/Add';
import DoneIcon from '@material-ui/icons/Done';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import Zoom from '@material-ui/core/Zoom';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import red from '@material-ui/core/colors/red';
import _ from 'lodash';
import Snackbar from '../components/Snackbar';
import api from '../Api';

class Stage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: null,
            stage: [],
            edit: true,
            saving: false,
            saveError: null,
            saveSuccess: false,
        };
    }

    async componentDidMount() {
        const {
            t, manage, matchId, stageIdx, setTitle, setExtraButtons, setSaveButtonCallback, setBackButtonVisible,
        } = this.props;

        if (manage) {
            setTitle(`${t('title')} - ${t('stage')} ${stageIdx}`);
            setExtraButtons([
                <Tooltip title={t('print')}>
                    <IconButton onClick={this.print}>
                        <i className='fas fa-print' style={{ fontSize: '24px' }} />
                    </IconButton>
                </Tooltip>,
            ]);
            setSaveButtonCallback(null);
            setBackButtonVisible(true);
        }

        const match = await api.getMatch({ matchId });

        const stage = await (async () => {
            if (stageIdx !== 'new') {
                const stageId = match.stages[stageIdx - 1];
                return (await api.getStages({ stages: [stageId] }))[0];
            }
            return {
                matchId,
                title: t('default-new-stage-title'),
                targets: {
                    paper: 1,
                    minipaper: 0,
                    poppers: 0,
                    minipoppers: 0,
                    'pcc-ten-points-poppers': 0,
                    'pcc-frangible': 0,
                    'pcc-ten-points-frangible': 0,
                    plates: 0,
                    'paper-no-shoots': 0,
                    'metal-no-shoots': 0,
                },
                rounds: 2,
                startPosition: t('default-new-stage-start-position'),
                gunCondition: t('default-new-stage-gun-condition'),
                pccCondition: t('default-new-stage-pcc-condition'),
                start: t('default-new-stage-start'),
                end: t('default-new-stage-end'),
                procedure: t('default-new-stage-procedure'),
                comments: '',
            };
        })();

        this.setState({
            match,
            edit: manage && (stageIdx === 'new'),
            stageId: stageIdx === 'new' ? 'new' : match.stages[stageIdx - 1],
            stageIdx,
            title: match.title,
            stage,
            editStage: JSON.parse(JSON.stringify(stage)),
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            t, manage, stageIdx, setTitle, setExtraButtons, setSaveButtonCallback, setBackButtonVisible,
        } = this.props;
        const { edit } = this.state;
        if (prevState.edit !== edit) {
            setTitle(`${t('title')} - ${t('stage')} ${stageIdx}`);
            if (manage) {
                setExtraButtons([
                    <Tooltip title={t('print')}>
                        <IconButton onClick={this.print}>
                            <i className='fas fa-print' style={{ fontSize: '24px' }} />
                        </IconButton>
                    </Tooltip>,
                ]);
                setSaveButtonCallback(null);
                setBackButtonVisible(true);
            }
        }

        if (prevProps.stageIdx !== stageIdx) {
            this.setState({ title: null }, () => {
                this.componentDidMount();
            });
        }
    }

    onDelete = () => {
        this.setState({ deleteRequest: true });
    }

    onDeleteAck = async () => {
        const {
            t, auth, matchId, stageIdx, onDelete,
        } = this.props;
        const { match } = this.state;

        await new Promise((res) => this.setState({ deleting: true }, res));
        try {
            await api.deleteStage({
                matchId, stageIdx, auth, stageId: match.stages[stageIdx - 1],
            });
            await new Promise((res) => this.setState({ deleting: false, deleteRequest: false }, res));
            onDelete();
        } catch (e) {
            if (_.get(e, 'response.data.status') === 403) {
                await new Promise((res) => this.setState({ deleting: false, deleteRequest: false, saveError: t('errorscoringstarted') }, res));
            } else {
                await new Promise((res) => this.setState({ deleting: false, deleteRequest: false, saveError: t('generic:saveerror') }, res));
            }
        }
    }

    onSave = async ({ force } = {}) => {
        const { stageId, editStage, imgFile } = this.state;
        const {
            t, auth, matchId, onSaveNew,
        } = this.props;

        if (editStage.targets.noshoots === '') {
            delete editStage.targets.noshoots;
        }

        const oneShotRounds = (editStage.targets.paper || 0) + (editStage.targets.minipaper || 0) + (editStage.targets.poppers || 0) + (editStage.targets.minipoppers || 0) + (editStage.targets.plates || 0) + (editStage.targets['pcc-ten-points-poppers'] || 0) + (editStage.targets['pcc-frangible'] || 0) + (editStage.targets['pcc-ten-points-frangible'] || 0);
        const twoShotsRounds = 2 * (editStage.targets.paper || 0) + 2 * (editStage.targets.minipaper || 0) + (editStage.targets.poppers || 0) + (editStage.targets.minipoppers || 0) + (editStage.targets.plates || 0) + (editStage.targets['pcc-ten-points-poppers'] || 0) + (editStage.targets['pcc-frangible'] || 0) + (editStage.targets['pcc-ten-points-frangible'] || 0);
        const threeShotsRounds = 3 * (editStage.targets.paper || 0) + 3 * (editStage.targets.minipaper || 0) + (editStage.targets.poppers || 0) + (editStage.targets.minipoppers || 0) + (editStage.targets.plates || 0) + (editStage.targets['pcc-ten-points-poppers'] || 0) + (editStage.targets['pcc-frangible'] || 0) + (editStage.targets['pcc-ten-points-frangible'] || 0);

        if ((editStage.rounds !== oneShotRounds) && (editStage.rounds !== twoShotsRounds) && (editStage.rounds !== threeShotsRounds)) {
            this.setState({ saving: false, saveError: t('invalid-number-rounds', { oneShotRounds, twoShotsRounds, threeShotsRounds }) });
            return;
        }

        await new Promise((res) => this.setState({ saving: true }, res));

        try {
            if (stageId === 'new') {
                const newStageId = (await api.postStage({ stage: _.omit(editStage, 'img') })).stageId;

                if (imgFile) {
                    const data = new FormData();
                    data.append('file', imgFile);
                    const { url } = await api.postStageImage({
                        stageId: newStageId, auth, img: data,
                    });
                    editStage.img = url;
                    await api.updateStage({
                        stageId: newStageId, auth, force, stage: _.omit(editStage, ['id']),
                    });
                }
                await api.postMatchStage({ matchId, auth, stageId: newStageId });
                onSaveNew();
            } else {
                if (imgFile) {
                    const data = new FormData();
                    data.append('file', imgFile);
                    const { url } = await api.postStageImage({ stageId, auth, img: data });
                    editStage.img = url;
                }
                await api.updateStage({
                    stageId, auth, force, stage: _.omit(editStage, ['id']),
                });
            }
            await new Promise((res) => this.setState({
                saving: false, edit: false, saveSuccess: true, stage: JSON.parse(JSON.stringify(editStage)),
            }, res));
        } catch (e) {
            console.error(e);
            if (_.get(e, 'response.data.status') === 403) {
                await new Promise((res) => this.setState({ saveError: this.getSavingScoredMessage() }, res));
            } else if (_.get(e, 'response.data.status') === 401) {
                await new Promise((res) => this.setState({ saving: false, saveError: t('permissiondenied') }, res));
            } else {
                await new Promise((res) => this.setState({ saving: false, saveError: t('generic:saveerror') }, res));
            }
        }
    }

    getSavingScoredMessage = () => {
        const { t } = this.props;
        return (
            <div style={{
                width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center',
            }}
            >
                <div style={{ flex: 1 }}>
                    {t('savescored')}
                </div>
                <Button variant='outlined' color='secondary' style={{ color: 'white', margin: '12px' }} onClick={() => this.setState({ saveError: null }, () => this.onSave({ force: true }))}>
                    {t('savescored-force')}
                </Button>
                <Button variant='outlined' color='secondary' style={{ color: 'white' }} onClick={() => this.setState({ saving: false, saveError: null })}>
                    {t('generic:ok')}
                </Button>
            </div>
        );
    }

    handleImageUpload = async (e) => {
        const { editStage } = this.state;
        const file = e.target.files[0];

        const reader = new FileReader();
        reader.onload = (ev) => {
            this.setState({ imgFile: file, editStage: { ...editStage, img: ev.target.result } });
        };
        reader.readAsDataURL(file);
    }

    print = async () => {
        window.print();
    }

    /*
    printPDF = async () => {
        const { match } = this.props;
        const { stageIdx } = this.state;
        window.html2canvas = html2canvas;
        await new Promise(res => this.setState({ pdfing: true }, res));

        const canvas = await html2canvas(this.$refs.printing);
        console.log(canvas.width, canvas.height);

        const width = 190;
        const height = Math.floor(width * canvas.height / canvas.width);

        const blob = await domtoimage.toPng(this.$refs.printing);
        const doc = new JSPDF();
        doc.addImage(blob, 'PNG', 10, 4, width, height);
        doc.save(`${match.title} - stage ${stageIdx}.pdf`);

        await new Promise(res => this.setState({ pdfing: false }, res));
    }
    */

    renderStage() {
        const { edit } = this.state;
        return (
            <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Paper style={{ padding: '24px', flex: 1 }} classes={{ root: 'stage-stage-paper' }}>
                    {edit ? this.renderInnerStageEdit() : this.renderInnerStage()}
                </Paper>
            </div>
        );
    }

    renderInnerStage() {
        const { t, manage, match } = this.props;
        const { stage, stageId, stageIdx } = this.state;
        const {
            title = '', targets, bonusTargets, rounds, startPosition, gunCondition, pccCondition, start, procedure, end, comments, inactive, bonusField,
        } = stage;

        const numNoShoots = targets.noshoots === 'single' ? 1
            : targets.noshoots === 'several' ? 2
                : (targets['paper-no-shoots'] || 0) + (targets['metal-no-shoots'] || 0);

        const tenPointsExtra = ((targets['pcc-ten-points-poppers'] || 0) + (targets['pcc-ten-points-frangible'] || 0)) * 5;

        const targetsStr = arrayToSentence(_.compact([
            targets.paper ? t('ipsctargets', { count: targets.paper }) : null,
            targets.minipaper ? t('ipsctargetsmini', { count: targets.minipaper }) : null,
            targets.poppers ? t('ipscpoppers', { count: targets.poppers }) : null,
            targets.minipoppers ? t('ipscpoppersmini', { count: targets.minipoppers }) : null,
            targets.plates ? t('ipscplates', { count: targets.plates }) : null,
            targets['pcc-ten-points-poppers'] ? t('ipscpcctenpointspoppers', { count: targets['pcc-ten-points-poppers'] }) : null,
            targets['pcc-frangible'] ? t('ipscpccfrangible', { count: targets['pcc-frangible'] }) : null,
            targets['pcc-ten-points-frangible'] ? t('ipscpcctenpointsfrangible', { count: targets['pcc-ten-points-frangible'] }) : null,
            numNoShoots < 50 && targets['paper-no-shoots'] ? t('ipscpaper_noshoots', { count: targets['paper-no-shoots'] }) : null,
            numNoShoots < 50 && targets['metal-no-shoots'] ? t('ipscmetal_noshoots', { count: targets['metal-no-shoots'] }) : null,
            numNoShoots >= 50 ? t('noshoots-several') : null,
        ]), {
            lastSeparator: t('generic:last_separator'),
        });


        return (
            <div>
                <Typography variant='h6' style={{ textAlign: 'center', paddingBottom: '12px' }}>
                    {stageIdx === 'new' ? title : `${t('stage-number', { number: stageIdx })} - ${title}`}
                </Typography>
                {inactive && <Typography variant='body2' style={{ textAlign: 'center', fontWeight: 500, color: red[500] }}>{t('inactive')}</Typography>}
                <div style={manage ? { position: 'relative', paddingTop: '100%', width: '100%' } : {}}>
                    {stage.img && (
                    <img
                        alt='stage'
                        style={manage ? {
                            position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%',
                        } : { width: '100%' }}
                        src={stage.img}
                    />
                    )}
                    {!stage.img && <div className='stage-image-broken'><BrokenImageIcon fontSize='inherit' /></div>}
                </div>
                {stage.img && !manage && (
                    <div style={{ textAlign: 'center', padding: '6px 0px' }} className='noprint'>
                        <Button color='primary' component={React.forwardRef((props, ref) => <a ref={ref} href={`/api/stages/${stageId}/image`} target='_blank' rel='noopener noreferrer' {...props} />)}>{t('openimg')}</Button>
                    </div>
                )}
                <Typography variant='body1' variantMapping={{ body1: 'div' }}>
                    <table className='stage-stage-table' border='1'>
                        <tbody>
                            <tr>
                                <td>{t('targets')}</td>
                                <td>{targetsStr}</td>
                            </tr>
                            <tr>
                                <td>{t('rounds')}</td>
                                <td>
                                    {t('rounds-points', { rounds, points: rounds * 5 + tenPointsExtra })}
                                    {(((targets.paper || 0) + (targets.minipaper || 0) > 0) && (rounds === (targets.paper || 0) + (targets.minipaper || 0) + (targets.poppers || 0) + (targets.minipoppers || 0) + (targets.plates || 0) + (targets['pcc-ten-points-poppers'] || 0) + (targets['pcc-frangible'] || 0) + (targets['pcc-ten-points-frangible'] || 0))) && (
                                        <Typography variant='caption'>{t('rounds-points-one-shot')}</Typography>
                                    )}
                                    {(((targets.paper || 0) + (targets.minipaper || 0) > 0) && (rounds === (targets.paper || 0) * 3 + (targets.minipaper || 0) * 3 + (targets.poppers || 0) + (targets.minipoppers || 0) + (targets.plates || 0) + (targets['pcc-ten-points-poppers'] || 0) + (targets['pcc-frangible'] || 0) + (targets['pcc-ten-points-frangible'] || 0))) && (
                                        <Typography variant='caption'>{t('rounds-points-three-shot')}</Typography>
                                    )}
                                </td>
                            </tr>
                            {!_.isEmpty(bonusTargets) && (
                                <tr>
                                    <td>{t('bonustargets')}</td>
                                    <td>{bonusTargets.map((s) => `T${s + 1}`).join(', ')}</td>
                                </tr>
                            )}
                            <tr>
                                <td>{t('startposition')}</td>
                                <td>{startPosition}</td>
                            </tr>
                            <tr>
                                <td>{t('guncondition')}</td>
                                <td>{gunCondition}</td>
                            </tr>
                            <tr>
                                <td>{t('pcccondition')}</td>
                                <td>{pccCondition}</td>
                            </tr>
                            <tr>
                                <td>{t('start')}</td>
                                <td>{start}</td>
                            </tr>
                            <tr>
                                <td>{t('procedure')}</td>
                                <td>{procedure}</td>
                            </tr>
                            {end && (
                                <tr>
                                    <td>{t('end')}</td>
                                    <td>{end}</td>
                                </tr>
                            )}
                            <tr>
                                <td>{t('comments')}</td>
                                <td>{comments}</td>
                            </tr>
                            {match.scoreMode === 'il-army' && bonusField && (
                                <tr>
                                    <td>{t('bonusfieldname')}</td>
                                    <td>{bonusField}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </Typography>
            </div>
        );
    }

    renderInnerStageEdit() {
        const { t, match } = this.props;
        const { editStage } = this.state;
        const {
            title = '', targets, bonusTargets = [], rounds, startPosition, gunCondition, pccCondition, start,
            procedure, end, comments, inactive, shouldSplit, bonusField,
        } = editStage;

        const onChange = (field) => (e) => {
            const newVal = JSON.parse(JSON.stringify(editStage));
            if ((field === 'rounds') && (parseInt(e.target.value, 10).toString() === _.trim(e.target.value))) {
                _.set(newVal, field, parseInt(e.target.value, 10));
            } else {
                _.set(newVal, field, e.target.value);
            }

            ['paper', 'minipaper', 'poppers', 'minipoppers', 'plates', 'metal-no-shoots', 'paper-no-shoots', 'pcc-ten-points-poppers', 'pcc-frangible', 'pcc-ten-points-frangible'].forEach(($field) => {
                if (newVal.targets[$field]) newVal.targets[$field] = parseInt(newVal.targets[$field], 10) || 0;
                if (newVal.targets[$field] === '') delete newVal.targets[$field];
            });

            if ((parseInt(targets.paper || 0, 10) * 2 + (parseInt(targets.minipaper || 0, 10) * 2) + parseInt(targets.poppers || 0, 10) + parseInt(targets.minipoppers || 0, 10) + parseInt(targets.plates || 0, 10) + parseInt(targets['pcc-ten-points-poppers'] || 0, 10) + parseInt(targets['pcc-frangible'] || 0, 10) + parseInt(targets['pcc-ten-points-frangible'] || 0, 10) === parseInt(rounds, 10)) && (field !== 'rounds')) {
                newVal.rounds = (newVal.targets.paper || 0) * 2 + (newVal.targets.minipaper || 0) * 2 + (newVal.targets.poppers || 0) + (newVal.targets.minipoppers || 0) + (newVal.targets.plates || 0) + (newVal.targets['pcc-ten-points-poppers'] || 0) + (newVal.targets['pcc-frangible'] || 0) + (newVal.targets['pcc-ten-points-frangible'] || 0);
            }

            this.setState({ editStage: newVal });
        };

        return (
            <div>
                <Typography variant='h6' style={{ textAlign: 'center' }}>
                    <TextField value={title} onChange={onChange('title')} inputProps={{ style: { textAlign: 'center' } }} />
                </Typography>
                <div style={{ position: 'relative', paddingTop: '100%', width: '100%' }} className='stage-image-wrapper'>
                    <label htmlFor='raised-button-file'>
                        <input
                            accept='image/*'
                            style={{ display: 'none' }}
                            id='raised-button-file'
                            type='file'
                            onChange={this.handleImageUpload}
                            hidden
                        />
                        <Button
                            component='span'
                            style={{
                                position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%',
                            }}
                        >
                            {editStage.img && (
                            <img
                                alt='stage'
                                style={{
                                    position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%',
                                }}
                                src={editStage.img}
                            />
                            )}
                            {editStage.img && <div className='stage-image-add'><EditIcon fontSize='inherit' /></div>}

                            {!editStage.img && <div className='stage-image-broken'><BrokenImageIcon fontSize='inherit' /></div>}
                            {!editStage.img && <div className='stage-image-add'><AddIcon fontSize='inherit' /></div>}
                        </Button>
                    </label>
                </div>
                <Typography variant='body1' headlineMapping={{ body1: 'div' }}>
                    <table className='stage-stage-table' border='1'>
                        <tbody>
                            <tr>
                                <td>{t('targets')}</td>
                                <td
                                    style={{
                                        display: 'flex', flexDirection: 'column', width: 'calc(100% - 12px)', border: 'none',
                                    }}
                                >
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    {t('ipsctargets_title')}
                                                    :
                                                </td>
                                                <td>
                                                    <TextField value={targets.paper} onChange={onChange('targets.paper')} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {t('ipsctargetsmini_title')}
                                                    :
                                                </td>
                                                <td>
                                                    <TextField value={targets.minipaper} onChange={onChange('targets.minipaper')} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {t('ipscpoppers_title')}
                                                    :
                                                </td>
                                                <td>
                                                    <TextField value={targets.poppers} onChange={onChange('targets.poppers')} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {t('ipscpoppersmini_title')}
                                                    :
                                                </td>
                                                <td>
                                                    <TextField value={targets.minipoppers} onChange={onChange('targets.minipoppers')} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {t('ipscplates_title')}
                                                    :
                                                </td>
                                                <td>
                                                    <TextField value={targets.plates} onChange={onChange('targets.plates')} />
                                                </td>
                                            </tr>
                                            {targets.noshoots && (
                                                <tr>
                                                    <td />
                                                    <td>
                                                        <FormControl style={{ width: '200px' }}>
                                                            <InputLabel shrink htmlFor='age-label-placeholder'>
                                                                {t('noshoots_title')}
                                                            </InputLabel>
                                                            <Select value={targets.noshoots || ''} onChange={onChange('targets.noshoots')}>
                                                                <MenuItem value=''>-</MenuItem>
                                                                <MenuItem value='single'>{t('noshoots-single')}</MenuItem>
                                                                <MenuItem value='several'>{t('noshoots-several')}</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </td>
                                                </tr>
                                            )}
                                            {!targets.noshoots && (
                                                <tr>
                                                    <td>
                                                        {t('ipscpaper_noshoots')}
                                                        :
                                                    </td>
                                                    <td>
                                                        <TextField value={targets['paper-no-shoots']} onChange={onChange('targets[\'paper-no-shoots\']')} />
                                                    </td>
                                                </tr>
                                            )}
                                            {!targets.noshoots && (
                                                <tr>
                                                    <td>
                                                        {t('ipscmetal_noshoots')}
                                                        :
                                                    </td>
                                                    <td>
                                                        <TextField value={targets['metal-no-shoots']} onChange={onChange('targets[\'metal-no-shoots\']')} />
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('targetspcc')}</td>
                                <td
                                    style={{
                                        display: 'flex', flexDirection: 'column', width: 'calc(100% - 12px)', border: 'none',
                                    }}
                                >
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    {t('ipscpcctenpointspoppers_title')}
                                                    :
                                                </td>
                                                <td>
                                                    <TextField value={targets['pcc-ten-points-poppers']} onChange={onChange('targets.pcc-ten-points-poppers')} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {t('ipscpccfrangible_title')}
                                                    :
                                                </td>
                                                <td>
                                                    <TextField value={targets['pcc-frangible']} onChange={onChange('targets.pcc-frangible')} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {t('ipscpcctenpointsfrangible_title')}
                                                    :
                                                </td>
                                                <td>
                                                    <TextField value={targets['pcc-ten-points-frangible']} onChange={onChange('targets.pcc-ten-points-frangible')} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('rounds')}</td>
                                <td><TextField value={rounds} fullWidth multiline onChange={onChange('rounds')} /></td>
                            </tr>
                            <tr>
                                <td>{t('bonustargets')}</td>
                                <td>
                                    {_.range(parseInt(targets.paper || 0, 10) + parseInt(targets.minipaper || 0, 10)).map((index) => (
                                        <FormControlLabel
                                            key={index}
                                            control={(
                                                <Checkbox
                                                    checked={bonusTargets.indexOf(index) > -1}
                                                    onChange={(e, v) => {
                                                        const $bonusTargets = bonusTargets.filter((s) => s !== index);
                                                        if (v) {
                                                            this.setState({ editStage: { ...editStage, bonusTargets: [...$bonusTargets, index] } });
                                                        } else {
                                                            this.setState({ editStage: { ...editStage, bonusTargets: $bonusTargets } });
                                                        }
                                                    }}
                                                />
                                            )}
                                            label={`T${index + 1}`}
                                        />
                                    ))}
                                </td>
                            </tr>
                            <tr>
                                <td>{t('startposition')}</td>
                                <td><TextField value={startPosition} fullWidth multiline onChange={onChange('startPosition')} /></td>
                            </tr>
                            <tr>
                                <td>{t('guncondition')}</td>
                                <td><TextField value={gunCondition} fullWidth multiline onChange={onChange('gunCondition')} /></td>
                            </tr>
                            <tr>
                                <td>{t('pcccondition')}</td>
                                <td><TextField value={pccCondition} fullWidth multiline onChange={onChange('pccCondition')} /></td>
                            </tr>
                            <tr>
                                <td>{t('start')}</td>
                                <td><TextField value={start} fullWidth multiline onChange={onChange('start')} /></td>
                            </tr>
                            <tr>
                                <td>{t('procedure')}</td>
                                <td><TextField value={procedure} fullWidth multiline onChange={onChange('procedure')} /></td>
                            </tr>
                            <tr>
                                <td>{t('end')}</td>
                                <td><TextField value={end} fullWidth multiline onChange={onChange('end')} /></td>
                            </tr>
                            <tr>
                                <td>{t('comments')}</td>
                                <td><TextField value={comments} fullWidth multiline onChange={onChange('comments')} /></td>
                            </tr>
                            {match.scoreMode === 'il-army' && (
                                <tr>
                                    <td>{t('bonusfieldname')}</td>
                                    <td><TextField value={bonusField} fullWidth onChange={onChange('bonusField')} /></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </Typography>
                <FormControlLabel
                    style={{ marginTop: '18px' }}
                    control={<Checkbox style={{ color: 'inherit' }} checked={shouldSplit} onChange={(e, v) => this.setState({ editStage: { ...editStage, shouldSplit: v } })} />}
                    label={<span style={{ color: 'inherit' }}>{t('should-split-label')}</span>}
                />
                <FormControlLabel
                    style={{ marginTop: '18px' }}
                    control={<Checkbox style={{ color: inactive ? red[500] : 'inherit' }} checked={inactive} onChange={(e, v) => this.setState({ editStage: { ...editStage, inactive: v } })} />}
                    label={<span style={{ color: inactive ? red[500] : 'inherit' }}>{t('inactive-label')}</span>}
                />
            </div>
        );
    }

    render() {
        const {
            match, title, stage, edit, saving, saveError, saveSuccess, deleteRequest, deleting,
        } = this.state;
        const {
            t, manage, stageIdx,
        } = this.props;

        if (!title) {
            return (
                <div style={{
                    display: 'flex', height: '100vh', alignItems: 'center', flexDirection: 'column',
                }}
                >
                    <CircularProgress size={75} />
                </div>
            );
        }

        const nextButton = (() => {
            if (!match.stages) return null;
            return (
                <Button
                    color='primary'
                    variant='text'
                    disabled={stageIdx === match.stages.length}
                    component={(props) => <Link to={`/${match.alias}/stages/${stageIdx + 1}${window.location.search}`} {...props} />}
                >
                    { t('generic:next') }
                </Button>
            );
        })();

        const prevButton = (() => {
            if (!match.stages) return null;
            return (
                <Button
                    color='primary'
                    variant='text'
                    disabled={stageIdx === 1}
                    component={(props) => <Link to={`/${match.alias}/stages/${stageIdx - 1}${window.location.search}`} {...props} />}
                >
                    { t('generic:prev') }
                </Button>
            );
        })();

        return (
            <div>
                {!manage && (
                    <div className='noprint' style={{ display: 'flex', justifyContent: 'center' }}>
                        <div
                            style={{
                                maxWidth: '720px', width: '90%', display: 'flex', flexDirection: 'row-reverse',
                            }}
                        >
                            <Tooltip title={t('print')}>
                                <IconButton onClick={this.print}>
                                    <i className='fas fa-print' style={{ fontSize: '24px' }} />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                )}
                <div className='noprint'>
                    <div style={{
                        display: 'flex', alignItems: 'center', flexDirection: 'column', marginBottom: '24px',
                    }}
                    >
                        <div style={{ maxWidth: '720px', width: '90%' }}>
                            {this.renderStage()}
                        </div>
                    </div>
                    {manage && (
                        <div className='stage-edit-button'>
                            <Zoom in={edit} timeout={{ enter: 200, exit: 200 }} unmountOnExit style={{ transitionDelay: '0ms' }}>
                                <Button variant='fab' color='primary' onClick={this.onSave}>
                                    {saving ? <CircularProgress color='white' size={18} /> : <DoneIcon />}
                                </Button>
                            </Zoom>
                        </div>
                    )}
                    {manage && (
                        <div className='stage-edit-button'>
                            <Zoom in={!edit} timeout={{ enter: 200, exit: 200 }} unmountOnExit style={{ transitionDelay: '0ms' }}>
                                <Button variant='fab' color='primary' onClick={() => this.setState({ edit: true, imgFile: null, editStage: JSON.parse(JSON.stringify(stage)) })}>
                                    <EditIcon />
                                </Button>
                            </Zoom>
                            <Zoom in={!edit} timeout={{ enter: 200, exit: 200 }} unmountOnExit style={{ transitionDelay: '200ms' }}>
                                <Button variant='fab' color='secondary' style={{ margin: '0px 12px' }} onClick={this.onDelete}>
                                    <DeleteIcon />
                                </Button>
                            </Zoom>
                        </div>
                    )}
                    <div
                        style={{
                            display: 'flex', justifyContent: 'space-between', maxWidth: '720px', width: '90%', margin: 'auto',
                        }}
                    >
                        {!manage && prevButton && (
                            prevButton
                        )}
                        {!manage && nextButton && (
                            nextButton
                        )}
                    </div>
                </div>
                <div className='onlyprint' style={{ background: 'white' }}>
                    {this.renderInnerStage()}
                </div>

                <Snackbar
                    open={!!saveError}
                    onClose={() => this.setState({ saveError: null })}
                    variant='error'
                    message={<div>{saveError}</div>}
                />

                <Snackbar
                    open={saveSuccess}
                    onClose={() => this.setState({ saveSuccess: false })}
                    variant='success'
                    message={t('generic:savesuccess')}
                />

                <Snackbar
                    open={!!deleteRequest}
                    variant='warning'
                    message={(
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                            <div style={{ flex: 1 }}>
                                {t('areyousure', { index: stageIdx })}
                            </div>
                            <Button style={{ color: 'white', margin: '12px' }} onClick={this.onDeleteAck}>
                                {deleting ? <CircularProgress size={12} color='white' /> : t('generic:yes')}
                            </Button>
                            <Button style={{ color: 'white' }} onClick={() => this.setState({ deleteRequest: false })}>
                                {t('generic:no')}
                            </Button>
                        </div>
)}
                />
            </div>
        );
    }
}

const styles = () => {
};

Stage.propTypes = {
    t: PropTypes.func.isRequired,
    manage: PropTypes.bool,
    stageIdx: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.oneOf(['new']),
    ]).isRequired,
    auth: PropTypes.string,
    matchId: PropTypes.string.isRequired,
    match: PropTypes.shape({
        title: PropTypes.string.isRequired,
        scoreMode: PropTypes.string,
    }).isRequired,
    onDelete: PropTypes.func,
    onSaveNew: PropTypes.func,
    setTitle: PropTypes.func.isRequired,
    setExtraButtons: PropTypes.func,
    setSaveButtonCallback: PropTypes.func,
    setBackButtonVisible: PropTypes.func,

};

Stage.defaultProps = {
    manage: false,
    auth: null,
    onSaveNew: null,
    onDelete: null,
    setExtraButtons: null,
    setSaveButtonCallback: null,
    setBackButtonVisible: null,
};

export default withStyles(styles, { withTheme: true })(withTranslation('stages')(Stage));

/* eslint-enable jsx-a11y/anchor-has-content */
