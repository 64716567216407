const lang = {
    en: {
        'division-standard': 'Standard',
        'division-production': 'Production',
        'division-production-optics': 'Production Optics',
        'division-production-optics-light': 'Production Optics Light (deprecated)',
        'division-open': 'Open',
        'division-classic': 'Classic',
        'division-revolver': 'Revolver',
        'division-pcc-optics': 'PCC Optics',
        'division-pcc-iron': 'PCC Iron',
        'division-sg-open': 'Shotgun Open',
        'division-sg-modified': 'Shotgun Modified',
        'division-sg-standard': 'Shotgun Standard',
        'division-sg-standard-manual': 'Shotgun Standard Manual',
        'category-regular': '',
        'category-senior': 'S',
        'category-super-senior': 'SS',
        'category-grand-senior': 'GS',
        'category-lady-senior': 'LS',
        'category-junior': 'J',
        'category-super-junior': 'SJ',
        'category-lady': 'L',
        'power-factor-minor': '',
        'power-factor-major': 'Maj',
        'power-factor-invalid': 'Invalid PF',
        'ics-grand-master': 'Grand Master',
        'ics-grand-master-short': 'GM',
        'ics-master': 'Master',
        'ics-master-short': 'M',
        'ics-a': 'A',
        'ics-a-short': 'A',
        'ics-b': 'B',
        'ics-b-short': 'B',
        'ics-c': 'C',
        'ics-c-short': 'C',
        'ics-d': 'D',
        'ics-d-short': 'D',
        'ics-unclassified': 'Unclassified',
        'ics-unclassified-short': 'U',
        alphas: 'Alphas',
        charlies: 'Charlies',
        deltas: 'Deltas',
        poppers: 'Metal Targets',
        tenpointspoppers: 'Metal Targets (10 Points)',
        misses: 'Misses',
        'no-shoots': 'Penalty Targets / No Shoots',
        penalties: 'Penalties',
        squads: 'Squads',
        results: 'Results',
        results_stage: 'Results Stage ',
        registration: 'Registration',
        shooter_id: 'Shooter Id',
        shooter_regional_id: 'Shooter regional Id',
        shooter_name: 'Shooter Name',
        shooter_club: 'Shooter Club',
        shooter_email: 'Shooter Email',
        shooter_division: 'Division',
        shooter_category: 'Category',
        shooter_power_factor: 'Power Factor',
        shooter_address: 'Shooter Address',
        shooter_region: 'Shooter Region',
        shooter_team: 'Shooter Team',
        shooter_ics: 'ICS Classification',
        'shooter_ics-short': 'ICS',
        region: 'Region',
        shooter_phone: 'Shooter Phone',
        shooter_social_number: 'Government Id',
        shooter_gun_license: 'Gun License Number',
        shooter_gun_license_exp: 'Gun License Expiration',
        gun_make_and_model: 'Gun Make and Model',
        gun_serial: 'Gun Serial Number',
        gun_serial_2: 'Second Gun Serial Number',
        image: 'Shooter Photo',
        image_description: 'Click to upload',
        covid: 'Covid-19 (vacinated/immuned/tested)',
        covid_vacinated: 'I got vacinated',
        covid_immuned: 'I am immuned (I was sick)',
        covid_tested: 'I\'ll show negative test results',
        optional: 'Optional',
        mandatory: 'Mandatory',
        squad_number: 'Squad #',
        stage_number: 'Stage #',
        result_time: 'Time (sec)',
        result_alphas: '# Alphas',
        result_charlies: '# Charlies',
        result_deltas: '# Deltas',
        result_poppers: '# Metal Targets',
        result_tenpointspoppers: '# Metal Targets (10 points)',
        result_misses: '# Misses',
        result_no_shoots: '# PT',
        result_penalties: '# Penalties',
        result_total: 'Total Points',
        result_factor: 'Factor',
        result_stage_points: 'Stage Points',
        next: 'Next',
        prev: 'Prev',
        save: 'Save',
        saveerror: 'Well, this is embarassing. Action failed :( Try again in a few seconds.',
        savesuccess: 'Yey - your changes have been saved!',
        last_separator: ', and ',
        yes: 'Yes',
        no: 'No',
        back: 'Back',
        ok: 'OK',
        edit: 'Edit',
        close: 'Close',
    },
    he: {
        'division-standard': 'סטנדרט',
        'division-production': 'פרודקשיין',
        'division-production-optics': 'פרודקשיין אופטיקס',
        'division-production-optics-light': 'פרודקשיין אופטיקס לייט',
        'division-open': 'אופן',
        'division-classic': 'קלאסיק',
        'division-revolver': 'רוולוור',
        'division-pcc-optics': 'קרבין אופטיקה',
        'division-pcc-iron': 'קרבין ברזל',
        'category-regular': '',
        'category-senior': 'S',
        'category-super-senior': 'SS',
        'category-grand-senior': 'GS',
        'category-lady-senior': 'LS',
        'category-junior': 'J',
        'category-super-junior': 'SJ',
        'category-lady': 'L',
        'power-factor-minor': '',
        'power-factor-major': 'Maj',
        'power-factor-invalid': 'עוצמה לא תקינה',
        'ics-grand-master': 'Grand Master',
        'ics-grand-master-short': 'GM',
        'ics-master': 'Master',
        'ics-master-short': 'M',
        'ics-a': 'A',
        'ics-a-short': 'A',
        'ics-b': 'B',
        'ics-b-short': 'B',
        'ics-c': 'C',
        'ics-c-short': 'C',
        'ics-d': 'D',
        'ics-d-short': 'D',
        'ics-unclassified': 'Unclassified',
        'ics-unclassified-short': 'U',
        alphas: 'אלפא',
        charlies: 'צ׳רלי',
        deltas: 'דלתא',
        poppers: 'מטרות ברזל',
        tenpointspoppers: 'מטרות ברזל (10 נקודות)',
        misses: 'מיס',
        'no-shoots': 'נו-שוט',
        penalties: 'ענישות',
        squads: 'סקוודים',
        results: 'תוצאות',
        results_stage: 'תוצאות סטייג׳ ',
        registration: 'רישום',
        shooter_id: 'מספר יורה',
        shooter_regional_id: 'מספר יורה',
        shooter_name: 'שם יורה',
        shooter_club: 'מועדון',
        shooter_email: 'אימייל',
        shooter_division: 'מחלקה',
        shooter_category: 'קטגוריה',
        shooter_power_factor: 'עוצמת תחמושת',
        shooter_address: 'כתובת (עיר)',
        shooter_region: 'מדינה',
        shooter_team: 'קבוצה',
        shooter_ics: 'ICS קלסיפיקציה',
        'shooter_ics-short': 'ICS',
        region: 'מדינה',
        shooter_phone: 'טלפון',
        shooter_social_number: 'תעודת זהות',
        shooter_gun_license: 'מספר רישיון אקדח (פרטי או ארגוני)',
        shooter_gun_license_exp: 'תוקף רישיון אקדח (פרטי או ארגוני)',
        gun_make_and_model: 'אקדח - יצרן וסוג',
        gun_serial: 'אקדח - מספר סידורי',
        gun_serial_2: 'אקדח שני - מספר סידורי',
        image: 'תמונת ראש של היורה',
        image_description: 'אנא בחרו והעלו תמונת פספורט ברורה שלכם',
        covid: 'אישור קורונה - חיסון, החלמה או בדיקה',
        covid_vacinated: 'התחסנתי',
        covid_immuned: 'חליתי והבראתי',
        covid_tested: 'אביא אישור בדיקה',
        optional: 'לא חובה',
        mandatory: 'חובה',
        squad_number: 'מספר סקווד',
        stage_number: 'תרגיל מספר ',
        result_time: 'זמן',
        result_alphas: 'A',
        result_charlies: 'C',
        result_deltas: 'D',
        result_poppers: 'PP',
        result_tenpointspoppers: 'PP (10 Points)',
        result_misses: 'Miss',
        result_no_shoots: 'PT',
        result_penalties: 'Penalties',
        result_total: 'סכום ניקוד',
        result_factor: 'פקטור',
        result_stage_points: 'נקודות תרגיל',
        next: 'הבא',
        prev: 'הקודם',
        save: 'שמור',
        saveerror: 'אופס, זה לא אמור לקרות. שגיאה בשמירה. נסו שנית עוד מספר רגעים',
        savesuccess: 'יש! השינויים שלכם נשמרו!',
        last_separator: ' ו-',
        yes: 'כן',
        no: 'לא',
        back: 'חזרה',
        ok: 'אוקי',
        edit: 'עריכה',
        close: 'סגירה',
    },
};

lang.iw = {
    ...lang.he,
    ...{
        squads: 'מקצים',
        squad_number: 'מספר מקצה',
        shooter_id: 'מספר אישי',
        shooter_regional_id: 'מספר לוחם\\ת',
        shooter_name: 'שם לוחם\\ת',
        shooter_club: 'יחידה',
        'division-il-army-pistol': 'אקדח',
        'division-il-army-rifle': 'רוס״ר',
        'division-il-army-negev': 'נגב',
        'division-il-army-army-rifle': 'רוב״צ',
        'category-men': 'לוחמים',
        'category-women': 'לוחמות',
        'category-reserves': 'מילואים',
    },
};

export default lang;
