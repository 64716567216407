import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import uuidv4 from 'uuid/v4';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import AddIcon from '@material-ui/icons/Add';
import yellow from '@material-ui/core/colors/yellow';
import red from '@material-ui/core/colors/red';
import { getFlagSrc, getAllDivisions } from './Utils';
import api from '../Api';

function TeamsManage(props) {
    const [match, setMatch] = useState(null);
    const [shooters, setShooters] = useState(null);
    const [showErrors, setShowErrors] = useState(false);

    const {
        t, classes, match: propMatch, auth,
        setTitle, setExtraButtons, setSaveButtonLabel, setSaveButtonCallback, setBackButtonVisible,
    } = props;

    useEffect(() => {
        (async () => {
            const serverMatch = await api.getMatch({ matchId: propMatch.id, auth });
            setMatch(serverMatch);
        })();

        (async () => {
            const serverShooters = await api.getShooters({ matchId: propMatch.id, auth });
            setShooters(serverShooters);
        })();
    }, []);

    useEffect(() => {
        setTitle(t('title', { name: propMatch.title }));
        setExtraButtons(null);
        setSaveButtonLabel(t('generic:save'));
        setBackButtonVisible(true);
        setSaveButtonCallback(onSave);
    }, [match]);

    async function onSave({ setState: setSavingState }) {
        if (!match) {
            await setSavingState({ saveError: true });
            await new Promise((res) => setTimeout(res, 2000));
            await setSavingState({ saveError: false });
            return;
        }

        if (_.some(match.teams, (team) => !team.title)) {
            await setSavingState({ saveError: true });
            await new Promise((res) => setTimeout(res, 2000));
            await setSavingState({ saveError: false });
            setShowErrors(true);
            return;
        }

        await setSavingState({ saving: true });

        try {
            await api.patchMatch({ matchId: propMatch.id, auth, patch: { teams: match.teams } });
        } catch (e) {
            console.error(e);
            await setSavingState({ saving: false, saveError: true });
            await new Promise((res) => setTimeout(res, 2000));
            await setSavingState({ saveError: false });
            return;
        }
        await setSavingState({ saving: false, saveSuccess: true });
        await new Promise((res) => setTimeout(res, 2000));
        await setSavingState({ saveSuccess: false });
    }

    function addTeam() {
        const $match = JSON.parse(JSON.stringify(match));
        $match.teams = $match.teams || [];
        $match.teams.push({
            id: uuidv4(),
            title: '',
            type: 'generic',
        });
        setMatch($match);
    }

    function changeTeam(id, newTeam) {
        const $match = JSON.parse(JSON.stringify(match));
        $match.teams = $match.teams.map((team) => (team.id === id ? newTeam : team));
        setMatch($match);
    }

    function deleteTeam(id) {
        const $match = JSON.parse(JSON.stringify(match));
        $match.teams = $match.teams.filter((team) => team.id !== id);
        setMatch($match);
    }

    if (!match || !shooters) {
        return (
            <div className={classes.wrapper} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress size={48} />
            </div>
        );
    }

    const { teams = [] } = match;

    return (
        <div className={classes.wrapper}>
            <Grid container spacing={16}>
                {teams.map((team) => (
                    <Grid key={team.id} item xs={12} md={6}>
                        <Team
                            match={match}
                            classes={classes}
                            t={t}
                            team={team}
                            shooters={shooters}
                            showErrors={showErrors}
                            onChange={($team) => changeTeam(team.id, $team)}
                            onDelete={() => deleteTeam(team.id)}
                        />
                    </Grid>
                ))}
                <Grid key='new' item xs={12} md={6}>
                    <AddTeam classes={classes} onAdd={() => addTeam()} />
                </Grid>
            </Grid>
        </div>
    );
}

TeamsManage.propTypes = {
    setTitle: PropTypes.func.isRequired,
    setExtraButtons: PropTypes.func.isRequired,
    setSaveButtonLabel: PropTypes.func.isRequired,
    setSaveButtonCallback: PropTypes.func.isRequired,
    setBackButtonVisible: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    classes: PropTypes.shape({}).isRequired,
    match: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        payment: PropTypes.string.isRequired,
        squadSelection: PropTypes.string.isRequired,
        squads: PropTypes.array,
        registrationExtraFields: PropTypes.array,
        teams: PropTypes.arrayOf(PropTypes.shape({
        })),
    }).isRequired,
    auth: PropTypes.string.isRequired,
};

const styles = () => ({
    wrapper: {
        maxWidth: '720px',
        width: '90%',
        margin: 'auto',
        paddingBottom: '20px',
    },
    teamwrapper: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    teamadd: {
        height: '100%',
        width: '100%',
        flex: 1,
        boxSizing: 'border-box',
        padding: '24px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(255, 255, 255, 0.1)',
    },
    teamshooters: {
        padding: '25px',
        marginTop: '12px',
        flex: 1,
    },
});

function Team(props) {
    const {
        team, match, t, classes, shooters, onChange, onDelete, showErrors,
    } = props;
    return (
        <div className={classes.teamwrapper}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                    style={{ flex: 1 }}
                    label={t('teamname')}
                    value={team.title}
                    error={showErrors && !team.title}
                    onChange={(e) => {
                        const $team = JSON.parse(JSON.stringify(team));
                        $team.title = e.target.value;
                        onChange($team);
                    }}
                    fullWidth
                />
                <IconButton
                    disabled={!!shooters.find((s) => s.team === team.id)}
                    color='secondary'
                    onClick={onDelete}
                >
                    <DeleteForeverOutlinedIcon fontSize='small' />
                </IconButton>
            </div>
            <Select
                style={{ marginTop: '12px' }}
                value={team.type}
                onChange={(e) => {
                    const $team = JSON.parse(JSON.stringify(team));
                    $team.type = e.target.value;
                    onChange($team);
                }}
                fullWidth
            >
                <MenuItem value='generic'>{t('teamtypegeneric')}</MenuItem>
                {['regular', 'lady', 'super-junior', 'junior', 'senior', 'super-senior', 'lady-senior', 'grand-senior'].map((category) => getAllDivisions({ matchType: match.type }).map((division) => (
                    <MenuItem value={`${category}-${division}`}>
                        {t(`generic:division-${division}`)}
                        {category !== 'regular' && (
                            <span>
                                &nbsp;-&nbsp;
                                {t(`registration:category-${category}`)}
                            </span>
                        )}
                    </MenuItem>
                )))}
                <MenuItem value='family'>{t('teamtypefamily')}</MenuItem>
            </Select>
            {(shooters.filter((s) => s.team === team.id).length > 4) && (
                <div style={{ display: 'flex', marginTop: '12px' }}>
                    <i className='fas fa-exclamation-triangle' style={{ color: yellow[600] }} />
                    <Typography variant='caption'>
                        &nbsp;
                        {t('teamexceeds4')}
                    </Typography>
                </div>
            )}
            <Paper classes={{ root: classes.teamshooters }}>
                <Typography variant='caption'>{t('squads:numshooters', { count: shooters.filter((s) => s.team === team.id).length })}</Typography>
                {shooters.filter((s) => s.team === team.id).map((shooter) => {
                    const shouldShowError = (() => {
                        if (team.type === 'generic') return false;
                        if (team.type === 'family') return false;
                        const [category, division] = team.type.split('-');
                        if (shooter.division !== division) return true;
                        if ((category !== 'regular') && ((shooter.category || 'regular') !== category)) return true;
                        return false;
                    })();

                    return (
                        <div key={shooter.id} style={{ display: 'flex', alignItems: 'center' }}>
                            {shouldShowError && (
                                <span>
                                    <Tooltip title={t('shooterdoesntmatchteam')}>
                                        <i className='fas fa-exclamation-triangle' style={{ color: red[600] }} />
                                    </Tooltip>
                                    &nbsp;
                                </span>
                            )}
                            <Typography variant='subtitle1'>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {(shooter.publicId.indexOf('|') > -1) && (
                                        <img
                                            alt={shooter.publicId.split('|')[0]}
                                            src={getFlagSrc({ countryCode: shooter.publicId.split('|')[0], size: 16 })}
                                        />
                                    )}
                                    <div style={{ padding: '0px 6px' }}>
                                        {shooter.publicId.split('|').pop()}
                                    </div>
                                    {' '}
                                    /
                                    {' '}
                                    {shooter.name}
                                </div>
                            </Typography>
                            <Typography variant='body1' style={{ margin: '0px 3px', flex: 1, opacity: 0.6 }}>
                                (
                                {shooter.club}
                                )
                            </Typography>
                        </div>
                    );
                })}
            </Paper>
        </div>
    );
}

Team.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.shape({}).isRequired,
    match: PropTypes.shape({
        type: PropTypes.string.isRequired,
    }).isRequired,
    team: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
    }).isRequired,
    shooters: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        team: PropTypes.string,
    })).isRequired,
    showErrors: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};

function AddTeam(props) {
    const { classes, onAdd } = props;

    return (
        <Paper className={classes.teamadd}>
            <Button variant='fab' color='primary' onClick={onAdd}>
                <AddIcon />
            </Button>
        </Paper>
    );
}

AddTeam.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    onAdd: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(withTranslation('teamsmanage')(TeamsManage));
