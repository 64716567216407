/* eslint-disable max-classes-per-file */
import 'seedrandom';
import React, { Component } from 'react';
import { Route, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { pathToRegexp } from 'path-to-regexp';
import _ from 'lodash';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import TimerIcon from '@material-ui/icons/Timer';
import ListAlt from '@material-ui/icons/ListAlt';
import TuneIcon from '@material-ui/icons/Tune';
import green from '@material-ui/core/colors/green';
import teal from '@material-ui/core/colors/teal';
import ShootersAutosuggest from './ShootersAutosuggest';
import { getLatestScoreForShooterPerStage } from './Utils';
import ScoreSquad from './Score.squad';
import Stage from './Stage';
import ScoreReviewing from './Score.reviewing';
import ScoreStageSquadAttendance from './Score.attendance';
import Snackbar from '../components/Snackbar';
import api from '../Api';

export default class ScoreStageWrapper extends Component {
    constructor(props) {
        super(props);

        const shootersSorting = window.localStorage.getItem('__score_stage_wrapper_sorting') || null;

        let scores = null;
        if (props.demo) {
            const cacheScores = JSON.parse(window.localStorage.getItem('__score_stage_wrapper_demoscores') || '{}');
            if (cacheScores.targets === props.targets && cacheScores.timestamp > new Date().getTime() - 24 * 60 * 60 * 1000) {
                ({ scores } = cacheScores);
            }
        }

        this.state = {
            scores,
            shootersSorting,
            showNextShooters: false,
            speedTickets: [],
        };
    }

    async componentDidMount() {
        const { demo } = this.props;

        if (demo) {
            const { scores } = this.state;
            this.setState({ scores: scores || [] });
        } else {
            const { match, auth } = this.props;

            const $scores = await api.getScores({ matchId: match.id, auth });

            const localScores = JSON.parse(window.localStorage.getItem('__offline_cache') || '[]')
                .filter((s) => s.matchId === match.id && s.timestamp > new Date().getTime() - 2 * 24 * 60 * 60 * 1000)
                .filter((s) => s.type === 'score')
                .map((s) => s.data);

            this.setState({ scores: [...$scores, ...localScores] });
        }
    }

    onSorting = (shootersSorting) => {
        if (shootersSorting) {
            window.localStorage.setItem('__score_stage_wrapper_sorting', shootersSorting);
        } else {
            window.localStorage.removeItem('__score_stage_wrapper_sorting');
        }
        this.setState({ shootersSorting });
    }

    onAddScore = (_score) => {
        const { targets, demo } = this.props;
        const { scores } = this.state;
        const newScores = [...scores, _score];

        if (demo) {
            try {
                const cacheScores = {
                    targets,
                    timestamp: new Date().getTime(),
                    scores: newScores,
                };
                window.localStorage.setItem('__score_stage_wrapper_demoscores', JSON.stringify(cacheScores));
            } catch (e) {
                // Nothing to do here
            }
        }
        this.setState({ scores: newScores });
    }

    onUpdateScore = (_score) => {
        const { scores } = this.state;
        const newScores = scores.map((s) => (s.id === _score.id ? _score : s));
        this.setState({ scores: newScores });
    }

    onUpdateScores = (updated) => {
        const { scores } = this.state;
        const newScores = [
            ...scores.filter((s) => !updated.find((s2) => s.id === s2.id)),
            ...updated,
        ];
        return new Promise((res) => this.setState({ scores: newScores }, res));
    }

    onDeleteScore = (_score) => {
        const { scores } = this.state;
        const { routeProps } = this.props;
        const { history } = routeProps;
        const newScores = scores.map((s) => ({ ...s, deleted: s.deleted || s.id === _score.id }));
        this.setState({ scores: newScores }, () => history.goBack());
    }

    onShowNextShooters = ({ shooterId, squadId, stageIdx }) => {
        const { match, shooters, demo } = this.props;
        const { scores } = this.state;

        let sqShooters = shooters
            .filter((s) => s.squad === squadId && ((s.equipmentChecks || [])[stageIdx] || {}).check !== 'absent');
        getLatestScoreForShooterPerStage({ scores, numStages: demo ? 1 : match.stages.length })
            .forEach((stageScores, stIdx2) => stageScores.forEach((s) => {
                // Remove anyone who has been dq'ed, or who has a score in this stage
                if ((s.dq) || (stageIdx === stIdx2)) sqShooters = _.reject(sqShooters, { id: s.shooterId });
            }));

        const index = _.findIndex(sqShooters, { id: shooterId });
        sqShooters = [...sqShooters.slice(index), ...sqShooters.slice(0, index)];
        sqShooters = _.reject(sqShooters, { id: shooterId });
        sqShooters = sqShooters.slice(0, 3);
        this.setState({ showNextShooters: sqShooters });
    }

    render() {
        const {
            t, match, shooters, demo, onUpdateShooters,
        } = this.props;
        const { scores, showNextShooters, speedTickets } = this.state;

        if (!shooters || !scores) {
            return (
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <CircularProgress size={75} />
                </div>
            );
        }

        return (
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <div style={{ width: '90%', maxWidth: '720px' }}>
                    <Route
                        path={['/:matchId/manage/scores/stage/:stageIdx', '/scoring-demo/:targets/stage/:stageIdx2']}
                        exact
                        render={(routeProps) => (
                            <ScoreStageInner
                                {...this.props}
                                {...this.state}
                                squads={match.squads}
                                routeProps={routeProps}
                                match={match}
                                onSorting={this.onSorting}
                            />
                        )}
                    />

                    <Route
                        path={['/:matchId/manage/scores/stage/:stageIdx/squad/:squadId/splitting', '/scoring-demo/:targets/stage/:stageIdx2/squad/:squadId2/splitting']}
                        exact
                        render={(routeProps) => (
                            <ScoreStageWelcomeOrScore
                                splitting
                                {...this.props}
                                routeProps={routeProps}
                                squadId={routeProps.match.params.squadId || routeProps.match.params.squadId2}
                            />
                        )}
                    />

                    <Route
                        path={['/:matchId/manage/scores/stage/:stageIdx/squad/:squadId', '/scoring-demo/:targets/stage/:stageIdx2/squad/:squadId2']}
                        exact
                        render={(routeProps) => (
                            <ScoreStageWelcomeOrScore
                                {...this.props}
                                routeProps={routeProps}
                                squadId={routeProps.match.params.squadId || routeProps.match.params.squadId2}
                            />
                        )}
                    />

                    <Route
                        path={[
                            '/:matchId/manage/scores/stage/:stageIdx/shooter/:shooterId',
                            '/scoring-demo/:targets/stage/:stageIdx2/shooter/:shooterId2',
                            '/:matchId/manage/scores/stage/:stageIdx3/squad/:squadId/shooter/:shooterId3',
                            '/scoring-demo/:targets/stage/:stageIdx4/squad/:squadId2/shooter/:shooterId4',
                            '/:matchId/manage/scores/stage/:stageIdx3/squad/:squadId/score-mode/speedticket/shooter/:shooterId5',
                            '/scoring-demo/:targets/stage/:stageIdx4/squad/:squadId2/score-mode/speedticket/shooter/:shooterId6',
                        ]}
                        exact
                        render={(routeProps) => {
                            const { history } = routeProps;
                            const stageIdx = parseInt(routeProps.match.params.stageIdx || routeProps.match.params.stageIdx2
                                || routeProps.match.params.stageIdx3 || routeProps.match.params.stageIdx4, 10);
                            const squadId = routeProps.match.params.squadId || routeProps.match.params.squadId2;
                            const shooterId = routeProps.match.params.shooterId || routeProps.match.params.shooterId2
                                || routeProps.match.params.shooterId3 || routeProps.match.params.shooterId4
                                || routeProps.match.params.shooterId5 || routeProps.match.params.shooterId6;
                            const shooter = shooters.find((s) => s.id === shooterId);
                            const equipmentCheck = {};
                            equipmentCheck[shooterId] = ((shooter.equipmentChecks || [])[stageIdx - 1] || {}).check;

                            const speedTicket = !!(routeProps.match.params.shooterId5 || routeProps.match.params.shooterId6);

                            let shooterHasScore = false;

                            const latestScoresPerStage = getLatestScoreForShooterPerStage({ scores, numStages: demo ? 1 : match.stages.length });
                            latestScoresPerStage.forEach((stageScores, _stageIdx) => stageScores.forEach((s) => {
                                if (s.shooterId === shooterId) {
                                    if ((_stageIdx !== stageIdx - 1) && (s.dq)) {
                                        equipmentCheck[s.shooterId] = 'absent-dq';
                                    } else if (_stageIdx === stageIdx - 1) {
                                        shooterHasScore = true;
                                    }
                                }
                            }));

                            return (
                                <ScoreStageSquadAttendance
                                    {...this.props}
                                    {...this.state}
                                    shooter={shooter}
                                    equipmentCheck={equipmentCheck}
                                    routeProps={routeProps}
                                    match={match}
                                    onChange={($shooters) => {
                                        if (Object.keys($shooters).length > 0) {
                                            let newShooters = JSON.parse(JSON.stringify(shooters));
                                            newShooters = newShooters.map((s) => (s.id === shooterId ? $shooters[shooterId] : s));
                                            onUpdateShooters(newShooters);
                                        }
                                        if (speedTicket) {
                                            this.setState({ speedTickets: [...speedTickets, shooter.id] });
                                            history.go(-2);
                                        } else if (squadId) {
                                            history.goBack();
                                        } else if (shooterHasScore) {
                                            history.replace(`/${match.alias}/manage/scores/stage/${stageIdx}/squad/none/score-mode/reviewing/${shooterId}${history.location.search}`);
                                        } else {
                                            history.replace(`/${match.alias}/manage/scores/stage/${stageIdx}/squad/none/score-mode/scoring/${shooterId}${history.location.search}`);
                                        }
                                    }}
                                />
                            );
                        }}
                    />


                    <Route
                        path={['/:matchId/manage/scores/stage/:stageIdx/squad/:squadId/welcome-mode', '/scoring-demo/:targets/stage/:stageIdx2/squad/:squadId2/welcome-mode']}
                        exact
                        render={(routeProps) => {
                            const { history } = routeProps;
                            const stageIdx = parseInt(routeProps.match.params.stageIdx || routeProps.match.params.stageIdx2, 10);
                            const squadId = routeProps.match.params.squadId || routeProps.match.params.squadId2;
                            const latestScoresPerStage = getLatestScoreForShooterPerStage({ scores, numStages: demo ? 1 : match.stages.length });
                            const equipmentCheck = {};

                            shooters.forEach((s) => {
                                if ((s.squad === squadId) && ((s.equipmentChecks || [])[stageIdx - 1])) {
                                    equipmentCheck[s.id] = ((s.equipmentChecks || [])[stageIdx - 1] || {}).check;
                                }
                            });

                            latestScoresPerStage.forEach((stageScores, _stageIdx) => {
                                if (_stageIdx !== stageIdx - 1) {
                                    stageScores.forEach((s) => {
                                        const shooter = shooters.find((sh) => sh.id === s.shooterId);
                                        if (s.dq) {
                                            if (shooter.squad === squadId) {
                                                equipmentCheck[s.shooterId] = 'absent-dq';
                                            }
                                        } else if (match.payment !== 'none' && !shooter.paid) {
                                            equipmentCheck[s.shooterId] = 'absent-not-paid';
                                        }
                                    });
                                }
                            });

                            return (
                                <ScoreStageSquadAttendance
                                    {...this.props}
                                    {...this.state}
                                    squads={match.squads}
                                    squadId={squadId}
                                    equipmentCheck={equipmentCheck}
                                    routeProps={routeProps}
                                    match={match}
                                    onChange={($shooters) => {
                                        if (Object.keys($shooters).length > 0) {
                                            let newShooters = JSON.parse(JSON.stringify(shooters));
                                            Object.keys($shooters).forEach((shooterId) => {
                                                newShooters = newShooters.map((s) => (s.id === shooterId ? $shooters[shooterId] : s));
                                            });
                                            onUpdateShooters(newShooters);
                                        }
                                        history.replace(`/${match.alias}/manage/scores/stage/${stageIdx}/squad/${squadId}/score-mode${history.location.search}`);
                                    }}
                                />
                            );
                        }}
                    />

                    <Route
                        path={['/:matchId/manage/scores/stage/:stageIdx/squad/:squadId/score-mode', '/scoring-demo/:targets/stage/:stageIdx2/squad/:squadId2/score-mode']}
                        exact
                        render={(routeProps) => {
                            const latestScoresPerStage = getLatestScoreForShooterPerStage({ scores, numStages: demo ? 1 : match.stages.length });
                            const shootersDqed = {};
                            latestScoresPerStage.forEach((stageScores) => stageScores.forEach((s) => {
                                if (s.dq) shootersDqed[s.shooterId] = s.stageIdx.toString();
                            }));
                            return (
                                <ScoreStageInner
                                    {...this.props}
                                    {...this.state}
                                    squads={match.squads}
                                    squadId={routeProps.match.params.squadId || routeProps.match.params.squadId2}
                                    shootersDqed={shootersDqed}
                                    speedTickets={speedTickets}
                                    routeProps={routeProps}
                                    match={match}
                                    onSorting={this.onSorting}
                                    onUpdateScores={this.onUpdateScores}
                                />
                            );
                        }}
                    />

                    <Route
                        path={['/:matchId/manage/scores/stage/:stageIdx/squad/:squadId/score-mode/speedticket', '/scoring-demo/:targets/stage/:stageIdx2/squad/:squadId2/score-mode/speedticket']}
                        exact
                        render={(routeProps) => {
                            const latestScoresPerStage = getLatestScoreForShooterPerStage({ scores, numStages: demo ? 1 : match.stages.length });
                            const shootersDqed = {};
                            latestScoresPerStage.forEach((stageScores) => stageScores.forEach((s) => {
                                if (s.dq) shootersDqed[s.shooterId] = s.stageIdx.toString();
                            }));
                            return (
                                <ScoreStageInner
                                    {...this.props}
                                    {...this.state}
                                    speedTicket
                                    squads={match.squads}
                                    shootersDqed={shootersDqed}
                                    routeProps={routeProps}
                                    match={match}
                                />
                            );
                        }}
                    />

                    <Route
                        path='/:matchId/manage/scores/stage/:stageIdx/briefing'
                        exact
                        render={({ history }) => (
                            <Stage
                                {...this.props}
                                manage={false}
                                history={history}
                            />
                        )}
                    />

                    <Route
                        path={[
                            '/:matchId/manage/scores/stage/:stageIdx/squad/:squadId/score-mode/reviewing/:shooterId',
                            '/scoring-demo/:targets/stage/:stageIdx2/squad/:squadId2/score-mode/reviewing/:shooterId2',
                        ]}
                        render={(routeProps) => {
                            const {
                                shooterId, shooterId2, squadId, squadId2,
                            } = routeProps.match.params;

                            return (
                                <ScoreReviewing
                                    {...this.props}
                                    {...this.state}
                                    routeProps={routeProps}
                                    shooterId={shooterId || shooterId2}
                                    squadId={squadId || squadId2}
                                    onAddScore={this.onAddScore}
                                    onUpdateScore={this.onUpdateScore}
                                    onUpdateScores={this.onUpdateScores}
                                    onDeleteScore={this.onDeleteScore}
                                />
                            );
                        }}
                    />

                    <Route
                        path={[
                            '/:matchId/manage/scores/stage/:stageIdx/squad/:squadId/score-mode/scoring/:shooterId',
                            '/scoring-demo/:targets/stage/:stageIdx2/squad/:squadId2/score-mode/scoring/:shooterId2',
                        ]}
                        render={(routeProps) => {
                            const {
                                shooterId, shooterId2, squadId, squadId2, stageIdx, stageIdx2,
                            } = routeProps.match.params;

                            return (
                                <ScoreReviewing
                                    {...this.props}
                                    {...this.state}
                                    routeProps={routeProps}
                                    shooterId={shooterId || shooterId2}
                                    squadId={squadId || squadId2}
                                    onAddScore={this.onAddScore}
                                    onUpdateScore={this.onUpdateScore}
                                    onDeleteScore={this.onDeleteScore}
                                    onUpdateTransfer={() => {}}
                                    onShowNextShooters={() => {
                                        const sId = shooterId || shooterId2;
                                        const sqId = squadId || squadId2;
                                        const stIdx = parseInt(stageIdx || stageIdx2, 10) - 1;
                                        this.onShowNextShooters({ shooterId: sId, squadId: sqId, stageIdx: stIdx });
                                    }}
                                />
                            );
                        }}
                    />
                </div>

                <Snackbar
                    open={!!showNextShooters}
                    onClose={() => this.setState({ showNextShooters: null })}
                    variant='info'
                    message={(
                        <div>
                            {((showNextShooters || []).length === 0) && (
                                <Typography variant='body2' style={{ color: 'inherit' }}>
                                    {t('nonextshooters')}
                                </Typography>
                            )}
                            {((showNextShooters || []).length > 0) && (
                                <div>
                                    <Typography variant='h6' style={{ color: 'inherit' }}>
                                        {t('nextshooters')}
                                    </Typography>
                                    {(showNextShooters || []).map((s) => (
                                        <div key={s.id}>
                                            <Typography variant='body2' style={{ color: 'inherit' }}>
                                                {s.name}
                                            </Typography>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                />
            </div>
        );
    }
}

ScoreStageWrapper.propTypes = {
    t: PropTypes.func.isRequired,
    routeProps: PropTypes.shape({
        history: ReactRouterPropTypes.history.isRequired,
        match: ReactRouterPropTypes.match.isRequired,
    }).isRequired,
    demo: PropTypes.bool,
    targets: PropTypes.number,
    auth: PropTypes.string.isRequired,
    stageIdx: PropTypes.number.isRequired,
    shooters: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        publicId: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        division: PropTypes.string.isRequired,
        category: PropTypes.string.isRequired,
        'power-factor': PropTypes.string.isRequired,
        squad: PropTypes.string,
    })),
    match: PropTypes.shape({
        id: PropTypes.string.isRequired,
        alias: PropTypes.string.isRequired,
        payment: PropTypes.string.isRequired,
        squads: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
        })).isRequired,
        stages: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    onUpdateShooters: PropTypes.func.isRequired,
};

ScoreStageWrapper.defaultProps = {
    targets: 0,
    shooters: null,
    demo: false,
};

class ScoreStageInnerNoStyle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startTimer: null,
            shooterOpen: null,
        };
    }

    componentDidMount() {
        this.updateHeader();
    }

    componentDidUpdate(prevProps, prevState) {
        /* eslint-disable react/destructuring-assignment */
        if ((prevState.startTimer !== this.state.startTimer)
            || (prevProps.stageIdx !== this.props.stageIdx)
            || (prevProps.squadId !== this.props.squadId)
            || (prevProps.match !== this.props.match)) {
            this.updateHeader();
        }
        /* eslint-enable react/destructuring-assignment */
    }

    updateHeader = () => {
        const {
            t, match, setTitle, setSaveButtonCallback, setBackButtonVisible, setExtraButtons, stageIdx, squadId,
        } = this.props;
        const {
            startTimer,
        } = this.state;

        if (!squadId) {
            setTitle(`${t('managewrapper:scores')} - ${t('stage')} ${stageIdx}`);
            setExtraButtons(null);
            setSaveButtonCallback(null);
            setBackButtonVisible(true);
        } else {
            setTitle(`${t('managewrapper:scores')} - ${t('stage')} ${stageIdx} - ${match.squads.find((s) => s.id === squadId.split(':')[0]).title}`);
            const time = parseInt((new Date().getTime() - (startTimer || 0)) / 1000, 10);
            setExtraButtons([
                <IconButton
                    key='briefing'
                    color='primary'
                    onClick={this.onBriefing}
                >
                    <ListAlt />
                </IconButton>,
                <Button
                    key='timer'
                    color='primary'
                    onClick={() => {
                        if (startTimer) {
                            this.setState({ startTimer: null });
                        } else {
                            this.setState({ startTimer: new Date().getTime() }, () => {
                                const updateTimer = () => {
                                    const { startTimer: $startTime } = this.state;
                                    const { pathname } = window.location;
                                    if (!$startTime) return;
                                    if (!pathToRegexp('/:matchId/manage/scores/stage/:stageIdx/squad/:squadId/score-mode').exec(pathname)) {
                                        this.setState({ startTimer: null });
                                        return;
                                    }
                                    this.setState({ startTimer: $startTime + 1 }); // We use ms to tick
                                    setTimeout(() => updateTimer(), 1000);
                                };
                                updateTimer();
                            });
                        }
                    }}
                >
                    <TimerIcon />
                    {startTimer && (
                    <div>
                        {_.padStart(parseInt(time / 60, 10), 2, '0')}
                        :
                        {_.padStart(time % 60, 2, '0')}
                    </div>
                    )}
                </Button>,
            ]);
            setSaveButtonCallback(null);
            setBackButtonVisible(true);
        }
    }

    onBriefing = () => {
        const {
            t, routeProps, stageIdx, match, onError,
        } = this.props;
        const { history } = routeProps;

        if (match.id) {
            history.push(`/${match.alias}/manage/scores/stage/${stageIdx}/briefing${history.location.search}`);
        } else {
            onError(t('briefing-not-in-demo'));
        }
    }

    onEditShooter = (shooterId) => {
        const {
            t, routeProps, match, onError,
        } = this.props;
        const { history } = routeProps;

        if (match.id) {
            history.push(`/${match.alias}/manage/scores/edit-shooter/${shooterId}${history.location.search}`);
        } else {
            onError(t('edit-not-in-demo'));
        }
    }

    onScheduleChrono = (shooterId) => {
        const {
            t, routeProps, match, onError,
        } = this.props;
        const { history } = routeProps;

        if (match.id) {
            history.push(`/${match.alias}/manage/chrono/newrequest/${shooterId}${history.location.search}`);
        } else {
            onError(t('chrono-not-in-demo'));
        }
    }

    onComment = (shooterId) => {
        const {
            t, routeProps, match, onError,
        } = this.props;
        const { history } = routeProps;

        if (match.id) {
            history.push(`/${match.alias}/manage/comments/${shooterId}${history.location.search}`);
        } else {
            onError(t('chrono-not-in-demo'));
        }
    }

    onShooter = (shooterId) => {
        const {
            match, scores, onShooter, stageIdx, squadId, routeProps, demo,
        } = this.props;

        if (onShooter) {
            onShooter(shooterId);
        } else {
            const latestScoresPerStage = getLatestScoreForShooterPerStage({ scores, numStages: demo ? 1 : match.stages.length });
            const existing = latestScoresPerStage[stageIdx - 1].find((s) => s.shooterId === shooterId) || {};
            if (squadId) {
                if ((existing.signature) || (existing.roSignature) || (existing.dq)) {
                    routeProps.history.push(`${routeProps.match.url}/reviewing/${shooterId}${routeProps.history.location.search}`);
                } else {
                    routeProps.history.push(`${routeProps.match.url}/scoring/${shooterId}${routeProps.history.location.search}`);
                }
            } else if ((existing.signature) || (existing.roSignature) || (existing.dq)) {
                routeProps.history.push(`${routeProps.match.url}/shooter/${shooterId}${routeProps.history.location.search}`);
            } else {
                routeProps.history.push(`${routeProps.match.url}/shooter/${shooterId}${routeProps.history.location.search}`);
            }
        }
    }

    onAddSpeedTicket = () => {
        const { routeProps } = this.props;

        routeProps.history.push(`${routeProps.match.url}/speedticket${routeProps.history.location.search}`);
    }

    renderSquad() {
        const {
            t, i18n, match, shooters, scores, stageIdx, squadId, shootersDqed = {},
            shootersSorting, demo, routeProps, speedTickets,
            onShowEditShooter, onSorting, classes,
        } = this.props;
        const {
            shooterOpen,
        } = this.state;
        const {
            history,
        } = routeProps;
        const squad = match.squads.find((s) => s.id === squadId.split(':')[0]);

        const latestScoresPerStage = getLatestScoreForShooterPerStage({ scores, numStages: demo ? 1 : match.stages.length });
        const stageScores = latestScoresPerStage[stageIdx - 1];

        const squadShooters = (() => {
            const allShootersForSquad = (shooters.filter(($s) => $s.squad === squadId.split(':')[0])).map((s) => s.id);
            const s = _.map(_.intersection(_.union(squad.order || [], allShootersForSquad), allShootersForSquad), (s3) => shooters.find((s2) => s2.id === s3));
            if (squadId.indexOf(':') === -1) return s;
            const part = parseInt((squadId.split(':')[1])[0], 10);
            const splits = parseInt((squadId.split(':')[1])[1], 10);
            const chunks = _.chunk(s, Math.ceil(s.length / splits));
            return chunks[part];
        })();

        const $shootersSorting = (() => {
            switch (shootersSorting) {
            case 'round-robin': {
                const ids = squadShooters
                    .filter((s) => match.payment === 'none' || s.paid)
                    .map((s) => s.id);

                const unpaid = squadShooters
                    .filter((s) => match.payment !== 'none' && !s.paid)
                    .map((s) => s.id);

                return [
                    ...ids.slice(((stageIdx - 1 - (squad.firstStage || 1) + 1 + match.stages.length) % match.stages.length) % ids.length),
                    ...ids.slice(0, ((stageIdx - 1 - (squad.firstStage || 1) + 1 + match.stages.length) % match.stages.length) % ids.length),
                    ...unpaid];
            }
            case 'random': {
                const myrng = new Math.seedrandom(squadId + stageIdx); // eslint-disable-line new-cap
                return _(squadShooters)
                    .sortBy(() => myrng())
                    .map((s) => s.id)
                    .value();
            }
            default: return squadShooters.map((s) => s.id);
            }
        })();

        const missingList = $shootersSorting
            .map((sId) => shooters.find((s) => s.id === sId))
            .filter((s) => ((s.equipmentChecks || [])[stageIdx - 1] || {}).check === 'absent')
            .filter((s) => !shootersDqed[s.id]);

        const dqs = $shootersSorting
            .map((sId) => shooters.find((s) => s.id === sId))
            .filter((s) => !!shootersDqed[s.id])
            .filter((s) => shootersDqed[s.id] !== stageIdx.toString());

        const unpaid = match.payment === 'none' ? [] : $shootersSorting
            .map((sId) => shooters.find((s) => s.id === sId))
            .filter((s) => !s.paid);

        return (
            <div>
                <div style={{ display: onSorting ? 'flex' : 'none', alignItems: 'center' }}>
                    <div>
                        <Button color={!shootersSorting ? 'secondary' : 'primary'} onClick={() => onSorting(null)}>
                            {t('sort-reset')}
                        </Button>
                        <Button color={shootersSorting === 'round-robin' ? 'secondary' : 'primary'} onClick={() => onSorting('round-robin')}>
                            {t('sort-by-round-robin')}
                        </Button>
                        {i18n.dir() === 'ltr' && false && (
                            <Button color={shootersSorting === 'random' ? 'secondary' : 'primary'} onClick={() => onSorting('random')}>
                                {t('sort-by-random')}
                            </Button>
                        )}
                    </div>
                </div>
                {onSorting && stageIdx === 1 && shootersSorting === 'round-robin' && (
                    <Typography variant='caption'>
                        {t('roundrobinfirststage')}
                    </Typography>
                )}
                {$shootersSorting
                    .map((sId) => shooters.find((s) => s.id === sId))
                    .filter((s) => ((s.equipmentChecks || [])[stageIdx - 1] || {}).check !== 'absent')
                    .filter((s) => match.payment === 'none' || s.paid)
                    .filter((s) => (shootersDqed[s.id] || stageIdx).toString() === stageIdx.toString())
                    .map((shooter) => {
                        const scoreOk = (() => {
                            const shooterScore = stageScores.find((s) => s.shooterId === shooter.id);
                            if (!shooterScore) return null;
                            if (shooterScore.dq) return 'dq';
                            if (shooterScore.signature) return 'ok';
                            return 'nosignature';
                        })();

                        return (
                            <div key={shooter.id} style={{ display: 'flex', marginTop: '12px' }}>
                                <Button variant='contained' color='primary' classes={scoreOk ? { root: classes.buttonscoreok } : {}} onClick={() => this.onShooter(shooter.id)} style={{ flex: 1 }}>
                                    {(shooter.comments || []).length > 0 && (
                                        <div
                                            style={{
                                                position: 'absolute',
                                                [i18n.dir() === 'rtl' ? 'left' : 'right']: '6px',
                                                top: '0px',
                                            }}
                                        >
                                            <i className='fas fa-comments' />
                                        </div>
                                    )}
                                    <div style={{
                                        display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center',
                                    }}
                                    >
                                        {scoreOk === 'dq' && <i style={{ fontSize: '24px', padding: '0px 12px' }}>DQ</i>}
                                        {scoreOk === 'ok' && <i className='fas fa-check' style={{ fontSize: '24px', padding: '0px 12px' }} />}
                                        {scoreOk === 'nosignature' && <i className='fas fa-exclamation' style={{ fontSize: '24px', padding: '0px 12px' }} />}
                                        <div style={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
                                            <Typography variant='h6' style={{ color: 'inherit' }}>
                                                {shooter.publicId}
                                                {' '}
                                                /
                                                {' '}
                                                {shooter.name}
                                            </Typography>
                                            <Typography variant='body1' style={{ color: 'inherit' }}>
                                                {[
                                                    shooter.division ? t(`generic:division-${shooter.division}`) : '',
                                                    shooter.category ? t(`generic:category-${shooter.category}`) : '',
                                                    shooter['power-factor'] ? t(`generic:power-factor-${shooter['power-factor']}`) : '',
                                                ].filter((s) => !!s).join(' | ')}
                                            </Typography>
                                        </div>
                                    </div>
                                </Button>
                                {(shooterOpen !== shooter.id) && (
                                    <Button color='primary' classes={scoreOk ? { root: classes.buttonscoreok2 } : {}} onClick={() => this.setState({ shooterOpen: shooter.id })} style={{ fontSize: '24px' }}>
                                        <i className='fas fa-ellipsis-v' />
                                    </Button>
                                )}

                                {(shooterOpen === shooter.id) && (
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        {onShowEditShooter && (
                                            <Button color='primary' classes={scoreOk ? { root: classes.buttonscoreok2 } : {}} onClick={() => this.onComment(shooter.id)} style={{ fontSize: '24px' }}>
                                                <i className='fas fa-comments' />
                                            </Button>
                                        )}

                                        {onShowEditShooter && (
                                            <Button color='primary' classes={scoreOk ? { root: classes.buttonscoreok2 } : {}} onClick={() => this.onScheduleChrono(shooter.id)} style={{ fontSize: '24px' }}>
                                                <TuneIcon />
                                            </Button>
                                        )}

                                        {onShowEditShooter && (
                                            <Button color='primary' classes={scoreOk ? { root: classes.buttonscoreok2 } : {}} onClick={() => this.onEditShooter(shooter.id)} style={{ fontSize: '24px' }}>
                                                <i className='fas fa-user-edit' />
                                            </Button>
                                        )}

                                        {onShowEditShooter && (
                                            <Button
                                                disabled={stageScores.find((s) => s.shooterId === shooter.id)}
                                                color='secondary'
                                                onClick={() => history.push(`/${match.alias}/manage/scores/stage/${stageIdx}/squad/${squadId}/shooter/${shooter.id}${history.location.search}`)}
                                                style={{ fontSize: '24px' }}
                                            >
                                                <i className='fas fa-user-times' />
                                            </Button>
                                        )}

                                        {onShowEditShooter && (
                                            <Button color='primary' onClick={() => this.setState({ shooterOpen: null })} style={{ fontSize: '24px' }}>
                                                <i className='fas fa-times' />
                                            </Button>
                                        )}
                                    </div>
                                )}
                            </div>
                        );
                    })}
                {!_.isEmpty(missingList) && (
                    <div style={{
                        borderBottom: '1px solid rgba(0,0,0,0.2)', paddingTop: '12px', paddingBottom: '12px', width: '100%',
                    }}
                    />
                )}
                {missingList.map((shooter) => (
                    <div key={shooter.id} style={{ display: 'flex', marginTop: '12px' }}>
                        <Button disabled variant='contained' color='primary' onClick={() => this.onShooter(shooter.id)} style={{ flex: 1 }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography variant='h6' style={{ color: 'inherit' }}>
                                    {shooter.publicId}
                                    {' '}
                                    /
                                    {' '}
                                    {shooter.name}
                                </Typography>
                                <Typography variant='body1' style={{ color: 'inherit' }}>
                                    {[
                                        shooter.division ? t(`generic:division-${shooter.division}`) : '',
                                        shooter.category ? t(`generic:category-${shooter.category}`) : '',
                                        shooter['power-factor'] ? t(`generic:power-factor-${shooter['power-factor']}`) : '',
                                    ].filter((s) => !!s).join(' | ')}
                                </Typography>
                            </div>
                        </Button>
                        <Button
                            color='primary'
                            style={{ fontSize: '24px', color: green[500] }}
                            onClick={() => {
                                history.push(`/${match.alias}/manage/scores/stage/${stageIdx}/squad/${squadId}/shooter/${shooter.id}${history.location.search}`);
                            }}
                        >
                            <i className='fas fa-user-check' />
                        </Button>
                    </div>
                ))}
                {!_.isEmpty(dqs) && (
                    <div>
                        <div style={{
                            borderBottom: '1px solid rgba(0,0,0,0.2)', paddingTop: '12px', paddingBottom: '12px', width: '100%',
                        }}
                        />
                        <Typography variant='h6' style={{ textAlign: 'center', marginTop: '12px' }}>DQ</Typography>
                    </div>
                )}
                {dqs.map((shooter) => (
                    <div key={shooter.id} style={{ display: 'flex', marginTop: '12px' }}>
                        <Button disabled variant='contained' color='primary' onClick={() => this.onShooter(shooter.id)} style={{ flex: 1 }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography variant='h6' style={{ color: 'inherit' }}>
                                    {shooter.publicId}
                                    {' '}
                                    /
                                    {' '}
                                    {shooter.name}
                                </Typography>
                                <Typography variant='body1' style={{ color: 'inherit' }}>
                                    {t('generic:stage_number')}
                                    {' '}
                                    {shootersDqed[shooter.id]}
                                </Typography>
                            </div>
                        </Button>
                    </div>
                ))}
                {!_.isEmpty(unpaid) && (
                    <div>
                        <div style={{
                            borderBottom: '1px solid rgba(0,0,0,0.2)', paddingTop: '12px', paddingBottom: '12px', width: '100%',
                        }}
                        />
                        <Typography variant='h6' style={{ textAlign: 'center', marginTop: '12px' }}>{t('unpaid')}</Typography>
                    </div>
                )}
                {unpaid.map((shooter) => (
                    <div key={shooter.id} style={{ display: 'flex', marginTop: '12px' }}>
                        <Button disabled variant='contained' color='primary' style={{ flex: 1 }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography variant='h6' style={{ color: 'inherit' }}>
                                    {shooter.publicId}
                                    {' '}
                                    /
                                    {' '}
                                    {shooter.name}
                                </Typography>
                                <Typography variant='body1' style={{ color: 'inherit' }}>
                                    {[
                                        shooter.division ? t(`generic:division-${shooter.division}`) : '',
                                        shooter.category ? t(`generic:category-${shooter.category}`) : '',
                                        shooter['power-factor'] ? t(`generic:power-factor-${shooter['power-factor']}`) : '',
                                    ].filter((s) => !!s).join(' | ')}
                                </Typography>
                            </div>
                        </Button>
                    </div>
                ))}
                <div style={{ display: 'flex', marginTop: '12px', alignItems: 'center' }}>
                    <div style={{ flex: 1, textAlign: 'center', opacity: 0.5 }}>
                        <hr />
                    </div>
                    <div
                        style={{
                            flex: 0, textAlign: 'center', margin: '0px 12px', whiteSpace: 'nowrap',
                        }}
                    >
                        {t('speedtickets')}
                    </div>
                    <div style={{ flex: 1, textAlign: 'center', opacity: 0.5 }}>
                        <hr />
                    </div>
                </div>
                {speedTickets
                    .map((sId) => shooters.find((s) => s.id === sId))
                    .filter((s) => (shootersDqed[s.id] || stageIdx).toString() === stageIdx.toString())
                    .map((shooter) => (
                        <div key={shooter.id} style={{ display: 'flex', marginTop: '12px' }}>
                            <Button variant='contained' color='primary' onClick={() => this.onShooter(shooter.id)} style={{ flex: 1 }}>
                                <div style={{
                                    display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center',
                                }}
                                >
                                    <i className='fas fa-meteor' style={{ fontSize: '24px', padding: '0px 12px' }} />
                                    {stageScores.find((s) => s.shooterId === shooter.id)
                                        ? stageScores.find((s) => s.shooterId === shooter.id).dq
                                            ? <i style={{ fontSize: '24px', padding: '0px 12px' }}>DQ</i>
                                            : stageScores.find((s) => s.shooterId === shooter.id).signature
                                                ? <i className='fas fa-check' style={{ fontSize: '24px', padding: '0px 12px' }} />
                                                : <i className='fas fa-exclamation' style={{ fontSize: '24px', padding: '0px 12px' }} />
                                        : null}
                                    <div style={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
                                        <Typography variant='h6' style={{ color: 'inherit' }}>
                                            {shooter.publicId}
                                            {' '}
                                            /
                                            {' '}
                                            {shooter.name}
                                        </Typography>
                                        <Typography variant='body1' style={{ color: 'inherit' }}>
                                            {[
                                                shooter.division ? t(`generic:division-${shooter.division}`) : '',
                                                shooter.category ? t(`generic:category-${shooter.category}`) : '',
                                                shooter['power-factor'] ? t(`generic:power-factor-${shooter['power-factor']}`) : '',
                                            ].filter((s) => !!s).join(' | ')}
                                        </Typography>
                                    </div>
                                </div>
                            </Button>
                            {(shooterOpen !== shooter.id) && (
                                <Button color='primary' onClick={() => this.setState({ shooterOpen: shooter.id })} style={{ fontSize: '24px' }}>
                                    <i className='fas fa-ellipsis-v' />
                                </Button>
                            )}

                            {(shooterOpen === shooter.id) && (
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    {onShowEditShooter && (
                                        <Button color='primary' onClick={() => this.onScheduleChrono(shooter.id)} style={{ fontSize: '24px' }}>
                                            <TuneIcon />
                                        </Button>
                                    )}

                                    {onShowEditShooter && (
                                        <Button color='primary' onClick={() => this.onEditShooter(shooter.id)} style={{ fontSize: '24px' }}>
                                            <i className='fas fa-user-edit' />
                                        </Button>
                                    )}

                                    {onShowEditShooter && (
                                        <Button
                                            disabled={stageScores.find((s) => s.shooterId === shooter.id)}
                                            color='secondary'
                                            onClick={() => history.push(`/${match.alias}/manage/scores/stage/${stageIdx}/squad/${squadId}/shooter/${shooter.id}${history.location.search}`)}
                                            style={{ fontSize: '24px' }}
                                        >
                                            <i className='fas fa-user-times' />
                                        </Button>
                                    )}

                                    {onShowEditShooter && (
                                        <Button color='primary' onClick={() => this.setState({ shooterOpen: null })} style={{ fontSize: '24px' }}>
                                            <i className='fas fa-times' />
                                        </Button>
                                    )}
                                </div>
                            )}
                        </div>
                    ))}
                <div style={{ display: 'flex', marginTop: '12px', marginBottom: '20px' }}>
                    <Button variant='contained' color='secondary' onClick={() => this.onAddSpeedTicket()} style={{ flex: 1 }}>
                        <div style={{
                            display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center',
                        }}
                        >
                            <Typography variant='h6' style={{ color: 'inherit' }}>
                                {t('addspeedticket')}
                            </Typography>
                        </div>
                    </Button>
                </div>
            </div>
        );
    }

    render() {
        const {
            t, shooters, match, squadId, routeProps, stageIdx, stages, speedTicket,
        } = this.props;

        if (squadId) {
            return this.renderSquad();
        }

        return (
            <div>
                <div style={{ marginTop: '24px', textAlign: 'center' }}>
                    <Typography variant='h6'>
                        {t('pickshooter')}
                    </Typography>
                    <ShootersAutosuggest
                        shooters={
                            shooters
                                .filter((s) => !s.cancelledParticipation)
                                .map((s) => ((match.payment === 'none' || s.paid) ? s : { ...s, name: `${s.name} - ${t('unpaid')}` }))
                        }
                        onSelect={(shooter) => {
                            if ((match.payment !== 'none') && (!shooter.paid)) return;
                            this.onShooter(shooter.id);
                        }}
                    />
                    {!speedTicket && (
                        <ScoreSquad
                            squads={match.squads}
                            onSquad={(squad) => {
                                const stage = stages.find((s) => s.id === match.stages[stageIdx - 1]);
                                if (stage.shouldSplit) {
                                    routeProps.history.push(`${routeProps.match.url}/squad/${squad.id}/splitting${routeProps.history.location.search}`);
                                } else {
                                    routeProps.history.push(`${routeProps.match.url}/squad/${squad.id}${routeProps.history.location.search}`);
                                }
                            }}
                        />
                    )}
                </div>
            </div>
        );
    }
}

ScoreStageInnerNoStyle.propTypes = {
    routeProps: PropTypes.shape({
        history: ReactRouterPropTypes.history.isRequired,
        match: ReactRouterPropTypes.match.isRequired,
    }).isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        dir: PropTypes.func.isRequired,
    }).isRequired,
    stageIdx: PropTypes.number.isRequired,
    squadId: PropTypes.string,
    speedTicket: PropTypes.bool,
    match: PropTypes.shape({
        id: PropTypes.string.isRequired,
        alias: PropTypes.string.isRequired,
        payment: PropTypes.string.isRequired,
        squads: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
        })).isRequired,
        stages: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    stages: PropTypes.arrayOf(PropTypes.shape({
        shouldSplit: PropTypes.bool,
    })).isRequired,
    scores: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        shooterId: PropTypes.string.isRequired,
        dq: PropTypes.string,
    })).isRequired,
    shootersDqed: PropTypes.objectOf(PropTypes.string),
    shootersSorting: PropTypes.string,
    speedTickets: PropTypes.arrayOf(PropTypes.string),
    shooters: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        publicId: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        division: PropTypes.string.isRequired,
        category: PropTypes.string.isRequired,
        'power-factor': PropTypes.string.isRequired,
    })).isRequired,
    setTitle: PropTypes.func.isRequired,
    setBackButtonVisible: PropTypes.func.isRequired,
    setSaveButtonCallback: PropTypes.func.isRequired,
    setExtraButtons: PropTypes.func.isRequired,
    demo: PropTypes.bool,
    onError: PropTypes.func.isRequired,
    onShooter: PropTypes.func,
    onShowEditShooter: PropTypes.bool,
    onSorting: PropTypes.func,
};

ScoreStageInnerNoStyle.defaultProps = {
    demo: false,
    squadId: null,
    shootersSorting: null,
    onShooter: null,
    onShowEditShooter: true,
    speedTicket: false,
    onSorting: null,
    speedTickets: [],
    shootersDqed: {},
};

const styles = {
    buttonscoreok: {
        backgroundColor: teal[500],
    },
    buttonscoreok2: {
        color: teal[500],
    },
};

const ScoreStageInner = withStyles(styles, { withTheme: true })(ScoreStageInnerNoStyle);

class ScoreStageWelcomeOrScore extends Component {
    componentDidMount() {
        const {
            t, match, setTitle, setSaveButtonCallback, setBackButtonVisible, setExtraButtons, stageIdx, squadId,
        } = this.props;

        setTitle(`${t('managewrapper:scores')} - ${t('stage')} ${stageIdx} - ${match.squads.find((s) => s.id === squadId.split(':')[0]).title}`);
        setExtraButtons([]);
        setSaveButtonCallback(null);
        setBackButtonVisible(true);
    }

    render() {
        const {
            t, routeProps, shooters, match, squadId, stageIdx, splitting,
        } = this.props;

        const squadShooters = (() => {
            const s = shooters.filter(($s) => $s.squad === squadId.split(':')[0]);
            if (squadId.indexOf(':') === -1) return s;
            const part = parseInt((squadId.split(':')[1])[0], 10);
            const splits = parseInt((squadId.split(':')[1])[1], 10);
            const chunks = _.chunk(s, Math.ceil(s.length / splits));
            return chunks[part];
        })();

        const allChecked = !squadShooters.find((s) => !((s.equipmentChecks || [])[stageIdx - 1]));

        if (splitting) {
            const baseUrl = `/${match.alias}/manage/scores/stage/${stageIdx}/squad/${squadId.split(':')[0]}`;
            return (
                <Card>
                    <CardHeader
                        title={t('splitting')}
                    />
                    <CardContent>
                        <Grid container>
                            <Grid item xs={12}>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    fullWidth
                                    component={($props) => <Link to={baseUrl} {...$props} style={{ textAlign: 'center' }} />}
                                >
                                    <div style={{ lineHeight: '3em' }}>
                                        {t('splitting-all')}
                                    </div>
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={8} style={{ marginTop: '16px' }}>
                            <Grid item xs={6}>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    fullWidth
                                    component={($props) => <Link to={`${baseUrl}:02`} {...$props} style={{ textAlign: 'center' }} />}
                                >
                                    {t('splitting-button-02')}
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    fullWidth
                                    component={($props) => <Link to={`${baseUrl}:12`} {...$props} style={{ textAlign: 'center' }} />}
                                >
                                    {t('splitting-button-12')}
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={8} style={{ marginTop: '16px' }}>
                            <Grid item xs={4}>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    fullWidth
                                    component={($props) => <Link to={`${baseUrl}:03`} {...$props} style={{ textAlign: 'center' }} />}
                                >
                                    {t('splitting-button-03')}
                                </Button>
                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    fullWidth
                                    component={($props) => <Link to={`${baseUrl}:13`} {...$props} style={{ textAlign: 'center' }} />}
                                >
                                    {t('splitting-button-13')}
                                </Button>
                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    fullWidth
                                    component={($props) => <Link to={`${baseUrl}:23`} {...$props} style={{ textAlign: 'center' }} />}
                                >
                                    {t('splitting-button-23')}
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            );
        }

        if (allChecked) {
            return (
                <div>
                    <Typography variant='body1'>
                        {t('welcomeallattendance')}
                    </Typography>
                    <Button
                        fullWidth
                        color='primary'
                        variant='contained'
                        onClick={() => {
                            routeProps.history.push(`${routeProps.match.url}/score-mode${routeProps.history.location.search}`);
                        }}
                        style={{ marginTop: '12px', padding: '12px 0px' }}
                    >
                        {t('welcomeallattendancescoresquad')}
                    </Button>
                    <Button
                        fullWidth
                        color='primary'
                        variant='text'
                        onClick={() => {
                            routeProps.history.push(`${routeProps.match.url}/welcome-mode${routeProps.history.location.search}`);
                        }}
                        style={{ marginTop: '12px', padding: '12px 0px' }}
                    >
                        {t('welcomeallattendanceattendance')}
                    </Button>
                    {splitting}
                </div>
            );
        }


        return (
            <div>
                <Typography variant='body1'>
                    {t('welcome')}
                </Typography>
                <Button
                    fullWidth
                    color='primary'
                    variant='contained'
                    onClick={() => {
                        routeProps.history.push(`${routeProps.match.url}/welcome-mode${routeProps.history.location.search}`);
                    }}
                    style={{ marginTop: '12px', padding: '12px 0px' }}
                >
                    {t('welcomeattendance')}
                </Button>
                <Button
                    fullWidth
                    color='primary'
                    variant='text'
                    onClick={() => {
                        routeProps.history.push(`${routeProps.match.url}/score-mode${routeProps.history.location.search}`);
                    }}
                    style={{ marginTop: '12px', padding: '12px 0px' }}
                >
                    {t('welcomescore')}
                </Button>
                {splitting}
            </div>
        );
    }
}

ScoreStageWelcomeOrScore.propTypes = {
    routeProps: PropTypes.shape({
        history: ReactRouterPropTypes.history.isRequired,
        match: ReactRouterPropTypes.match.isRequired,
    }).isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        dir: PropTypes.func.isRequired,
    }).isRequired,
    stageIdx: PropTypes.number.isRequired,
    squadId: PropTypes.string.isRequired,
    splitting: PropTypes.bool,
    match: PropTypes.shape({
        id: PropTypes.string.isRequired,
        alias: PropTypes.string,
        squads: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
        })).isRequired,
        stages: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    stages: PropTypes.arrayOf(PropTypes.shape({
        shouldSplit: PropTypes.bool,
    })).isRequired,
    shooters: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        publicId: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        division: PropTypes.string.isRequired,
        category: PropTypes.string.isRequired,
        'power-factor': PropTypes.string.isRequired,
        squad: PropTypes.string,
    })).isRequired,
    setTitle: PropTypes.func.isRequired,
    setBackButtonVisible: PropTypes.func.isRequired,
    setSaveButtonCallback: PropTypes.func.isRequired,
    setExtraButtons: PropTypes.func.isRequired,
};

ScoreStageWelcomeOrScore.defaultProps = {
    splitting: false,
};
/* eslint-enable max-classes-per-file */
